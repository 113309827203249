import { Popover } from '@blueprintjs/core';
import { FC, CSSProperties, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { useDrag } from 'react-dnd';
import { observer } from 'mobx-react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Patient } from '@doc-abode/data-models';

import { isSecondDoubleUpHcp as isSecondDoubleUpHcpFunc } from '../../../../../helpers/ucr/helpers';
import { IJobPos } from '../../../../../interfaces/ucr';
import { Job } from './Job';
import { EnumJobContainer } from './Job/JobTypes';
import JobSummaryContainer from './JobSummary/JobSummaryContainer';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    pos: IJobPos;
    job: Patient;
    showJobStatus?: boolean;
    container: EnumJobContainer;
}

const JobDraggable: FC<IProps> = ({ pos, job, container, showJobStatus = true }) => {
    const {
        RootStore: {
            ucrStore: { jobSummaryHcp, jobSummaryJob, setJobSummary, draggedJobs },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'box',
            item: {
                pos,
                job,
                container,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [pos],
    );

    const style: CSSProperties = useMemo(() => {
        return {
            transform: `translate(${pos.left}px, ${pos.top}px)`,
            opacity: isDragging ? 0 : 1,
        };
    }, [pos.top, pos.left, isDragging]);

    const isSecondDoubleUpHcp = isSecondDoubleUpHcpFunc(job, pos);

    const hasBeenDragged = draggedJobs[job.id];
    const draggable: boolean = useMemo(() => {
        if (hasBeenDragged) {
            return false;
        }

        const draggable = isSecondDoubleUpHcp
            ? job.buddyJobStatus !== 'COMPLETED'
            : job.jobStatus !== 'COMPLETED' || !moment().isSame(job.startDateTime, 'days');

        return draggable;
    }, [job, isSecondDoubleUpHcp, hasBeenDragged]);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const showPopover = Boolean(
        !isDragging &&
            jobSummaryHcp &&
            jobSummaryHcp === pos.hcpId &&
            jobSummaryJob &&
            jobSummaryJob === job.id,
    );
    const mouseOverTimerRef = useRef<{ timer: any }>({ timer: 0 });
    useEffect(() => {
        const {
            current: { timer },
        } = mouseOverTimerRef;
        return () => clearTimeout(timer);
    }, [mouseOverTimerRef.current.timer]);

    return (
        <div
            ref={draggable ? drag : null}
            className={cn(
                'ucr__calendar-job-draggable',
                `ucr__calendar-job-draggable--${container}`,
            )}
            style={style}
            onMouseEnter={() => {
                mouseOverTimerRef.current.timer = setTimeout(
                    () => setJobSummary(job.id, pos.hcpId!),
                    1000,
                );
            }}
            onMouseLeave={() => {
                clearTimeout(mouseOverTimerRef.current.timer);
                setJobSummary();
            }}
        >
            <Popover
                isOpen={showPopover}
                content={<JobSummaryContainer job={job} hcpid={pos.hcpId!} />}
                lazy
                minimal
                renderTarget={({ ref }) => (
                    <Job
                        pos={pos}
                        job={job}
                        container={container}
                        draggable={draggable}
                        showJobStatus={showJobStatus}
                        elementRef={ref}
                    />
                )}
            />
        </div>
    );
};

export default observer(JobDraggable);
