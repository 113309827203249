import { Patient } from '@doc-abode/data-models';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { getAbortedDetails } from '../../../../../helpers/getAbortedDetails';
import { isAborted } from '../../../../../helpers/statusCheckHelper';
import { getFormattedHcpUserForRadioLabelChooser } from '../../../../../helpers/ucr/getFormattedHcpUserForRadioLabelChooser';
import { getHcp } from '../../../../../helpers/ucr/getHcp';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

const defaultOption = {
    label: 'Select option',
    value: '',
    disabled: true,
};

export type UseReverseJobViewModelType = {
    loading: boolean;
    job: Patient;
};

export const useReverseJobViewModel = ({ loading, job }: UseReverseJobViewModelType) => {
    const {
        RootStore: {
            lovsStore: { controllerReverseReason },
            ucrStore: { focusedUser },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { values, setFieldValue }: FormikContextType<FormikValues> = useFormikContext();

    const isDoubleUp = isMultiAssigneeJob(job);

    // Establish which job parts are in a reversible state
    const hcp1InReversibleState = isAborted({ jobStatus: job.jobStatus });
    const hcp2InReversibleState = isDoubleUp ? isAborted({ jobStatus: job.buddyJobStatus }) : false;

    // We have to extract the user details irrespective of state, to allow displaying in the drop-down
    const user1 = getHcp(users, job.hcpId);
    const user2 = isDoubleUp ? getHcp(users, job.buddyId) : undefined;
    const displayLabelUser1 = getFormattedHcpUserForRadioLabelChooser(job, user1, 1);
    const displayLabelUser2 = getFormattedHcpUserForRadioLabelChooser(job, user2, 2);

    // Only extract abort details if the job part is in a reversible state
    let abortedDetailsHcp1;
    let abortedDetailsHcp2;
    if (hcp1InReversibleState) {
        abortedDetailsHcp1 = getAbortedDetails(job, false);
    }

    if (hcp2InReversibleState) {
        abortedDetailsHcp2 = getAbortedDetails(job, true);
    }

    const reasonsForReversing = [defaultOption, ...controllerReverseReason];

    useEffect(() => {
        setFieldValue('controllerReverseReason', '');
    }, [values.reverseForOption, setFieldValue]);

    const isControllerReverseReasonDisabled = values.reverseForOption === '';
    const isConfirmReverseVisitDisabled =
        loading || values.reverseForOption === '' || values.controllerReverseReason === '';

    const firstVisit = {
        label: displayLabelUser1,
        value: 'user1',
        disabled: !hcp1InReversibleState,
    };
    const secondVisit = {
        label: displayLabelUser2,
        value: 'user2',
        disabled: !hcp2InReversibleState,
    };
    const bothVisits = {
        label: 'All jobs',
        value: 'all',
        disabled: !hcp1InReversibleState || !hcp2InReversibleState,
    };

    const reverseForOption = [firstVisit, secondVisit, bothVisits];

    // Sort/pre-select options based on selected job part
    let choice = focusedUser === 'user1' ? firstVisit : secondVisit;
    reverseForOption.sort(function (x, y) {
        if (x === choice) {
            return -1;
        } else if (y === choice) {
            return 1;
        } else {
            return 0;
        }
    });

    return {
        isDoubleUp,
        displayLabelUser1,
        abortedDetailsHcp1,
        displayLabelUser2,
        abortedDetailsHcp2,
        reverseForOption,
        reasonsForReversing,
        isControllerReverseReasonDisabled,
        isConfirmReverseVisitDisabled,
    };
};
