import moment from 'moment';
import { FC, useEffect, useState } from 'react';

import { getVisitTimings } from '../../../../../api/jobsApi';
import useStores from '../../../../../hook/useStores';

import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Button, ButtonColors, ButtonElems } from '../../../../v2/components';
import { DateInputComponent as DateInput } from '../../../../v2/form/DateInput';
import { SimpleWarning } from '../../../../v2/form/SimpleWarning';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    onClose: () => void;
}

const dateFormat = 'DD/MM/YYYY';

const MAXDATE = new Date();

const ExportJobTimings: FC<IProps> = ({ onClose }) => {
    const {
        RootStore: {
            ucrStore: { selectedDate },
            userStore: { getUserSession },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [fromDate, setFromDate] = useState<Date | null>(selectedDate);
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [isLoading, setLoading] = useState<boolean>(false);
    const [warningMessage, setWarningMessage] = useState<String | undefined>(undefined);

    const onExportTimes = async () => {
        setLoading(true);

        const { tokens } = await getUserSession();

        const from = moment(fromDate).startOf('day').utc().toISOString();
        const to = moment(toDate || MAXDATE)
            .endOf('day')
            .utc()
            .toISOString();

        const response = await getVisitTimings(tokens.id, from, to);

        if (!fromDate && !toDate) {
            AppToaster.show({
                message: 'Both start date and end date must be set',
                intent: 'danger',
            });
            setLoading(false);
        } else if (!fromDate) {
            AppToaster.show({
                message: 'A start date must be set',
                intent: 'danger',
            });
            setLoading(false);
        } else if (!toDate) {
            AppToaster.show({
                message: 'A end date must be set',
                intent: 'danger',
            });
        } else {
            if (response.ok) {
                const { url } = await response.json();
                window.location.href = url;
                setLoading(false);
            } else {
                AppToaster.show({
                    message: 'There was an error downloading the file, please try again.',
                    intent: 'danger',
                });
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const from = moment(fromDate);
        const to = moment(toDate);
        setToDate(to.isBefore(from) ? from.toDate() : toDate);
    }, [fromDate, toDate]);

    useEffect(() => {
        if (!fromDate) setWarningMessage('A start date must be set');
        if (!toDate) setWarningMessage('An end date must be set');
        if (!toDate && !fromDate) setWarningMessage('Start and end date must be set');
        if (toDate && fromDate) setWarningMessage(undefined);
    }, [fromDate, toDate]);

    return (
        <>
            <div className="v2__dialog-block">
                <p>Please select the date range for which to export job timings</p>
            </div>
            <div className="v2__dialog-group v2__dialog-group--between">
                <DateInput
                    name="fromDate"
                    label="From date"
                    dateFormat={dateFormat}
                    {...getMomentDateFormatter(dateFormat)}
                    value={fromDate?.toISOString()}
                    required
                    onChangeEvent={(date: Date | null): void => setFromDate(date)}
                    onError={() => {
                        setFromDate(null);
                    }}
                />
                <DateInput
                    name="toDate"
                    label="To date"
                    dateFormat={dateFormat}
                    minDate={fromDate || new Date()}
                    {...getMomentDateFormatter(dateFormat)}
                    value={toDate?.toISOString()}
                    required
                    onChangeEvent={(date: Date | null): void => {
                        setToDate(date);
                    }}
                    onError={() => {
                        setToDate(null);
                    }}
                />
            </div>
            {warningMessage && <SimpleWarning spacerBottom={true} warnings={[warningMessage]} />}
            <div className="v2__dialog-buttons v2__dialog-buttons--right">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    color={ButtonColors.WHITE}
                    clickEvent={onClose}
                />
                <Button
                    name="Export times"
                    elem={ButtonElems.BUTTON}
                    clickEvent={onExportTimes}
                    disabled={isLoading}
                />
            </div>
        </>
    );
};

export default ExportJobTimings;
