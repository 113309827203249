import moment from 'moment';
import { dateFormat } from '../constants/patientsConst';

export const transformDate = (value, originalValue) => {
    if (!originalValue) return new Date();

    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(originalValue)) {
        return new Date('');
    }

    if (moment.isDate(originalValue)) return originalValue;

    return moment(originalValue, dateFormat).toDate();
};
