import { Popover } from '@blueprintjs/core';
import { FC } from 'react';
import moment from 'moment';
import AppToaster from '../../../../modules/helpers/Toaster';
import { exportSchedulesFetch } from '../../../../../api/schedulesApi';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';
import { IconExpandMore } from '../../../../../helpers/ucr/icons';
import { Dialogs } from '../../../../../stores/UCRStore';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

export interface IActionsButton {
    Icon?: FC;
    name: string;
    disabled?: boolean;
    color?: ButtonColors;
    size?: ButtonSizes;
    clickEvent: () => void;
}

export enum ActionsButtonActions {
    EXPORT_JOBS = 'Export job timings',
    IMPORT_SCHEDULES = 'Import schedules',
    EXPORT_SCHEDULES = 'Export schedules',
}

interface IActionsDropDown {
    text: string;
    color?: ButtonColors;
    size?: ButtonSizes;

    dropDownButtonSize?: ButtonSizes;
    dropDownButtonColor?: ButtonColors;

    actions: ActionsButtonActions[];
}

export const ActionsDropDown: FC<IActionsDropDown> = ({
    text,
    actions,
    size,
    color,
    dropDownButtonSize,
    dropDownButtonColor,
}) => {
    const {
        RootStore: {
            ucrStore: { selectedDate, setOpenedDialog },
            userStore: { getUserSession },
        },
    } = useStores<{ RootStore: RootStore }>();

    const handleExportSchedules = async () => {
        try {
            const { tokens } = (await getUserSession()) as { tokens: { id: string } };
            const { url } = await exportSchedulesFetch(tokens.id, selectedDate);
            window.location.href = url;
            AppToaster.show({
                message: `Successfully export schedules data at ${moment().format('HH:mm')}`,
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to export availability',
                intent: 'danger',
            });
        }
    };

    const mapActions = (action: ActionsButtonActions) => {
        switch (action) {
            case ActionsButtonActions.EXPORT_JOBS:
                return {
                    name: 'Export job timings',
                    clickEvent() {
                        setOpenedDialog(Dialogs.EXPORT_JOB_TIMINGS);
                    },
                };
            case ActionsButtonActions.EXPORT_SCHEDULES:
                return {
                    name: 'Export schedules',
                    clickEvent() {
                        handleExportSchedules();
                    },
                };
            case ActionsButtonActions.IMPORT_SCHEDULES:
                return {
                    name: 'Import schedules',
                    clickEvent() {
                        setOpenedDialog(Dialogs.IMPORT_SCHEDULES);
                    },
                };
        }
    };

    const actionsDetails = actions.map(mapActions);

    return (
        <Popover
            position="bottom-left"
            content={
                <div className="actions-drop-down__drop-down">
                    {actionsDetails.map(({ clickEvent, ...button }, i) => (
                        <div
                            key={`actions-button ${clickEvent.name} ${i}`}
                            className="actions-drop-down__wrapper"
                        >
                            <Button
                                {...button}
                                style={{ border: 'none' }}
                                key={`actions-button ${clickEvent.name} ${i}`}
                                className="actions-drop-down__button"
                                color={dropDownButtonColor || ButtonColors.WHITE}
                                size={dropDownButtonSize}
                                clickEvent={clickEvent}
                            />
                        </div>
                    ))}
                </div>
            }
        >
            <Button name={text} color={color} size={size} IconRight={IconExpandMore} />
        </Popover>
    );
};
