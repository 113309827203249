import { Vaccination, JobStatus } from '@doc-abode/data-models';
import { Intent, Tag } from '@blueprintjs/core';

import { statusTags } from '../../../../constants/patientsConst';

const tags = statusTags as { [key: string]: Intent };

interface IStatusTag {
    jobStatus: JobStatus;
    large?: boolean;
    className?: string;
    isPatient?: boolean;
}

export default function StatusTag({
    jobStatus,
    large = false,
    className = '',
    isPatient = false,
}: IStatusTag) {
    return (
        <Tag
            intent={tags[jobStatus]}
            minimal={jobStatus !== 'COMPLETED'}
            large={large}
            className={className}
        >
            {isPatient
                ? Vaccination.getFriendlyPatientStatus(jobStatus)
                : Vaccination.getFriendlyStatus(jobStatus)}
        </Tag>
    );
}
