import moment from 'moment';

import { VaccinationRoute } from '../types';
import { getDurationInMinutes } from '../../../../helpers';
import { allowEditStatus } from './constants';
import { getSelectedDateTime } from './helpers';

export interface IGetValidationErrors {
    selectedDate: string | null | undefined;
    selectedStartTime: string;
    selectedEndTime: string;
    selectedCapacity: number;
    routeDetails?: VaccinationRoute | undefined | null;
    isSuperuser?: boolean;
}
export const getValidationErrors = ({
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedCapacity,
    routeDetails = undefined,
    isSuperuser = false,
}: IGetValidationErrors): string[] => {
    const validationErrorList = [];
    if (!routeDetails) {
        return [];
    }

    let newStartTime;
    let newEndTime;
    if (selectedDate) {
        newStartTime = getSelectedDateTime(selectedDate, selectedStartTime);
        newEndTime = getSelectedDateTime(selectedDate, selectedEndTime);
    }
    const {
        jobStatus,
        itinerary: {
            route: { wayPoints, totalTravelTime },
        },
        routeType,
    } = routeDetails;

    if (!Boolean(selectedDate)) {
        return ['The date is mandatory'];
    }

    if (!Boolean(newStartTime)) {
        validationErrorList.push('You must provide a start time.');
    }
    if (
        allowEditStatus.includes(jobStatus) &&
        (moment(selectedDate).isBefore(moment()) || moment(newStartTime).isBefore(moment())) &&
        !isSuperuser
    ) {
        validationErrorList.push('The date cannot be in the past');
    }

    if (
        Boolean(newStartTime) &&
        Boolean(newEndTime) &&
        !moment(newStartTime).isBefore(moment(newEndTime))
    ) {
        validationErrorList.push('The start time must be earlier than the end time');
    }

    if (routeType === 'COVID' && !selectedCapacity) {
        validationErrorList.push('The maximum number of doses need to be set');
    }

    if (routeType === 'COVID' && selectedCapacity < 1) {
        validationErrorList.push('The maximum number of doses must be greater than 0');
    }

    const totalDuration = totalTravelTime
        ? getDurationInMinutes(totalTravelTime)
        : wayPoints.length * (routeType === 'COVID' ? 20 : 10);

    if (
        Boolean(newStartTime) &&
        Boolean(newEndTime) &&
        moment(newStartTime).add(totalDuration, 'minutes').isAfter(moment(newEndTime))
    ) {
        validationErrorList.push(
            'Start time and end time are too close to each other for planning purposes',
        );
    }

    return validationErrorList;
};
