import { useState } from 'react';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import AppToaster from '../../modules/helpers/Toaster';

export const S1ManagementViewModel = () => {
    const [confirmLocks, setConfirmLocks] = useState(false);
    const [confirmQueue, setConfirmQueue] = useState(false);

    const {
        RootStore: {
            s1Store: { purgeQueue, purgeLocks },
        },
    } = useStores<{ RootStore: RootStore }>();

    const handleSelectQueue = () => {
        setConfirmQueue((value) => !value);
    };

    const handleSelectLocks = () => {
        setConfirmLocks((value) => !value);
    };

    const handlePurgeQueue = async () => {
        setConfirmQueue(false);

        const apiResponse = await purgeQueue();
        if (!apiResponse || apiResponse.statusCode !== 200) {
            AppToaster.show({
                message:
                    'Queue could not be purged. Please try again later and if the problem persists please get in touch.',
                intent: 'danger',
            });

            return;
        }

        AppToaster.show({
            message: 'Queue was successfully purged.',
            intent: 'success',
        });
    };

    const handlePurgeLocks = async () => {
        setConfirmLocks(false);
        const apiResponse = await purgeLocks();

        if (!apiResponse || apiResponse.statusCode !== 200) {
            AppToaster.show({
                message:
                    'Job locks could not be purged. Please try again later and if the problem persists please get in touch.',
                intent: 'danger',
            });

            return;
        }

        AppToaster.show({
            message: 'Job locks were successfully purged.',
            intent: 'success',
        });
    };

    return {
        confirmLocks,
        confirmQueue,
        handleSelectQueue,
        handleSelectLocks,
        handlePurgeQueue,
        handlePurgeLocks,
    };
};
