import { Button, Checkbox } from '@blueprintjs/core';
import { FC } from 'react';
import { observer } from 'mobx-react';
import { Panel } from '../side';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const PatientFiltersPanel: FC<IProps> = observer(({ isShow, closePanel }) => {
    const {
        RootStore: {
            patientListStore: { showDischarged, setShowDischarged, clearAllFilters },
        },
    } = useStores<{ RootStore: RootStore }>();

    const onClearAllFilters = (): void => {
        clearAllFilters();
    };

    return (
        <Panel title="Filters" side="right" isShow={isShow} closePanel={closePanel}>
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-container">
                    <Checkbox
                        label="Show Discharged Patients"
                        checked={showDischarged}
                        className="shift-pattern-select-all-checkbox"
                        onChange={(e) => setShowDischarged(!showDischarged)}
                        onClick={(event) => event.stopPropagation()}
                    />

                    <Button
                        className="ucr__filters-clear-filters"
                        text="Clear filters"
                        minimal={true}
                        name="Clear filters"
                        onClick={onClearAllFilters}
                    />
                </div>
            </div>
        </Panel>
    );
});

export default PatientFiltersPanel;
