import { useMutation } from '@apollo/client';
import { JobStatus } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import moment from 'moment';
import { useContext } from 'react';
import { UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { isAborted } from '../../../../../helpers/statusCheckHelper';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import { JobsContext } from '../../../../../providers';
import { Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import RootStore from '../../../../../stores/RootStore';

interface APIValuesInterface {
    controllerAbortedReason?: string;
    controllerAbortedNotes?: string;
    buddyControllerAbortedReason?: string;
    buddyControllerAbortedNotes?: string;
    id: string;
    jobStatus?: JobStatus | undefined;
    jobStatusBeforeAbort?: JobStatus;
    buddyJobStatusBeforeAbort?: JobStatus | undefined;
    buddyJobStatus?: JobStatus | undefined;
    lastUpdatedBy: string | undefined;
    lastUpdatedDateTime: string;
    version: number;
    finishedDateTime?: string;
    buddyFinishedDateTime?: string;
}

export const useAbortVisitViewModel = () => {
    const jobsContext = useContext(JobsContext);
    const { currentViewState } = useView();

    const [updateJob, { loading }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                jobsContext.setRefreshAssignedJobs(true);
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });

    const {
        RootStore: {
            ucrStore: { focusedUser, abortVisit, setAbortVisit, setOpenedDialog },
            userStore: {
                user: { username },
            },
            lovsStore: { controllerAbortReason },
        },
    } = useStores<{ RootStore: RootStore }>();

    // TODO - Needs fixing further up the store - abortVisit shouldn't be null or undefined
    if (!abortVisit) {
        return {
            isDisabled: true,
            isDoubleUp: false,
            loading: false,
            onSubmit: () => {
                throw new Error('Unable to process job');
            },
            isDoubleUpAndAbort: false,
            reasonsForAbort: [],
        };
    }

    const isDoubleUp = isMultiAssigneeJob(abortVisit);
    const isFirstUser = Boolean(!isDoubleUp || (isDoubleUp && focusedUser === 'user1'));

    const user1Aborted = isAborted({ jobStatus: abortVisit.jobStatus });
    const user2Aborted = isAborted({ jobStatus: abortVisit.buddyJobStatus });

    const isDisabled = (isFirstUser && user1Aborted) || (!isFirstUser && user2Aborted);

    const onSubmit = async (values: FormikValues) => {
        if (
            isDisabled ||
            (isDoubleUp && values.cancelationOption === '') ||
            values.controllerAbortedReason === ''
        ) {
            return false;
        }

        const isUser1 = !isDoubleUp || (isDoubleUp && values.cancelationOption === 'user1');
        const isUser2 = isDoubleUp && values.cancelationOption === 'user2';

        let input: APIValuesInterface = {
            id: abortVisit.id,
            lastUpdatedBy: username,
            lastUpdatedDateTime: moment().toISOString(),
            version: abortVisit.version + 1,
        };

        if (isUser1 || !isDoubleUp) {
            // if user1
            input.controllerAbortedNotes = values.controllerAbortedNotes;
            input.controllerAbortedReason = values.controllerAbortedReason;
            input.jobStatusBeforeAbort = abortVisit.jobStatus;
            input.finishedDateTime = new Date().toISOString();
            input.jobStatus = JobStatus.CONTROLLER_ABORTED;
        } else if (isUser2) {
            /// if user2
            input.buddyControllerAbortedReason = values.controllerAbortedReason;
            input.buddyControllerAbortedNotes = values.controllerAbortedNotes;
            input.buddyJobStatusBeforeAbort = abortVisit.buddyJobStatus;
            input.buddyFinishedDateTime = new Date().toISOString();
            input.buddyJobStatus = JobStatus.CONTROLLER_ABORTED;
        } else {
            // all visits
            input.buddyControllerAbortedReason = values.controllerAbortedReason;
            input.buddyControllerAbortedNotes = values.controllerAbortedNotes;
            input.buddyJobStatusBeforeAbort = abortVisit.buddyJobStatus;
            input.buddyFinishedDateTime = new Date().toISOString();
            input.buddyJobStatus = JobStatus.CONTROLLER_ABORTED;
            input.controllerAbortedNotes = values.controllerAbortedNotes;
            input.controllerAbortedReason = values.controllerAbortedReason;
            input.jobStatusBeforeAbort = abortVisit.jobStatus;
            input.finishedDateTime = new Date().toISOString();
            input.jobStatus = JobStatus.CONTROLLER_ABORTED;
        }

        try {
            await updateJob({ variables: { input } });
            setAbortVisit(null);
            setOpenedDialog(Dialogs.NONE);
            AppToaster.show({
                message: 'Visit aborted successfully',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error aborting visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to cancel the visit',
                intent: 'danger',
            });
        }
    };

    const isDoubleUpAndAbort = abortVisit && isDoubleUp;

    const reasonsForAbort =
        controllerAbortReason && controllerAbortReason.length > 0
            ? controllerAbortReason[0].value
            : '';

    return {
        isDisabled,
        isDoubleUp,
        loading,
        onSubmit,
        isDoubleUpAndAbort,
        reasonsForAbort,
    };
};
