import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { FC } from 'react';
import { observer } from 'mobx-react';

import Loader from '../../../../modules/helpers/Loader';
import { IconRefresh } from '../../../../../helpers';
import { Button, ButtonElems } from '../../../../v2/components';
import { useMapContainerViewModel } from './useMapContainerViewModel';

const MapContainer: FC = () => {
    const {
        onLoad,
        clearDeepLink,
        openDeepLink,
        generateImageOptions,
        mapZoomLevel,
        bounds,
        loadedMap,
        allMarkers,
        notLoaded,
        gridSize,
    } = useMapContainerViewModel();

    return (
        <div className="map-container">
            {notLoaded ? (
                <Loader fullscreen={false} />
            ) : (
                <GoogleMap onLoad={onLoad} id="scheduling-map" zoom={12}>
                    <MarkerClusterer
                        maxZoom={18}
                        averageCenter={false}
                        ignoreHidden={true}
                        minimumClusterSize={2}
                        gridSize={gridSize}
                        styles={generateImageOptions()}
                    >
                        {(clusterer) => {
                            return (
                                <>
                                    {allMarkers.map((pin) => {
                                        return (
                                            <Marker
                                                position={{ lat: pin.latitude, lng: pin.longitude }}
                                                clusterer={clusterer}
                                                icon={pin.url}
                                                onClick={
                                                    pin.type === 'job'
                                                        ? (e) => {
                                                              const lat = pin.latitude;
                                                              const long = pin.longitude;
                                                              openDeepLink(pin.id || '', 'user1');
                                                              loadedMap.setZoom(19);
                                                              loadedMap.setCenter({
                                                                  lat: lat - 0.0002,
                                                                  lng: long,
                                                              });
                                                          }
                                                        : undefined
                                                }
                                                key={pin.id}
                                                title={pin.title}
                                                clickable={pin.clickable}
                                            />
                                        );
                                    })}
                                </>
                            );
                        }}
                    </MarkerClusterer>
                    <Button
                        name="Reset zoom"
                        Icon={IconRefresh}
                        elem={ButtonElems.BUTTON}
                        className="ucr__controls-button ucr__controls-button--map-zoom"
                        clickEvent={() => {
                            loadedMap.setZoom(mapZoomLevel);
                            loadedMap.fitBounds(bounds);
                            clearDeepLink();
                        }}
                    />
                </GoogleMap>
            )}
        </div>
    );
};

export default observer(MapContainer);
