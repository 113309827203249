import React, { FC } from 'react';
import { ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import { Icon, MenuItem } from '@blueprintjs/core';
import cn from 'classnames';
import { IHcp } from '../../../../../interfaces/ucr';
import useStores from '../../../../../hook/useStores';
import { ellipsis, IconAccount } from '../../../../../helpers';
import RootStore from '../../../../../stores/RootStore';

type IProps = {
    handleChange: (hcpFunction: string[]) => void;
    loading: boolean;
    hcp: IHcp;
    initialResponsibilityValues: { label: string; value: string }[];
};

type IFunction = {
    label: string;
    value: string;
    isArchived?: boolean;
};

const HcpFunctions: FC<IProps> = ({ handleChange, loading, hcp, initialResponsibilityValues }) => {
    const {
        RootStore: {
            lovsStore: { managementDay },
        },
    } = useStores<{ RootStore: RootStore }>();

    const hcpFunctions = initialResponsibilityValues.map((item) => item.value);

    const renderItem = (item: IFunction, { modifiers, handleClick }: ItemRendererProps) => {
        return (
            <MenuItem
                className={cn('ucr__calendar-hcp-functionality-select-item')}
                key={item.value}
                text={item.label}
                onClick={handleClick}
                active={modifiers.active}
                icon={
                    hcpFunctions.includes(item.value) ? (
                        <Icon icon="tick" size={12} />
                    ) : (
                        <span className="ucr__calendar-hcp-functionality-icon-placeholder" />
                    )
                }
            />
        );
    };

    const renderSummary = () => {
        if (initialResponsibilityValues.length === 0) {
            return '';
        }
        if (initialResponsibilityValues.length === 1) {
            return ellipsis(initialResponsibilityValues[0].label, 20, '...');
        }

        return `${ellipsis(initialResponsibilityValues[0].label, 13, '...')} (+${
            initialResponsibilityValues.length - 1
        })`;
    };

    const handleFunctionChange = (value: string) => {
        let functionSelected;
        if (initialResponsibilityValues.some((opt) => opt.value === value)) {
            functionSelected = initialResponsibilityValues
                .filter((opt) => opt.value !== value)
                .map((opt) => opt.value);
        } else {
            functionSelected = [...initialResponsibilityValues.map((opt) => opt.value), value];
        }
        if (functionSelected) {
            handleChange(functionSelected);
        }
    };

    return (
        <MultiSelect
            className="ucr__calendar-hcp-functionality-select"
            activeItem={null}
            items={managementDay.slice().sort((a: any, b: any) => a.label.localeCompare(b.label))}
            selectedItems={initialResponsibilityValues}
            itemRenderer={renderItem}
            placeholder={renderSummary()}
            noResults={<MenuItem disabled text="No results." />}
            onItemSelect={(item) => handleFunctionChange(item.value)}
            tagRenderer={() => null}
            popoverProps={{
                minimal: true,
                popoverClassName: 'ucr__calendar-hcp-functionality-select-popover',
            }}
            resetOnSelect={true}
            tagInputProps={{
                className: 'ucr__calendar-hcp-functionality-select-wrap',
                tagProps: {
                    minimal: true,
                    multiline: false,
                    onRemove: undefined,
                },
                leftIcon: <IconAccount className="ucr__calendar-hcp-functionality-select-icon" />,
            }}
        />
    );
};
export default HcpFunctions;
