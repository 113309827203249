import { Formik, FormikValues } from 'formik';
import { AlertCategory, AlertStatus, PatientAlert } from '@doc-abode/data-models';
import { FC, useState } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Dialogs } from '../../../../../stores/UCRStore';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { Form, Select, TextArea } from '../../../../v2/form';
import { Dialog } from '../../../../v2/overlays';
import RootStore from '../../../../../stores/RootStore';

const ResolvePatientAlert: FC = () => {
    const [loading, setLoading] = useState(false);

    const {
        RootStore: {
            lovsStore: { controllerPatientAlertResolutionReason },
            ucrStore: { openedDialog, openedPatientAlertToResolve, clearOpenedDialog, updateAlert },
        },
    } = useStores<{ RootStore: RootStore }>();

    const onSubmit = async (values: FormikValues, { resetForm }: { resetForm: () => void }) => {
        const { controllerResolutionReason, controllerResolutionNotes } = values;
        if (!controllerResolutionReason) {
            AppToaster.show({
                message: 'A resolution type must be set',
                intent: 'warning',
            });
            return;
        }

        const input: Partial<PatientAlert> = {
            status: AlertStatus.RESOLVED,
            category: AlertCategory.PATIENT,
            resolution: controllerResolutionReason,
            resolutionNotes: controllerResolutionNotes,
        };

        try {
            setLoading(true);
            await updateAlert(openedPatientAlertToResolve!.alertId, input);
            setLoading(false);
            clearOpenedDialog();
            resetForm();
            AppToaster.show({
                message: 'Patient alert successfully resolved',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error resolving patient alert:', err);
            setLoading(false);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to cancel the visit',
                intent: 'danger',
            });
        }
    };

    const close = () => {
        if (loading) {
            return;
        }

        clearOpenedDialog();
    };

    const showDialog = openedDialog === Dialogs.RESOLVE_PATIENT_ALERT;

    if (showDialog && !openedPatientAlertToResolve) {
        AppToaster.show({
            message:
                'Sorry, an error occurred and we were unable to show the resoluition interface',
            intent: 'danger',
        });
        clearOpenedDialog();
    }

    return (
        <Dialog isShow={showDialog} closeEvent={close} title="Resolve Patient Alert">
            <Formik
                initialValues={{
                    controllerResolutionReason: controllerPatientAlertResolutionReason[0].value,
                    controllerResolutionNotes: '',
                }}
                onSubmit={onSubmit}
                enableReinitialize
            >
                <Form>
                    <div className="v2__form-block">
                        <p className="v2__form-group--pos-1-1">
                            How do you want to resolve this alert?
                        </p>
                        <Select
                            label="Resolution to alert"
                            name="controllerResolutionReason"
                            className="v2__form-group--pos-1-1"
                            options={controllerPatientAlertResolutionReason}
                        />
                        <TextArea
                            label="Notes"
                            name="controllerResolutionNotes"
                            className="v2__form-group--pos-1-1"
                        />
                        <div className="v2__form-group v2__form-group--pos-1-1">
                            <Button
                                name="Cancel"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                color={ButtonColors.GREY}
                                disabled={loading}
                                clickEvent={close}
                            />
                            <Button
                                name="Resolve alert"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                type="submit"
                                color={ButtonColors.RED}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default observer(ResolvePatientAlert);
