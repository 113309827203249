import { FormikValues } from 'formik';
import { observer } from 'mobx-react';

import {
    ReferralDetailsVMInterface,
    useReferralDetailsViewModel,
} from './useReferralDetailsViewModel';
import { Text } from '../../../../v2/form';

export const ReferralDetailsReview = observer(({ values }: { values: FormikValues }) => {
    const { systmOneRef, systmOneRefLabel, referralDate, referralTime, s1Enabled } =
        useReferralDetailsViewModel({
            values,
        } as ReferralDetailsVMInterface);

    return (
        <div className="v2__form-section">
            <Text name="systmOneRef" title={systmOneRefLabel} description={systmOneRef} />
            <Text name="referrer" title="Referrer" description={values.referrer} />
            {!s1Enabled && (
                <>
                    <Text
                        name="referralDateTime"
                        title="Referral date"
                        description={referralDate}
                    />
                    <Text
                        name="referralDateTime"
                        title="Referral time"
                        description={referralTime}
                    />
                </>
            )}
        </div>
    );
});
