import {
    Vaccination,
    VaccinationDetails,
    VaccinationCategory,
    VaccineManufacturer,
    Instruction,
    Job,
    JobStatus,
} from '@doc-abode/data-models';
import useStores from '../../../../../hook/useStores';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { VaccinationRoute } from '../../types';
import { vaccinationIsNotPartOfRoute } from './Itinerary/itineraryHelpers';
import RootStore from '../../../../../stores/RootStore';

export interface IPickupSummaryData {
    practiceName: string;
    vaccinationCategory: VaccinationCategory | string;
    vaccineManufacturer: VaccineManufacturer | string;
    numberOfDoses: number;
    id: string;
}

/**
 * helper function
 * not sure where best place to put this is as it look like this logic is used in various places (DRY)
 */
export function isVaccinationAndInstructionAreAMatch(
    vaccination: Vaccination,
    instruction: Instruction,
): boolean {
    return vaccination.id === (instruction?.itineraryItem?.name || '');
}

export function isJobCancelled(job: Job) {
    return [JobStatus.CONTROLLER_ABORTED, JobStatus.HCP_ABORTED, JobStatus.WITHDRAWN].includes(
        job.jobStatus,
    );
}
export interface IVaccinationPatientCountsAsRemoved {
    vaccinationRoute: VaccinationRoute;
    vaccinationPatient: Vaccination;
}

/**
 * counts as removed when:
 * the route has not been aborted and the patient part of the route
 * OR
 * the route has been aborted patient vaccination has been completed
 * */
export function vaccinationPatientCountsAsRemovedWhenItineraryIdMatchAnd(
    vaccinationRouteJobStatus: string,
    vaccinationPatientJobStatus: string,
): boolean {
    // the ts ignore is because my ide wanted to keep flipping things round in an endless loop
    return (
        // @ts-ignore
        vaccinationRouteJobStatus === !JobStatus.CONTROLLER_ABORTED ||
        (vaccinationRouteJobStatus === JobStatus.CONTROLLER_ABORTED &&
            // @ts-ignore
            ![JobStatus.COMPLETED].includes(vaccinationPatientJobStatus))
    );
}
/**
 *
 */
export function vaccinationPatientCountsAsRemoved({
    vaccinationRoute,
    vaccinationPatient,
}: IVaccinationPatientCountsAsRemoved): boolean {
    // is the Vaccination Itinerary the same as the VaccinationRoutes Itinerary
    // if it's not the Vaccination has been removed from that Itinerary and by extension that route
    // if we have no itinerary for the route lets assume that means no patients, so they all must be removed.

    if (!vaccinationRoute.itineraryId) {
        return true;
    }
    // itineraryIds must be a miss-match so any pickup that has happened or will happen cannot affect this route
    vaccinationIsNotPartOfRoute({
        vaccination: vaccinationPatient,
        routeItineraryId: vaccinationRoute.itineraryId,
    });
    if (
        vaccinationIsNotPartOfRoute({
            vaccination: vaccinationPatient,
            routeItineraryId: vaccinationRoute.itineraryId,
        })
    ) {
        return true;
    }

    return vaccinationPatientCountsAsRemovedWhenItineraryIdMatchAnd(
        vaccinationRoute.jobStatus,
        vaccinationPatient.jobStatus,
    );
}

interface IMakeSummaryData {
    vaccinationRoute: VaccinationRoute;
    vaccinationPatientJobs: Vaccination[];
    vaccinationDetailsForOrganisation: VaccinationDetails;
}
/**
 * The instructions are used to find the vaccinatePatientJobs: Vaccine[] that are on the route
 * once we have the vaccinatePatientJobs we use Vaccine.vaccinationCategory and the organisations VaccinationDetails
 * to work out if we should do a pickup or not.
 *
 */
export function makePickupSummaryData({
    vaccinationRoute,
    vaccinationPatientJobs,
    vaccinationDetailsForOrganisation,
}: IMakeSummaryData): IPickupSummaryData[] {
    const patientVisits: Vaccination[] = [];
    const instructions = vaccinationRoute?.itinerary?.instructions ?? [];
    instructions.forEach((instruction: Instruction) => {
        // Get rid of the Vaccination that have ar not going to do (aborted, withdraw)
        // Completed stays, so we can see what has been done.
        const vaccinationPatientJob = vaccinationPatientJobs.find(
            /**
             * Match the patient to the instruction,
             * Do no include:
             *      patients who have been aborted or withdrawn
             *      vaccines of a type that the organisation does not require picking up
             */
            (vaccinationPatientJob: Vaccination) => {
                return (
                    isVaccinationAndInstructionAreAMatch(vaccinationPatientJob, instruction) &&
                    !isJobCancelled(vaccinationPatientJob) &&
                    vaccinationDetailsForOrganisation[vaccinationPatientJob.vaccinationCategory]
                        ?.requiresPickup &&
                    !vaccinationPatientCountsAsRemoved({
                        vaccinationRoute: vaccinationRoute,
                        vaccinationPatient: vaccinationPatientJob,
                    })
                );
            },
        );
        // in other components RouteDetails ~ line 165  and Instruction ~ line 78  this
        // is where we would make a call to the server to get the patient if its not already been found
        if (vaccinationPatientJob) {
            patientVisits.push(vaccinationPatientJob);
        }
    });
    const pickupSummaryData: IPickupSummaryData[] = [];
    const noPracticeProvided = 'No practice provided';
    const noVaccinationCategoryProvided = 'No vaccination category provided';
    const noManufacturerProvided = 'No manufacturer provided';

    patientVisits.forEach((patient) => {
        if (patient) {
            const practiceName: string = patient?.practice || noPracticeProvided;
            // if we get no vaccine category i'm pretty sure that is an error of some sort,
            // if we dont know the vaccine category how can we give a vaccination, therefore we cant pick it up
            // I guess its possible that the data has just not been filled in.
            const vaccinationCategory: VaccinationCategory | string =
                patient?.vaccinationCategory || noVaccinationCategoryProvided;
            const vaccineManufacturer: VaccineManufacturer | string =
                patient?.vaccineManufacturer || noManufacturerProvided;

            let rowData = pickupSummaryData.find(
                (tableRow) =>
                    tableRow.practiceName === practiceName &&
                    tableRow.vaccinationCategory === vaccinationCategory &&
                    tableRow.vaccineManufacturer === vaccineManufacturer,
            );

            if (!rowData) {
                pickupSummaryData.push({
                    practiceName,
                    vaccinationCategory,
                    vaccineManufacturer,
                    numberOfDoses: 0,
                    id: patient.id,
                });
                rowData = pickupSummaryData[pickupSummaryData.length - 1];
            }
            rowData.numberOfDoses += 1;
        }
    });
    //friendly data for sorting
    const friendlyPickupSummaryData = pickupSummaryData.map((tableRow) => {
        return {
            ...tableRow,
            vaccinationCategory:
                Vaccination.getFriendlyVaccinationCategory(
                    tableRow.vaccinationCategory as VaccinationCategory,
                ) || tableRow.vaccinationCategory,
            vaccineManufacturer:
                Vaccination.getFriendlyManufacturer(
                    tableRow.vaccineManufacturer as VaccineManufacturer,
                ) || tableRow.vaccineManufacturer,
        };
    });
    /*
         Data is sorted alphanumeric, case sensitive
         GP practice
         THEN Vaccination category
         THEN <DISPLAY VALUE> of the vaccine or “Not provided” statement
         */
    function compare(tableRowA: IPickupSummaryData, tableRowB: IPickupSummaryData) {
        if (tableRowB.practiceName === noPracticeProvided) {
            return -1;
        }
        if (tableRowA.practiceName === noPracticeProvided) {
            return 1;
        }
        if (tableRowA.practiceName < tableRowB.practiceName) {
            return -1;
        }
        if (tableRowA.practiceName > tableRowB.practiceName) {
            return 1;
        }
        // no need to do a noVaccinationCategoryProvided check as it should not be possible.
        if (tableRowA.vaccinationCategory < tableRowB.vaccinationCategory) {
            return -1;
        }
        if (tableRowA.vaccinationCategory > tableRowB.vaccinationCategory) {
            return 1;
        }
        // vaccineManufacturer
        if (tableRowB.vaccineManufacturer === noManufacturerProvided) {
            return -1;
        }
        if (tableRowA.vaccineManufacturer === noManufacturerProvided) {
            return 1;
        }
        if (tableRowA.vaccineManufacturer < tableRowB.vaccineManufacturer) {
            return -1;
        }
        // as the data is summary data if the data was the same we could up the count by one,
        // so it is not possible to have the same values.  Left this note as its normal to have return 0
        // in a sort function.
        // in this case the return 1 satisfies the condition
        // if (tableRowA.vaccineManufacturer > tableRowB.vaccineManufacturer)
        return 1;
    }

    friendlyPickupSummaryData.sort(compare);

    return friendlyPickupSummaryData;
}

interface IPickupSummaryTableProps {
    pickupSummaryData: IPickupSummaryData[];
    routeIsCompleted: boolean;
}

export function PickupSummaryTable({
    pickupSummaryData,
    routeIsCompleted,
}: IPickupSummaryTableProps): JSX.Element {
    let vaccinePickupLabel: string = 'There are no vaccine pickups on this route.';
    if (pickupSummaryData.length > 0) {
        vaccinePickupLabel = 'Vaccines to be picked up';
        if (routeIsCompleted) {
            vaccinePickupLabel = 'Vaccines picked up';
        }
    }

    return (
        <>
            <label className="filter__label--any pickup-summary__label">{vaccinePickupLabel}</label>
            <ConditionalDisplay show={pickupSummaryData.length > 0}>
                <table className="bp5-html-table" data-testid="itinerary-table">
                    <thead>
                        <tr>
                            <th>GP practice</th>
                            <th>Vaccination category</th>
                            <th>Vaccine manufacturer</th>
                            <th>Number of doses</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pickupSummaryData.map((tableRow) => {
                            return (
                                <tr key={`pickup-summary-row${tableRow.id}`}>
                                    <td>{tableRow.practiceName}</td>
                                    <td>{tableRow.vaccinationCategory}</td>
                                    <td>{tableRow.vaccineManufacturer}</td>
                                    <td>{tableRow.numberOfDoses}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </ConditionalDisplay>
        </>
    );
}

interface IPickupSummaryProps {
    vaccinationRoute: VaccinationRoute;
    patientsOnRoute: Vaccination[];
}

/**
 * https://docabode.atlassian.net/browse/VSU-2079
 * Table summarizing all the vaccine pickups required on a route.
 */
export function PickupSummary({
    vaccinationRoute,
    patientsOnRoute,
}: IPickupSummaryProps): JSX.Element {
    const {
        RootStore: {
            configStore: { vaccinationDetails },
        },
    } = useStores<{ RootStore: RootStore }>();

    const vaccinationDetailsForOrganisation = vaccinationDetails;

    const routeIsCompleted = [
        JobStatus.CONTROLLER_ABORTED,
        JobStatus.HCP_ABORTED,
        JobStatus.COMPLETED,
    ].includes(vaccinationRoute.jobStatus);
    // allPatients: Vaccinations is not what we need here.
    // we need the Vaccinations that belong to a VaccinationRoute
    const pickupSummaryData = makePickupSummaryData({
        vaccinationRoute,
        vaccinationPatientJobs: patientsOnRoute,
        vaccinationDetailsForOrganisation,
    });

    return (
        <PickupSummaryTable
            pickupSummaryData={pickupSummaryData}
            routeIsCompleted={routeIsCompleted}
        />
    );
}
