import { PatientAlert, StaffAlert } from '@doc-abode/data-models';

import { rootUrl, request } from './baseApi';

export interface GetAlertsResponse {
    patientAlerts: PatientAlert[];
    staffAlerts: StaffAlert[];
}

/**
 * Fetches all alerts for the given job ids
 * @param {Object} data - the job ids to fetch alerts for
 * @param {string[]} data.jobIds - the job ids to fetch alerts for
 * @param {boolean} retry - whether to retry the request if it fails
 */
export const getAlerts = (
    data: { jobIds: string[] },
    retry: boolean = false,
): Promise<GetAlertsResponse> =>
    request(
        {
            url: `${rootUrl}/alerts/search`,
            method: 'POST',
            data,
        },
        retry,
    );

/**
 * Updates an alert
 * @param {string} alertId - the id of the alert to update
 * @param {Object} data - the data to update the alert with
 * @param {boolean} retry - whether to retry the request if it fails
 */
export const updateAlert = (
    alertId: string,
    data: Partial<PatientAlert | StaffAlert>,
    retry: boolean = false,
): Promise<PatientAlert> =>
    request(
        {
            url: `${rootUrl}/alerts/${alertId}`,
            method: 'PATCH',
            data,
        },
        retry,
    );

/**
 * Fetches all alerts for a given job id
 * @param {string} jobId - the job id to fetch alerts for
 * @param {boolean} retry - whether to retry the request if it fails
 */
export const getJobAlerts = (
    jobId: string,
    retry: boolean = false,
): Promise<{ alerts: PatientAlert[] }> =>
    request(
        {
            url: `${rootUrl}/alerts/search/${jobId}`,
            method: 'POST',
        },
        retry,
    );
