import { useMutation } from '@apollo/client';
import { JobStatus, Patient } from '@doc-abode/data-models';
import cn from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { ViewToShow } from '../../../../../constants/mainConst';
import { UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { filterPatientAlerts } from '../../../../../helpers/ucr';
import useStores from '../../../../../hook/useStores';
import { IJobPos } from '../../../../../interfaces/ucr';
import { DragContainers } from '../../../../../stores/UCRStore';
import { JobDraggable } from '../calendar/';
import { EnumJobContainer } from '../calendar/Job/JobTypes';
import { abortedJobStatuses } from './ContainerConst';
import { IJob } from './ContainerTypes';
import Empty from './Empty';

import {
    getChangeConfirmationDialogMessage,
    isQualifyingStateForChangeConfirmationDialog,
} from '../../../../../helpers/ucr/changeConfirmationDialogHelper';
import { isAdminJob } from '../../../../../helpers/ucr/isAdminJob';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import RootStore from '../../../../../stores/RootStore';
import UnassignedVisits from './UnassignedVisits';

interface IProps {
    isSnapToGrid?: boolean;
}

interface INewJobPos {
    job: Patient;
    pos: IJobPos;
}

interface IAllJobs {
    pos: IJobPos;
    job: Patient;
    props: IJob;
    showJobStatus?: boolean;
    container: EnumJobContainer;
}
interface IGroup {
    [key: string]: IJob[];
}

const prohibitedJobStatuses = [...abortedJobStatuses, JobStatus.COMPLETED];

const Container: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: {
                panelWidth,
                unassignedJobs,
                patientAlerts,
                setDragContainer,
                openAlert,
                closeAlert,
                selectedDate,
                viewToShow,
            },
            userStore: {
                user: { username },
            },
            mapStore: { unassignedJobs: mapUnassignedJobs },
        },
    } = useStores<{ RootStore: RootStore }>();
    const [updateJob] = useMutation(UPDATE_JOB);
    const getJobHeight = useCallback(
        (job: Patient): number => {
            const { hasPatientAlerts } = filterPatientAlerts(patientAlerts, job.id);

            let height = 88;

            if (hasPatientAlerts) {
                height += 24;
            }

            return height;
        },
        [patientAlerts],
    );

    // List of all jobs that can be moved
    const jobsList: JSX.Element = useMemo(() => {
        const jobs = (viewToShow === ViewToShow.MAP ? mapUnassignedJobs : unassignedJobs).map(
            (job: Patient) => {
                const isDoubleUp = isMultiAssigneeJob(job);
                const isSecondDoubleUpHcp = isDoubleUp && !job.buddyId;
                const pos: IJobPos = {
                    top: 0,
                    left: 0,
                    width: panelWidth,
                    height: getJobHeight(job),
                    isDoubleUp,
                    isExpanded: false,
                };

                const Job = !job.hcpId ? (
                    <JobDraggable
                        key={`visits-${job.id}`}
                        pos={{
                            ...pos,
                            isSecondDoubleUpHcp: false,
                        }}
                        job={job}
                        showJobStatus={false}
                        container={EnumJobContainer.UNASSIGNED}
                    />
                ) : undefined;

                const JobSecondDoubleUp = isSecondDoubleUpHcp ? (
                    <JobDraggable
                        key={`visits-${job.id}-double-up`}
                        pos={{
                            ...pos,
                            isSecondDoubleUpHcp: true,
                        }}
                        job={job}
                        showJobStatus={false}
                        container={EnumJobContainer.UNASSIGNED}
                    />
                ) : undefined;

                let jobArr = [];
                if (Job !== undefined) {
                    jobArr.push(Job);
                }
                if (JobSecondDoubleUp !== undefined) {
                    jobArr.push(JobSecondDoubleUp);
                }

                return jobArr;
            },
        );

        const groups = (jobs.flat() as unknown as IAllJobs[]).reduce(
            (groups: IGroup, jobItem: IAllJobs) => {
                const dateUse = jobItem.props.job.startDateTime || jobItem.props.job.dateOfVisit;
                const date = dateUse && moment(dateUse).format('MMMM Do YYYY');
                if (date) {
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(jobItem);
                }
                return groups;
            },
            {},
        );
        return (
            <>
                {Object.keys(groups).map((date) => {
                    return (
                        <div className="unassigned-visits-wrapper" key={date}>
                            <UnassignedVisits
                                title={date}
                                content={groups[date]}
                                isCheckedDate={moment(date, 'MMMM Do YYYY').isBefore(selectedDate)}
                            />
                        </div>
                    );
                })}
            </>
        );
    }, [panelWidth, unassignedJobs, getJobHeight, selectedDate, mapUnassignedJobs, viewToShow]);

    const unassignHcp = (input: Partial<Patient>, job: Patient, pos: IJobPos): Partial<Patient> => {
        let newPos = {
            ...input,
        };

        if (isMultiAssigneeJob(job) && pos.isSecondDoubleUpHcp) {
            // We're unassigning HCP2
            // The GraphQL Jobs Lambda ensures relevant other fields are reset
            // https://gitlab.com/doc-abode/platform/-/blob/develop/lambdas/jobs-graphql/index.ts?ref_type=heads#L885
            newPos.buddyId = null;
        } else {
            // We're unassigning HCP1
            // The GraphQL Jobs Lambda ensures relevant other fields are reset
            // https://gitlab.com/doc-abode/platform/-/blob/develop/lambdas/jobs-graphql/index.ts?ref_type=heads#L862
            newPos.hcpId = null;
        }

        return newPos;
    };

    // Updating new job position parameters
    const updateJobPos = useCallback(
        ({ job, pos }: INewJobPos) => {
            const input: Partial<Patient> = {
                id: job.id,
                version: job.version + 1,
                lastUpdatedBy: username,
            };
            const newPos = unassignHcp(input, job, pos);

            updateJob({
                variables: { input: newPos },
            });
        },
        [updateJob, username],
    );

    // Calculating the new position of the job on drop
    const [{ isOver, isAdminTime, isAllowDrop }, drop] = useDrop(
        () => ({
            accept: 'box',
            drop(item: INewJobPos, monitor) {
                const isAdminTime = isAdminJob(item?.job);
                const delta = monitor.getDifferenceFromInitialOffset();
                const jobStatus = item?.pos?.isSecondDoubleUpHcp
                    ? item?.job.buddyJobStatus || item?.job.jobStatus
                    : item?.job.jobStatus;

                if (
                    prohibitedJobStatuses.includes(jobStatus) ||
                    isAdminTime ||
                    !delta ||
                    !item.pos.hcpId
                ) {
                    return;
                }

                const newJobPos: INewJobPos = {
                    job: item.job,
                    pos: item.pos,
                };

                if (isQualifyingStateForChangeConfirmationDialog(jobStatus)) {
                    openAlert({
                        message: getChangeConfirmationDialogMessage(jobStatus),
                        isOpen: true,
                        onConfirm: () => {
                            updateJobPos(newJobPos);
                            closeAlert();
                        },
                    });
                    return;
                }
                updateJobPos(newJobPos);
            },
            collect: (monitor) => {
                const item: INewJobPos = monitor.getItem();
                if (item && !item.job) {
                    return {
                        isAllowDrop: false,
                        isOver: false,
                        isAdminTime: false,
                    };
                }
                const jobStatus = item?.pos?.isDoubleUp
                    ? item?.job.buddyJobStatus || item?.job.jobStatus
                    : item?.job.jobStatus;

                return {
                    isAdminTime: isAdminJob(item?.job),
                    isAllowDrop: !prohibitedJobStatuses.includes(jobStatus),
                    isOver: monitor.isOver(),
                };
            },
        }),
        [updateJobPos],
    );

    useEffect(() => {
        setDragContainer(
            !isAdminTime && isAllowDrop && isOver ? DragContainers.VISITS : DragContainers.NONE,
        );
    }, [isAdminTime, isAllowDrop, isOver, setDragContainer]);

    return (
        <div ref={drop} className="ucr__side-panel-container">
            {unassignedJobs.length > 0 && jobsList ? jobsList : <Empty />}
            <div
                className={cn('ucr__side-panel-container-placeholder', {
                    'ucr__side-panel-container-placeholder--hover':
                        !isAdminTime && isAllowDrop && isOver,
                })}
            >
                Drop here to unassign
            </div>
        </div>
    );
};

export default observer(Container);
