import * as Yup from 'yup';
import { FormSteps, patientDetailsRequiredSchema } from '../common';

const referralDetails = Yup.object({
    systmOneRef: Yup.string().nullable(),
    referralDateTime: Yup.date().nullable(),
    referralTime: Yup.date(),
});

const careDetails = Yup.object({
    referralPathway: Yup.string().required('This field is required.'),
    disposition: Yup.string().required('This field is required.'),
    careComplexity: Yup.string().nullable(),
    hcpId: Yup.string().nullable(),
    buddyId: Yup.string().nullable(),
    carRequired: Yup.boolean().nullable(),
    visitDate: Yup.date().required('This field is required.').nullable().default(undefined),
    earliestDateOfVisit: Yup.date().nullable(),
    availableFrom: Yup.date().nullable(),
    availableTo: Yup.date().nullable(),
    startTime: Yup.date()
        .when('hcpId', (hcpId: string, schema: Yup.DateSchema) =>
            hcpId
                ? schema.required('Start time is required if a staff member is assigned')
                : schema,
        )
        .nullable(),
    duration: Yup.string()
        .when('hcpId', (hcpId: string, schema: Yup.StringSchema) =>
            hcpId ? schema.required('Duration is required if a staff member is assigned') : schema,
        )
        .not(['00:00'], 'Duration cannot be 0 minutes')
        .nullable(),
    arrivedDateTime: Yup.date().nullable(),
    finishedDateTime: Yup.date().nullable(),
    notes: Yup.string().nullable(),
});

const stepSchemas: { [key: string]: Yup.AnyObjectSchema } = {
    [FormSteps.PATIENT]: patientDetailsRequiredSchema,
    [FormSteps.REFERRAL]: referralDetails,
    [FormSteps.CARE]: careDetails,
};

export const getStepSchema = (step: FormSteps) => stepSchemas[step];

const validationSchema = patientDetailsRequiredSchema.concat(referralDetails).concat(careDetails);

export default validationSchema;
