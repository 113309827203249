import { Job, JobStatus } from '@doc-abode/data-models';

export interface IStatusCheck {
    jobStatus: JobStatus | undefined;
}

// Checks whether a job qualifies as ARRIVED
export function isArrived({ jobStatus }: IStatusCheck): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return jobStatus === JobStatus.ARRIVED;
}

// Checks whether a job qualifies as COMPLETED
export function isCompleted({ jobStatus }: IStatusCheck): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return jobStatus === JobStatus.COMPLETED;
}

// Checks whether a job qualifies as ABORTED
export function isAborted({ jobStatus }: IStatusCheck): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return [JobStatus.CONTROLLER_ABORTED, JobStatus.HCP_ABORTED].includes(jobStatus);
}

export function getJobStatus(job: Job, isBuddy: boolean) {
    if (isBuddy) {
        // Usual fallback as our buddy can be optional
        return job.buddyJobStatus ?? JobStatus.PENDING;
    }
    return job.jobStatus;
}
