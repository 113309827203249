import { FC } from 'react';

import { Person, People } from '../../../../../../helpers/ucr/icons';
import { getClassByStatus } from '../Job/Job';
import { Patient } from '@doc-abode/data-models';
import S1SyncStatusLabel from '../../../blocks/panels/children/S1SyncStatusLabel';
import { ViewToShow } from '../../../../../../constants/mainConst';

export interface IHCP {
    name: string;
    status: string;
    isMain: boolean;
    hcpID: string;
}

export interface IHeader {
    name: string;
    hcps: (IHCP | null)[];
    currentHcp: string;
}

interface IJobSummaryHeaderProps {
    header: IHeader;
    patient: Patient;
}

export const JobSummaryHeader: FC<IJobSummaryHeaderProps> = ({ header, patient }) => {
    const isBuddy = header.currentHcp !== patient.hcpId;
    const view = ViewToShow.JOB_SUMMARY_HEADER;
    if (!header.name) {
        return (
            <div className="job-summary__header">
                <div className="job-summary__patient-name" />
                <S1SyncStatusLabel patient={patient} isBuddy={isBuddy} label="S1" view={view} />
            </div>
        );
    }

    return (
        <div className="job-summary__header">
            <div className="job-summary__patient-name">{header.name}</div>
            <S1SyncStatusLabel patient={patient} isBuddy={isBuddy} label="S1" view={view} />
        </div>
    );
};

export const JobSummaryHCP: FC<{ hcp: IHCP | null; currentHcp: string }> = ({
    hcp,
    currentHcp,
}) => {
    const Icon = hcp?.hcpID === currentHcp ? Person : People;
    const statusClass = getClassByStatus(hcp?.status, Patient.getFriendlyVisitStatus);
    if (!hcp) {
        return (
            <div className="job-summary__hcp">
                <span className="job-summary__hcp-double-up job-summary__hcp-double-up--margin">
                    <Icon
                        className="job-summary__double-up-icon"
                        style={{ verticalAlign: 'middle' }}
                    />
                </span>
                <span className="job-summary__hcp-status job-summary__hcp-status--unassigned">
                    Unassigned
                </span>
            </div>
        );
    }

    return (
        <div className="job-summary__hcp">
            <span>
                <span className="job-summary__hcp-double-up job-summary__hcp-double-up--margin">
                    <Icon
                        className="job-summary__double-up-icon"
                        style={{ verticalAlign: 'middle' }}
                    />
                </span>

                <span className="job-summary__hcp-name">{hcp.name}</span>
            </span>

            <span className={`job-summary__hcp-status job-summary__hcp-status--${statusClass}`}>
                {hcp.status}
            </span>
        </div>
    );
};
