import { Job, JobStatus } from '@doc-abode/data-models';
import { standardiseStringToUndefined } from './standardiseToUndefined';

export type AbortedDetails = {
    abortedStatus?: JobStatus;
    abortedDatetime?: string;
    abortedReason?: string;
    abortedNotes?: string;
    abortedFollowUpAction?: string;
};

function getFromHcp2(job: Job): AbortedDetails | undefined {
    if (JobStatus.CONTROLLER_ABORTED === job.buddyJobStatus) {
        return {
            abortedStatus: job.buddyJobStatus,
            abortedDatetime: standardiseStringToUndefined(job.buddyFinishedDateTime),
            abortedReason: job.buddyControllerAbortedReason,
            abortedNotes: job.buddyControllerAbortedNotes,
            abortedFollowUpAction: standardiseStringToUndefined(job.abortedFollowUpAction),
        };
    }

    if (JobStatus.HCP_ABORTED === job.buddyJobStatus) {
        return {
            abortedStatus: job.buddyJobStatus,
            abortedDatetime: standardiseStringToUndefined(job.buddyHcpAbortedDateTime),
            abortedReason: job.buddyHcpAbortedReason,
            abortedNotes: job.buddyHcpAbortedNotes,
            abortedFollowUpAction: standardiseStringToUndefined(job.abortedFollowUpAction),
        };
    }

    return undefined;
}

function getFromHcp1(job: Job): AbortedDetails | undefined {
    if (JobStatus.CONTROLLER_ABORTED === job.jobStatus) {
        return {
            abortedStatus: job.jobStatus,
            abortedDatetime: standardiseStringToUndefined(job.finishedDateTime),
            abortedReason: job.controllerAbortedReason,
            abortedNotes: job.controllerAbortedNotes,
            abortedFollowUpAction: standardiseStringToUndefined(job.abortedFollowUpAction),
        };
    }

    if (JobStatus.HCP_ABORTED === job.jobStatus) {
        return {
            abortedStatus: job.jobStatus,
            abortedDatetime: standardiseStringToUndefined(job.hcpAbortedDateTime),
            abortedReason: job.hcpAbortedReason,
            abortedNotes: job.hcpAbortedNotes,
            abortedFollowUpAction: standardiseStringToUndefined(job.abortedFollowUpAction),
        };
    }

    return undefined;
}

export function getAbortedDetails(job: Job, isBuddy?: boolean): AbortedDetails | undefined {
    return isBuddy ? getFromHcp2(job) : getFromHcp1(job);
}
