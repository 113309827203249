import { useQuery } from '@tanstack/react-query';
import { getPDSSpineData } from './pdsApi';

export const useGetPDSSpineData = (nhsNumber: string, enabled: boolean = true) => {
    const queryKey = ['pds-spine-data', nhsNumber, enabled];
    return useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            if (nhsNumber === '' || !enabled) {
                throw new Error('NHS number not provided or PDS lookup not enabled');
            }
            return await getPDSSpineData(nhsNumber);
        },
        retry: 1,
        refetchInterval: 60_000,
        staleTime: 30_000,
    });
};
