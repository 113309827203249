import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { formatPractices } from '../modules/helpers/formatData';
import AsyncSelect from 'react-select/async';
import { OptionTypeBase } from 'react-select';

import { searchOrganisations } from '../../api/odsFhirApi';

interface IProps {
    name?: string;
    disabled?: boolean;
    value?: OptionTypeBase | null;
    onChange?: (value: OptionTypeBase | null) => void;
    className?: string;
    isMulti?: boolean;
    isClearable?: boolean;
    insideForm?: boolean;
}

export const PracticeSelect: FC<IProps> = ({
    name,
    value,
    onChange,
    className,
    isMulti = false,
    isClearable = false,
    disabled = false,
    insideForm = false,
    ...props
}) => {
    const [hasError, setHasError] = useState(false);

    const filterPractices = async (inputValue: string): Promise<string[]> => {
        try {
            setHasError(false);
            const { entry } = await searchOrganisations({
                'name:contains': inputValue,
                'ods-org-role': '76', // GP Practice
                active: 'true',
            });
            return formatPractices(
                entry
                    .map(({ resource }) => ({
                        name: resource.name,
                        odsCode: resource.id,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name)),
            );
        } catch (e) {
            setHasError(true);
            return [];
        }
    };

    const loadOptions = (inputValue: string) => {
        return new Promise<string[]>(async (resolve) => {
            if (inputValue?.length >= 3) {
                const practices = await filterPractices(inputValue);
                resolve(practices);
            } else {
                resolve([]);
            }
        });
    };

    return (
        <AsyncSelect
            loadOptions={loadOptions}
            noOptionsMessage={(val) =>
                hasError
                    ? 'Unable to retrieve practices from ODS'
                    : val.inputValue?.length > 2
                      ? 'No results'
                      : 'Type at least three characters to see results'
            }
            value={value}
            onChange={onChange}
            className={className}
            defaultOptions
            isClearable={isClearable}
            isDisabled={disabled}
            isMulti={isMulti}
            classNamePrefix="practice-select"
            backspaceRemovesValue={false}
            inputId="practices"
            {...props}
        />
    );
};

export default observer(PracticeSelect);
