import { FC, CSSProperties, useMemo, memo } from 'react';
import { Patient } from '@doc-abode/data-models';

import { IJobPos } from '../../../../../interfaces/ucr';
import { Job } from './Job';
import { EnumJobContainer } from './Job/JobTypes';

interface IProps {
    pos: IJobPos;
    job: Patient;
    container: EnumJobContainer;
}

const JobPreview: FC<IProps> = ({ pos, job, container }) => {
    const style: CSSProperties = useMemo(
        () => ({
            transform: `translate(${pos.left}px, ${pos.top}px)`,
            opacity: pos.overlapping?.length ? 0.25 : 0.5,
        }),
        [pos],
    );

    return (
        <div className="ucr__calendar-job-preview" style={style}>
            <Job pos={pos} job={job} container={container} preview />
        </div>
    );
};

export default memo(JobPreview);
