import { Schedule } from '@doc-abode/data-models';
import moment from 'moment/moment';
import { IAvailabilityTime } from './HcpAvailabilityFormTypes';

export const CUSTOM_PATTERN = { label: 'Custom', value: 'custom' };

export function reverseLookupScheduleToPattern(
    schedule: Schedule,
    patterns: IAvailabilityTime[],
    hasMultipleShifts: boolean = false,
) {
    if (hasMultipleShifts) {
        return CUSTOM_PATTERN;
    }

    // Extracting start and end time in local time for comparison
    const startTime = moment(schedule.startDateTime).format('HH:mm');
    const endTime = moment(schedule.endDateTime).format('HH:mm');

    const matchingPattern = patterns.find(
        (pattern) =>
            pattern.enabled && pattern.startTime === startTime && pattern.endTime === endTime,
    );

    if (!matchingPattern) {
        return CUSTOM_PATTERN;
    } else {
        return { label: matchingPattern.label, value: matchingPattern.value };
    }
}
