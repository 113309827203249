import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { ellipsis } from '../../../helpers';

interface IProps {
    label?: ReactNode;
    Icon?: any;
    urgent?: boolean;
    children: string;
    intent?: 'success' | 'warning' | 'danger';
    isMultiple?: boolean;
}

const InfoIconItem: FC<IProps> = ({ label, Icon, urgent, children, intent, isMultiple }) => {
    return (
        <div
            className={cn('v2-info-icon-wrap', {
                'v2-info-icon-wrap--multiple': isMultiple,
            })}
        >
            <div
                className={cn('v2-info-icon__title', {
                    [`v2-info__title--${intent}`]: intent,
                })}
            >
                <span>
                    {Icon ? (
                        <>
                            <span className="job-summary__hcp-double-up">
                                <Icon
                                    className={cn('job-summary__double-up-icon', {
                                        'job-summary__double-up-icon--urgent': urgent,
                                    })}
                                    style={{ verticalAlign: 'middle' }}
                                />{' '}
                            </span>
                            <span>{ellipsis(children)}</span>
                        </>
                    ) : (
                        label
                    )}
                </span>
            </div>
            {isMultiple && (
                <div
                    className={cn('v2-info-icon__definition', {
                        [`v2-info__definition--${intent}`]: intent,
                    })}
                >
                    {children || <span className="v2-info__not-set">Not set</span>}
                </div>
            )}
        </div>
    );
};

export default InfoIconItem;
