import React from 'react';
import { Icon, Button } from '@blueprintjs/core';

const SortIcon = (name, sortData) => {
    if (sortData.column !== name) {
        return;
    }
    return (
        <Icon
            icon={sortData.orderBy === 'asc' ? 'arrow-up' : 'arrow-down'}
            color="grey"
            iconSize={16}
        />
    );
};

const SortedHeader = ({ sort, sortBy, text, sortData }) => {
    return (
        <th className="sorted-header">
            <Button
                onClick={sort(sortBy)}
                icon={SortIcon(sortBy, sortData)}
                minimal
                fill
                alignText="left"
            >
                {text}
            </Button>
        </th>
    );
};

export default SortedHeader;
