import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Intent, Tag } from '@blueprintjs/core';

import {
    formatDisplayDate,
    formatDisplayTime,
    formatTimeDifference,
} from '../../../../modules/helpers/formatData';
import SortedHeader from '../../../../common/sorting/SortedHeader';
import useHeaderSorting from '../../../../../hook/useHeaderSorting';
import { friendlyShortRouteTypes, routeStatusMappings, routeStatusTags } from '../../utils';
import { VaccinationRoute } from '../../types';

interface Props {
    jobs: VaccinationRoute[];
    handleOnClick: (id: string) => void;
    hubs: any[];
    onSort: (sortBy: string, sortDirection: string) => void;
}

export const RoutesTable: FC<Props> = ({ jobs, handleOnClick, hubs, onSort }) => {
    const [initialSortApplied, setInitialSortApplied] = useState(false);

    const mapJob = (job: VaccinationRoute) => ({
        requiresAttention: job.requiresAttention,
        id: job.id,
        routeType: job.routeType,
        date: formatDisplayDate(job.itinerary.route.startTime),
        dateTicks: moment(new Date(job.itinerary.route.startTime)).valueOf(),
        time: `${formatDisplayTime(job.itinerary.route.startTime)} - ${formatDisplayTime(
            job.itinerary.route.endTime,
        )}`,
        hcpId: job.hcpId || job.nominatedHcps[0],
        wayPoints: job.itinerary.instructions.filter(
            ({ instructionType }) => instructionType === 'VisitLocation',
        ).length,
        hub: hubs?.find((hub) => hub.id === job.hubId)?.name,
        expiry: job.jobStatus === 'AVAILABLE' && formatTimeDifference(job.expiryDateTime),
        expiryTicks:
            job.jobStatus === 'AVAILABLE' && moment(new Date(job.expiryDateTime || '')).valueOf(),
        dateAdded: formatDisplayDate(job.createDateTime),
        dateAddedTicks: moment(new Date(job.createDateTime)).valueOf(),
        jobStatus: job.jobStatus,
        jobStatusText: routeStatusMappings[job.jobStatus],
    });

    const { sort, sortData } = useHeaderSorting(mapJob, onSort, true);

    useEffect(() => {
        if (!initialSortApplied) {
            sort('dateTicks', 'desc')();
            setInitialSortApplied(true);
        }
    }, [sort, initialSortApplied]);

    const getJobs = () => {
        return jobs.map(mapJob);
    };

    return (
        <table
            className="bp5-html-table bp5-interactive patients-table"
            data-test="routesTable"
            data-testid="routesTable"
        >
            <thead>
                <tr>
                    <SortedHeader
                        sort={sort}
                        sortBy={'dateTicks'}
                        text="Date"
                        sortData={sortData}
                    />
                    <SortedHeader sort={sort} sortBy={'time'} text="Time" sortData={sortData} />
                    <SortedHeader
                        sort={sort}
                        sortBy={'routeType'}
                        text="Route type"
                        sortData={sortData}
                    />
                    <SortedHeader sort={sort} sortBy={'hcpId'} text="HCP" sortData={sortData} />
                    <SortedHeader
                        sort={sort}
                        sortBy={'wayPoints'}
                        text="Patients"
                        sortData={sortData}
                    />
                    <SortedHeader sort={sort} sortBy={'hub'} text="Hub" sortData={sortData} />
                    <SortedHeader
                        sort={sort}
                        sortBy={'expiryTicks'}
                        text="Expiry"
                        sortData={sortData}
                    />
                    <SortedHeader
                        sort={sort}
                        sortBy={'dateAddedTicks'}
                        text="Date created"
                        sortData={sortData}
                    />
                    <SortedHeader
                        sort={sort}
                        sortBy={'jobStatusText'}
                        text="Status"
                        sortData={sortData}
                    />
                </tr>
            </thead>
            <tbody>
                {getJobs().map(
                    ({
                        id,
                        date,
                        time,
                        jobStatus,
                        hcpId,
                        requiresAttention,
                        hub,
                        expiry,
                        dateAdded,
                        wayPoints,
                        jobStatusText,
                        routeType,
                    }) => (
                        <tr
                            className={`patients-table__row patients-table__row--${jobStatus
                                .toLowerCase()
                                .replace(/_/g, '-')} ${
                                requiresAttention ? 'patients-table__row--requires-attention' : ''
                            }`}
                            onClick={() => handleOnClick(id)}
                            key={id}
                        >
                            <td>{date}</td>
                            <td>{time}</td>
                            <td>{friendlyShortRouteTypes[routeType as 'COVID' | 'NON_COVID']}</td>
                            <td>{hcpId}</td>
                            <td>{wayPoints}</td>
                            <td>{hub}</td>
                            <td>{expiry}</td>
                            <td>{dateAdded}</td>
                            <td>
                                <Tag
                                    intent={routeStatusTags[jobStatus] as Intent}
                                    minimal={jobStatus !== 'COMPLETED'}
                                >
                                    {jobStatusText}
                                </Tag>
                            </td>
                        </tr>
                    ),
                )}
            </tbody>
        </table>
    );
};
