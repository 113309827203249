import { useField } from 'formik';
import { HTMLSelect, FormGroup, HTMLSelectProps } from '@blueprintjs/core';

interface ISelectInput extends HTMLSelectProps {
    name: string;
    label?: string;
    helperText?: string;
    labelInfo?: string;
}

const SelectInput = ({ label, helperText, labelInfo, ...props }: ISelectInput) => {
    const [{ name, onBlur, onChange, value }, meta] = useField(props);

    const fieldProps = { name, onBlur, onChange, value };

    return (
        <FormGroup
            label={label}
            labelFor={props.name}
            helperText={helperText}
            labelInfo={labelInfo}
        >
            <HTMLSelect {...fieldProps} {...props} id={props.name} fill />
            {meta.touched && meta.error ? (
                <div className="form-error-message">{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};

export default SelectInput;
