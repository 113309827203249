import cn from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, LegacyRef, useMemo } from 'react';

import { JobStatus, Patient } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst } from '@doc-abode/helpers';

import { isSecondDoubleUpHcp as isSecondDoubleUpHcpFunc } from '../../../../../../helpers/ucr/helpers';
import useStores from '../../../../../../hook/useStores';

import { ViewToShow } from '../../../../../../constants/mainConst';
import { getJobStatus, isAborted, isArrived } from '../../../../../../helpers/statusCheckHelper';
import { isAdminJob } from '../../../../../../helpers/ucr/isAdminJob';
import { IJobPos } from '../../../../../../interfaces/ucr';
import RootStore from '../../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import S1SyncStatusLabel from '../../../blocks/panels/children/S1SyncStatusLabel';
import { useWarningConditions } from '../../../hooks/useWarningConditions';
import { useView } from '../../../views/useView';
import { EnumJobContainer } from './JobTypes';
import WarningIcons from './WarningIcons';

interface IProps {
    pos?: IJobPos | any;
    job: Patient;
    showJobStatus?: boolean;
    container: EnumJobContainer;
    draggable?: boolean;
    preview?: boolean;
    elementRef?: LegacyRef<HTMLDivElement>;
}

export const getClassByStatus = (
    status?: string,
    trasformToCheck = (status: JobStatus) => status as string,
) => {
    switch (status) {
        case trasformToCheck(JobStatus.AVAILABLE):
        case trasformToCheck(JobStatus.PENDING):
            return 'available';
        case trasformToCheck(JobStatus.CURRENT):
            return 'current';
        case trasformToCheck(JobStatus.COMPLETED):
            return 'completed';
        case trasformToCheck(JobStatus.ACCEPTED):
            return 'accepted';
        case trasformToCheck(JobStatus.ARRIVED):
            return 'arrived';
        case trasformToCheck(JobStatus.HCP_ABORTED):
        case trasformToCheck(JobStatus.WITHDRAWN):
        case trasformToCheck(JobStatus.CONTROLLER_ABORTED):
            return 'aborted';
        default:
            return 'available';
    }
};

const Job: FC<IProps> = ({
    pos = {
        height: 0,
        width: 0,
        isDoubleUp: false,
        hcpId: undefined,
        buddyId: undefined,
        offsetWidth: 0,
        isExpanded: false,
    },
    job,
    container,
    draggable = true,
    showJobStatus = true,
    preview = false,
    elementRef,
}) => {
    const isAdminTime = isAdminJob(job);

    const {
        RootStore: {
            ucrStore: { focusedJobId, focusedUser, hcpsPos, draggedJobs },
            configStore: { pathways, adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { hasUnresolvedPatientAlerts, isPartiallyAssigned } = useWarningConditions({ job });

    const style = useMemo(() => {
        const styles: Record<string, unknown> = {};

        if ((pos.width && pos.height) || hcpsPos[pos.hcpId!]) {
            const height = hcpsPos[pos.hcpId!] ? hcpsPos[pos.hcpId!].height : pos.height;
            styles.width = `${pos.width}px`;
            styles.height = `${height}px`;
        }

        return styles;
    }, [pos.width, pos.height, pos.hcpId, hcpsPos]);

    const { openDeepLink } = useView();

    const onClickJob = () => {
        openDeepLink(job.id, pos.isSecondDoubleUpHcp ? 'user2' : 'user1');
    };

    const isSecondDoubleUpHcp = isSecondDoubleUpHcpFunc(job, pos as IJobPos);

    const jobStatus = getJobStatus(job, isSecondDoubleUpHcp);
    // Usual fallback to "Not started" in case the optional job status has not been set
    const jobStatusLabel = Patient.getFriendlyVisitStatus(
        jobStatus || JobStatus.PENDING,
    ) as JobStatus;

    const isArrivedJob = isArrived({ jobStatus });
    const isAbortedJob = isAborted({ jobStatus });

    const adminType = adminTypes.find((adminType: any) => {
        return adminType.value === job.activityType;
    });

    const bgClass = hasUnresolvedPatientAlerts ? 'alert-unresolved' : null;

    const borderClass = getClassByStatus(jobStatus);

    // Used for any job - whether single staff or dbl-up - that is unassigned or partially assigned
    const isUnassigned = jobStatus === JobStatus.PENDING || isPartiallyAssigned;

    const isFocused =
        !focusedUser ||
        (!pos.isDoubleUp && focusedUser === 'user1') ||
        (pos.isDoubleUp && focusedUser === 'user2');

    const hasBeenDragged = draggedJobs[job.id];
    const pathway = pathways.find((pathway: any) => pathway.value === job.referralPathway);
    const serviceProps = pathway?.services.find(
        (service: any) => service.value === job.disposition,
    );

    const backgroundColor = serviceProps?.color || adminType?.color;

    const showName = !!job.lastName;
    const showReferrer = !isAdminTime && isUnassigned && container === EnumJobContainer.UNASSIGNED;

    const dateTimeToShow = job.startDateTime || job.dateOfVisit;
    const showDateTime =
        isUnassigned && !!dateTimeToShow && container === EnumJobContainer.UNASSIGNED;

    return (
        <div
            className={cn('ucr__calendar-job', `ucr__calendar-job--${container}`, {
                [`ucr__calendar-job--${bgClass}`]: bgClass !== null,
            })}
            style={style}
            ref={elementRef}
        >
            {pos.offsetWidth && isArrivedJob ? (
                <div
                    className="ucr__calendar-job-offset"
                    onClick={onClickJob}
                    style={{ width: pos.offsetWidth }}
                />
            ) : null}

            <button
                className={cn('ucr__calendar-job-inner', {
                    'ucr__calendar-job-inner--active-focused': focusedJobId === job.id && isFocused,
                    'ucr__calendar-job-inner--active': focusedJobId === job.id && !isFocused,
                    'ucr__calendar-job-inner--draggable': draggable,
                    'ucr__calendar-job-inner--has-been-dragged': hasBeenDragged,
                    'ucr__calendar-job-inner--preview': preview,
                })}
                onClick={onClickJob}
            >
                <div
                    className={cn(
                        'ucr__calendar-job-main',
                        `ucr__calendar-job-main--${borderClass}`,
                        {
                            [`ucr__calendar-job-main--${bgClass}`]: bgClass !== null,
                        },
                        {
                            'ucr__calendar-job-main--unassigned': !showJobStatus,
                        },
                        {
                            'ucr__calendar-job-main--partially-assigned': isPartiallyAssigned,
                        },
                    )}
                    style={{
                        ...(backgroundColor &&
                            !hasUnresolvedPatientAlerts && {
                                backgroundColor,
                            }),
                    }}
                >
                    <S1SyncStatusLabel
                        patient={job}
                        isBuddy={pos.isSecondDoubleUpHcp}
                        label="S1"
                        view={ViewToShow.TIMELINE}
                        includeReferral={true}
                    />

                    <div className="ucr__calendar-job-about">
                        <ConditionalDisplay show={isAdminTime}>
                            <span className="ucr__calendar-job-fullName" aria-label="activity">
                                {job.activityType}
                            </span>
                        </ConditionalDisplay>
                        <ConditionalDisplay show={showName}>
                            <span className="ucr__calendar-job-fullName" aria-label="patient name">
                                {formatNameLastMiddleFirst({
                                    firstName: job.firstName,
                                    middleName: job.middleName,
                                    lastName: job.lastName,
                                })}
                            </span>
                        </ConditionalDisplay>
                        <span className="ucr__calendar-job-fullName" aria-label="postcode">
                            {job.postCode}
                        </span>
                        <ConditionalDisplay show={!isAdminTime}>
                            <span className="ucr__calendar-job-fullName ucr__calendar-job-pathway">
                                {pathway?.label || job.referralPathway}
                            </span>
                        </ConditionalDisplay>
                        <ConditionalDisplay show={showReferrer}>
                            <span className="ucr__calendar-job-postCode">{job.referrer}</span>
                        </ConditionalDisplay>
                        <ConditionalDisplay show={showDateTime}>
                            <span
                                className={cn('ucr__calendar-job-date', {
                                    'ucr__calendar-job-date--red': moment().isAfter(
                                        job.startDateTime || job.dateOfVisit,
                                        'day',
                                    ),
                                })}
                            >
                                {job.startDateTime
                                    ? moment(job.startDateTime).format('DD-MMM-YYYY H:mm')
                                    : moment(job.dateOfVisit).format('DD-MMM-YYYY')}
                            </span>
                        </ConditionalDisplay>
                    </div>

                    <ConditionalDisplay show={!isAbortedJob}>
                        <WarningIcons
                            container={container}
                            job={job}
                            isBuddy={isSecondDoubleUpHcp}
                        />
                    </ConditionalDisplay>
                </div>
                <ConditionalDisplay show={showJobStatus}>
                    <div
                        className={cn(
                            'ucr__calendar-job-side',
                            `ucr__calendar-job-side--${borderClass}`,
                        )}
                    >
                        <span className="ucr__calendar-job-status">
                            {hasBeenDragged ? 'Updating' : jobStatusLabel}
                        </span>
                    </div>
                </ConditionalDisplay>
            </button>
        </div>
    );
};

export default observer(Job);
