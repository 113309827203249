import { ItemRenderer, Select } from '@blueprintjs/select';
import { useCallback, useState, useEffect } from 'react';
import { MenuItem } from '@blueprintjs/core';
import cn from 'classnames';
export enum SicknessItem {
    SICK = 'Sick',
    NOT_SICK = 'Not Sick',
}

interface IProps {
    isSick?: boolean;
    handleChange?: (val: boolean) => void;
}

export const useSicknessViewModel = (props: IProps) => {
    const { isSick, handleChange } = props;
    const SicknessSelect = Select.ofType<SicknessItem>();
    const [selected, setSelected] = useState<SicknessItem>(SicknessItem.NOT_SICK);

    useEffect(() => {
        setSelected(isSick ? SicknessItem.SICK : SicknessItem.NOT_SICK);
    }, [isSick]);

    const onSelect = useCallback(
        (val: SicknessItem) => {
            setSelected(val);
            handleChange && handleChange(val === SicknessItem.SICK);
        },
        [handleChange, setSelected],
    );
    const itemRenderer = useCallback<ItemRenderer<SicknessItem>>((isSick, props) => {
        if (!props.modifiers.matchesPredicate) {
            return null;
        }
        const key = Object.keys(SicknessItem)[Object.values(SicknessItem).indexOf(isSick)];
        return (
            <MenuItem
                active={props.modifiers.active}
                key={key}
                className={cn('ucr__calendar-hcp-sickness-option', {
                    'ucr__calendar-hcp-sickness-option--active': props.modifiers.active,
                })}
                onClick={props.handleClick}
                icon={props.modifiers.active ? 'tick' : null}
                text={isSick}
            />
        );
    }, []);

    return {
        SicknessSelect,
        selected,
        setSelected,
        itemRenderer,
        onSelect,
    };
};
