import * as Yup from 'yup';
import { FormSteps, patientDetailsOptionalSchema, patientDetailsRequiredSchema } from '../common';

const activityDetails = Yup.object({
    activityType: Yup.string().required('Activity is required'),
    hcpId: Yup.string().required('Staff is required'),
    visitDate: Yup.date().typeError('Date is required'),
    startTime: Yup.date().typeError('Start time is required').required('Start time is required'),
    duration: Yup.string()
        .typeError('Duration is required')
        .required('Duration is required')
        .not(['00:00'], 'Duration cannot be 0 minutes'),
    notes: Yup.string(),
});

export const getStepSchema = (step: FormSteps, { patientFieldsRequired = false }) => {
    if (step === FormSteps.PATIENT) {
        return patientFieldsRequired ? patientDetailsRequiredSchema : patientDetailsOptionalSchema;
    } else {
        return activityDetails;
    }
};

export const getValidationSchema = ({ patientFieldsRequired = false }) =>
    patientFieldsRequired
        ? patientDetailsRequiredSchema.concat(activityDetails)
        : patientDetailsOptionalSchema.concat(activityDetails);
