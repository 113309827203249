import * as Yup from 'yup';
import { isStrongPassword } from 'validator';

const Validation = () => {
    const loginValidationSchema = Yup.object({
        userName: Yup.string().required('This field is required.'),
        password: Yup.string().required('This field is required.'),
    });

    const newPasswordValidationSchema = Yup.object({
        newPassword: Yup.string()
            .min(16, 'Password must contain at least 16 characters.')
            .required('This field is required.')
            .test(
                'is-strong-password',
                'Password must contain at lease one upper case letter, one lower case letter, one number and one symbol character.',
                (value = '') =>
                    isStrongPassword(value, {
                        minLength: 16,
                        minLowercase: 1,
                        minUppercase: 1,
                        minNumbers: 1,
                        minSymbols: 1,
                    }),
            ),
    });

    const mfaSchema = Yup.object({
        code: Yup.string()
            .matches(/^\d{6}$$/, 'Verification Code must be 6 digits only.')
            .required('This field is required.'),
    });

    return { loginValidationSchema, newPasswordValidationSchema, mfaSchema };
};

export default Validation;
