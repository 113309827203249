import { Popover } from '@blueprintjs/core';
import { FC } from 'react';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';
import { IconExpandMore } from '../../../../../helpers/ucr/icons';

export interface IQuickActionsButton {
    Icon?: FC;
    name: string;
    disabled?: boolean;
    color?: ButtonColors;
    size?: ButtonSizes;
    clickEvent: () => void;
}

interface IQuickActionsDropDown {
    text: string;
    color?: ButtonColors;
    size?: ButtonSizes;

    dropDownButtonSize?: ButtonSizes;
    dropDownButtonColor?: ButtonColors;

    buttons: IQuickActionsButton[];
}

export const QuickActionsDropDown: FC<IQuickActionsDropDown> = ({
    text,
    buttons,
    size,
    color,
    dropDownButtonSize,
    dropDownButtonColor,
}) => {
    return (
        <Popover
            position="bottom-left"
            content={
                <div className="quick-actions-drop-down__drop-down">
                    {buttons.map(({ clickEvent, ...button }, i) => (
                        <div
                            key={`quick-actions-button ${clickEvent.name} ${i}`}
                            className="quick-actions-drop-down__wrapper"
                        >
                            <Button
                                {...button}
                                style={{ border: 'none' }}
                                key={`quick-actions-button ${clickEvent.name} ${i}`}
                                className="quick-actions-drop-down__button"
                                color={dropDownButtonColor || ButtonColors.WHITE}
                                size={dropDownButtonSize}
                                clickEvent={() => {
                                    if (button.disabled) {
                                        return;
                                    }

                                    clickEvent();
                                }}
                            />
                        </div>
                    ))}
                </div>
            }
        >
            <Button name={text} color={color} size={size} Icon={IconExpandMore} />
        </Popover>
    );
};
