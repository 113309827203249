import { FC } from 'react';

import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import Linkify from 'react-linkify';

interface Props {
    messages?: {
        timestamp: string;
        message: string;
    }[];
}

export const StaffAlertMessages: FC<Props> = ({ messages }) => {
    if (!messages || messages.length === 0) {
        return <div className="v2__form-section">There are no messages from this staff member</div>;
    }

    return (
        <div className="v2__form-section">
            {messages.map((message, i) => (
                <div key={`ucr-staff-alert-dialog__messages-item++${i}`}>
                    <span className="ucr-staff-alert-dialog__message-timestamp">
                        {formatDisplayDateTime(message.timestamp)}
                    </span>
                    <pre className="ucr-staff-alert-dialog__message">
                        <Linkify>{message.message}</Linkify>
                    </pre>
                </div>
            ))}
        </div>
    );
};
