import { JobStatus, Service, AdminTypeV2, Pathway } from '@doc-abode/data-models';
import {
    getFriendlyPathwayLabel,
    getFriendlyServiceLabel,
    getFriendlyAdminTypeLabel,
} from '@doc-abode/helpers';

export enum FormMode {
    ADD_VISIT = 'addVisit',
    EDIT_VISIT = 'editVisit',
    FOLLOW_UP = 'followUp',
    DEFAULT = 'default',
}

export enum FormSteps {
    PATIENT = 'patient',
    ACTIVITY = 'activity',
    REVIEW = 'review',
    REFERRAL = 'referral',
    CARE = 'care',
}

export enum ButtonNames {
    ADD_VISIT = 'Save',
    EDIT_VISIT = 'Save',
    DEFAULT_LAST = 'Review',
    DEFAULT = 'Continue',
}

type FriendlyVisitDetails = {
    [key: string]: string;
};

export const getButtonName = (formMode: FormMode, step?: FormSteps): ButtonNames => {
    if (formMode === FormMode.ADD_VISIT) {
        return ButtonNames.ADD_VISIT;
    } else if (formMode === FormMode.EDIT_VISIT) {
        return ButtonNames.EDIT_VISIT;
    } else if (step && [FormSteps.ACTIVITY, FormSteps.CARE].includes(step)) {
        return ButtonNames.DEFAULT_LAST;
    } else {
        return ButtonNames.DEFAULT;
    }
};

export const friendlyDisposition: FriendlyVisitDetails = {
    within2hResponse: 'Within 2h response',
    '0-1DayResponse': '0-1 day response',
    over2hResponse: 'Over 2h response',
    '2dReablement': '2d reablement',
    '2hCrisis': '2h Crisis',
    '24hVisit': '24h Visit',
    followUp: 'Follow Up',
    admin: 'Admin',
    other: 'Other',
};

export const getPathwayDisplayName = (pathways: Pathway[] = [], value?: string): string => {
    // Default value if pathway cannot be resolved
    let name = 'Other';

    if (value) {
        // Use the display label from configuration where found
        // or fallback to uppercasing the value for defunct/non-migrated pathways
        name = getFriendlyPathwayLabel(value, pathways, value.toUpperCase());
    }

    return name;
};

export const getServiceDisplayName = (services: Service[] = [], value?: string): string => {
    let name = '';

    if (value) {
        name = getFriendlyServiceLabel(value, services, friendlyDisposition[value]);
    }

    // We used to have hardcoded values for service names which are no longer supported
    return name;
};

export const getAdminDisplayName = (adminTypes: AdminTypeV2[] = [], value?: string): string => {
    let name = 'Admin';

    if (value) {
        name = getFriendlyAdminTypeLabel(value, adminTypes, value.toUpperCase());
    }

    return name;
};

export const friendlyCareComplexity: FriendlyVisitDetails = {
    nonComplex: 'Non-complex',
    complex: 'Complex',
};
export const abortStatusCheck = (jobStatus: JobStatus) =>
    jobStatus === JobStatus.CONTROLLER_ABORTED || jobStatus === JobStatus.HCP_ABORTED;
