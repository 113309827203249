import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { JobType, Patient } from '@doc-abode/data-models';

import { JobFilter } from '../../../../../__generated__/v2';
import { QUERY_JOBS_BY_JOB_TYPE } from '../../../../../graphql/queries/jobs';
import { formatNhsNumber } from '../../../../../helpers/ucr';
import {
    DATEMODE,
    sortJobsByPlannedTime,
    SORTORDER,
} from '../../../../../helpers/ucr/jobStartDateHelpers';
import useStores from '../../../../../hook/useStores';
import useVisitWarnings from '../../../../../hook/useVisitWarnings';
import RootStore from '../../../../../stores/RootStore';
import Loader from '../../../../modules/helpers/Loader';
import { Button, ButtonSizes } from '../../../../v2/components';
import { Text, TextInput } from '../../../../v2/form';
import { graphqlErrorHandler } from '../../../common/errorHandling/graphqlErrorHandler';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import {
    FormMode,
    FormSteps,
    getButtonName,
    LookupPatientlBtn,
    PullFromReferralBtn,
    warningHighlight,
} from '../common';
import PatientDataForm from '../PatientDataForm/PatientDetailsPdsLookup';
import { PatientDetailsForm } from './PatientDetailsForm';
import { PdsIssues } from './PdsIssues';

type ApiResponse = {
    queryJobsByJobTypeIndex: {
        items?: Patient[];
    };
};

type PatientType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    onNextStep?: () => void;
    onSaveForm?: (curStep: FormSteps) => void;
    languagesSpoken?: any;
    gender?: any;
    genderStaffPreferred?: any;
    showPullFromReferral?(): void;
    required?: boolean;
    handleChangePatientRequired?: (isRequired: boolean) => void;
};

const sortByJobStartDesc = (a: Patient, b: Patient) => {
    const sortMode = SORTORDER.DESC;
    const dateMode = DATEMODE.AS_IS;
    return sortJobsByPlannedTime(a, b, dateMode, sortMode);
};

const nhsNumberValidationRe = /^\d{10}$/;

export const PatientDetailsPds: FC<PatientType> = ({
    values,
    loading,
    formMode,
    languagesSpoken,
    gender,
    onNextStep = () => {},
    onSaveForm = () => {},
    genderStaffPreferred,
    showPullFromReferral,
    required = true,
    handleChangePatientRequired,
}) => {
    const {
        RootStore: {
            ucrStore: {
                loadingLocalData,
                setLocalPatientDataLoading,
                localPatientData,
                setLocalPatientData,
                clearLocalPatientData,
                setSelectedPatientData,
                hasSelectedPatientData,
                pdsData,
                getPDSData,
                clearPDSData,
                loadingPDSData,
                pdsError,
                setPDSErrorMessage,
                pdsWarnings,
                setPDSWarnings,
            },
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();
    const { setFieldValue, setValues }: FormikContextType<FormikValues> = useFormikContext();
    const pdsEnabled = isFeatureEnabled('pdsLookup');

    // ************ warnings Start ************
    const warnings = useVisitWarnings(values as VisitValuesType);
    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);
    const [highlightedWarnings, setHighlightedWarnings] = useState<any>({});
    // ************ warnings  END ************

    const [performedSearch, setPerformedSearch] = useState(false);

    const [requestedNhsNumber, setRequestedNhsNumber] = useState('');

    const hasFormikPatientData = [
        values.firstName,
        values.lastName,
        values.middleName,
        values.addressLine1,
        values.addressLine2,
        values.addressLine3,
        values.town,
        values.postCode,
    ].some((v) => !!v);
    const lookupDisabled = formMode === FormMode.EDIT_VISIT;
    const isValidNhsNumber = nhsNumberValidationRe.test(values.nhsNumber);
    const nhsNumberChanged = values.nhsNumber !== requestedNhsNumber;
    const hasReferralData = values.fromReferral && hasFormikPatientData && !nhsNumberChanged;
    const hasLocalPatientData =
        isValidNhsNumber && !nhsNumberChanged && !loading && !!localPatientData;
    const hasPdsData =
        pdsEnabled &&
        isValidNhsNumber &&
        !nhsNumberChanged &&
        !loadingPDSData &&
        Object.keys(pdsData?.[requestedNhsNumber] || {}).length > 0;

    const clearFormikPatientData = () => {
        setValues({
            ...values,
            nhsNumber: '',
            dateOfBirth: new Date(),
            gender: '',
            firstName: '',
            middleName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            languagesSpoken: undefined,
            staffPreferredGender: undefined,
            contactNumber: undefined,
            additionalContactNumbers: undefined,
            town: '',
            postCode: '',
            visitDate: new Date(),
            duration: '01:00',
        });
        setSelectedPatientData();
    };

    const clearPatientDetails = () => {
        clearLocalPatientData();
        clearFormikPatientData();
        setPerformedSearch(false);
        setRequestedNhsNumber('');
        setLocalPatientData();
        clearPDSData();
        setPDSErrorMessage();
        setPDSWarnings();
        setFieldValue('fromReferral', false);

        handleChangePatientRequired?.(false);
    };

    // On init make sure we clear out previously selected data
    useEffect(() => {
        setSelectedPatientData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isValidNhsNumber) {
            setHighlightedWarnings(
                warningHighlight({
                    warnings: warnings,
                    values: values,
                }),
            );
        }
    }, [warnings, values, isValidNhsNumber]);

    const [loadJobs, { data, loading: loadingPatient }] = useLazyQuery<ApiResponse>(
        QUERY_JOBS_BY_JOB_TYPE,
        {
            fetchPolicy: 'network-only',
            onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
        },
    );

    // 2.1 save Local Data to Store
    useEffect(() => {
        if (!loadingPatient && data?.queryJobsByJobTypeIndex?.items && isValidNhsNumber) {
            const patientJobs = data?.queryJobsByJobTypeIndex?.items?.length
                ? [...data.queryJobsByJobTypeIndex.items]
                : undefined;

            if (patientJobs?.length) {
                patientJobs.sort(sortByJobStartDesc);
                const lastJob = patientJobs[patientJobs?.length - 1];
                setLocalPatientData({
                    ...lastJob,
                    dateOfBirth: lastJob.dateOfBirth
                        ? moment(lastJob.dateOfBirth).toDate()
                        : undefined,
                } as unknown as Patient);
            }
            setPerformedSearch(true);
            setLocalPatientDataLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loadingPatient]);

    // 1. load Local & PDS Patient Data by nhsNumber
    const findByNhsNumber = async () => {
        if (!isValidNhsNumber) return;

        if (pdsEnabled) {
            await getPDSData(values.nhsNumber);
        }

        const v2Filter: JobFilter = {
            nhsNumber: {
                equals: values.nhsNumber,
            },
            dynamoDBIndexName: 'jobType-index',
            jobType: {
                equals: JobType.UCR,
            },
        };

        // Local
        await loadJobs({
            variables: {
                jobType: JobType.UCR,
                filter: {
                    nhsNumber: {
                        eq: values.nhsNumber,
                    },
                },
                v2Filter: JSON.stringify(v2Filter),
            },
        });
    };

    const setToFormPatientData = (data: Patient) => {
        const {
            firstName,
            lastName,
            middleName,
            gender,
            dateOfBirth,
            addressLine1,
            addressLine2,
            addressLine3,
            town,
            postCode,
            languagesSpoken,
            staffPreferredGender,
            contactNumber,
            additionalContactNumbers,
            pds,
            nhsNumber,
        } = data;

        const patientData = {
            dateOfBirth: dateOfBirth ? moment(dateOfBirth).toDate() : moment().toDate(),
            gender,
            nhsNumber: nhsNumber || requestedNhsNumber,
            firstName,
            middleName,
            lastName,
            addressLine1,
            addressLine2,
            addressLine3,
            languagesSpoken: languagesSpoken ? [...languagesSpoken] : undefined,
            staffPreferredGender: staffPreferredGender ? [...staffPreferredGender] : undefined,
            contactNumber,
            additionalContactNumbers,
            town,
            postCode,
            pds,
        };

        setValues({
            ...values,
            ...patientData,
            visitDate: new Date(),
            duration: '01:00',
        });

        setSelectedPatientData(patientData as unknown as Patient);

        clearLocalPatientData();
        clearPDSData(false);
    };

    const onSubmit = () => setIsContinueBtnClicked(true);

    const RenderNoData = () => {
        return (
            <div className="v2__form-no-data">
                <Text
                    name="name"
                    title={
                        pdsEnabled
                            ? 'Unable to retrieve data from SPINE and no local data found'
                            : 'No local data found'
                    }
                    description=""
                    titleClassName="v2__form-no-data--title"
                />
            </div>
        );
    };

    const RenderDataVersionDiffer = () => {
        return (
            <div className="v2__form-no-data">
                <Text
                    name="name"
                    title="Patient details on SPINE have been updated"
                    description="Please review the latest data carefully and ensure your patient records are up-to-date"
                    titleClassName="v2__form-no-data--title"
                />
            </div>
        );
    };
    const isLoading = loadingPatient || loadingPDSData || loadingLocalData;
    const noPatientData =
        performedSearch &&
        !isLoading &&
        !hasLocalPatientData &&
        !hasPdsData &&
        !hasSelectedPatientData;

    const pdsVersionsDiffer =
        localPatientData?.pds?.versionId !== pdsData?.[requestedNhsNumber]?.versionId;

    const patientDataFormComponent =
        hasLocalPatientData || hasPdsData ? (
            <PatientDataForm
                nhsNumber={requestedNhsNumber}
                hasReferralData={hasReferralData}
                setToFormPatientData={setToFormPatientData}
            />
        ) : null;

    return (
        <>
            <div className="v2__form-block">
                <div className="v2__form-group  v2__form-group--pos-1-1">
                    <div className="v2__form-group--flex-wrap">
                        <div className="v2__form-group--flex-part">
                            <TextInput
                                name="pds.versionId"
                                className="v2__form-hidden-text"
                                type={'hidden'}
                                isContinueBtnClicked={isContinueBtnClicked}
                            />
                            <TextInput
                                name="nhsNumber"
                                label="NHS number"
                                className="v2__form-nhs-number"
                                required={required}
                                displayErrors={highlightedWarnings['nhsNumber']}
                                disabled={loading}
                                onChange={(event) => {
                                    setFieldValue('nhsNumber', formatNhsNumber(event.target.value));
                                }}
                                isContinueBtnClicked={isContinueBtnClicked}
                            />
                            <LookupPatientlBtn
                                clickEvent={() => {
                                    setValues({
                                        ...values,
                                        nhsNumber: values.nhsNumber,
                                        dateOfBirth: new Date(),
                                        gender: '',
                                        firstName: '',
                                        middleName: '',
                                        lastName: '',
                                        addressLine1: '',
                                        addressLine2: '',
                                        addressLine3: '',
                                        languagesSpoken: undefined,
                                        staffPreferredGender: undefined,
                                        contactNumber: undefined,
                                        additionalContactNumbers: undefined,
                                        town: '',
                                        postCode: '',
                                        visitDate: new Date(),
                                        duration: '01:00',
                                    });
                                    setPerformedSearch(false);
                                    setRequestedNhsNumber(values.nhsNumber); // set latest requested nhsData
                                    setLocalPatientData();
                                    clearPDSData();
                                    setPDSErrorMessage();
                                    setPDSWarnings();
                                    setLocalPatientDataLoading(true);
                                    setSelectedPatientData();

                                    findByNhsNumber();
                                    handleChangePatientRequired?.(true);
                                }}
                                className={`v2__form-group--align-center v2__form-btn-mt`}
                                btnStyle="v2__form-lookup-btn "
                                disabled={!isValidNhsNumber || lookupDisabled}
                            />
                        </div>
                        <div className="v2__form-group--flex-part">
                            {formMode !== FormMode.EDIT_VISIT && (
                                <span
                                    onClick={clearPatientDetails}
                                    className={`v2__form-block-clear-patient-details v2__form-btn-mt v2__form-group--align-center`}
                                >
                                    Clear patient details
                                </span>
                            )}
                            {formMode !== FormMode.EDIT_VISIT && (
                                <div className="v2__form-group--pos-4-411 v2__form-group--align-center">
                                    {showPullFromReferral && (
                                        <PullFromReferralBtn
                                            clickEvent={() => {
                                                showPullFromReferral();
                                            }}
                                            className="v2__form-group--pos-4-4 v2__form-group--align-center"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {noPatientData && <RenderNoData />}
                {performedSearch && (!!pdsError || pdsWarnings.length > 0) && <PdsIssues />}
                {performedSearch && hasLocalPatientData && hasPdsData && pdsVersionsDiffer && (
                    <RenderDataVersionDiffer />
                )}

                {!required && !values.nhsNumber && !performedSearch && (
                    <label className="v2__form-group">
                        <Button
                            className="v2__form-submit-button"
                            name={getButtonName(formMode)}
                            size={ButtonSizes.MEDIUM}
                            clickEvent={() => {
                                if (formMode === FormMode.EDIT_VISIT) {
                                    onSaveForm(FormSteps.PATIENT);
                                } else {
                                    onNextStep();
                                }
                                onSubmit();
                            }}
                        />
                    </label>
                )}
            </div>

            {isLoading ? <Loader /> : patientDataFormComponent}

            {(hasFormikPatientData ||
                (hasSelectedPatientData && !hasLocalPatientData && !hasPdsData) ||
                noPatientData) && (
                <PatientDetailsForm
                    loading={loading}
                    formMode={formMode}
                    gender={gender}
                    languagesSpoken={languagesSpoken}
                    genderStaffPreferred={genderStaffPreferred}
                    onNextStep={onNextStep}
                    onSaveForm={onSaveForm}
                    onSubmitAction={onSubmit}
                    handleChangePatientRequired={handleChangePatientRequired}
                    required={required}
                />
            )}
        </>
    );
};
