import { JobStatus, Patient } from '@doc-abode/data-models';
import { Form, FormikContextType, FormikValues, useFormikContext } from 'formik';
import { FC } from 'react';

import useStores from '../../../../../hook/useStores';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { Select } from '../../../../v2/form';

import { getFormattedHcpUserForRadioLabelChooser } from '../../../../../helpers/ucr/getFormattedHcpUserForRadioLabelChooser';
import { getHcp } from '../../../../../helpers/ucr/getHcp';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    visit: Patient | undefined;
    loading: boolean;
    onClose: () => void;
}

const ChangeVisitStatusForm: FC<IProps> = ({ visit, loading, onClose }) => {
    const {
        RootStore: {
            ucrStore: { focusedUser, hcps },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { values }: FormikContextType<FormikValues> = useFormikContext();

    if (!visit) {
        return null;
    }

    const user1 = getHcp(hcps, visit.hcpId);
    const user2 = getHcp(hcps, visit.buddyId);

    const user1Label = getFormattedHcpUserForRadioLabelChooser(visit, user1, 1);
    const user2Label = getFormattedHcpUserForRadioLabelChooser(visit, user2, 2);

    const prohibitedStatuses = [
        undefined,
        JobStatus.COMPLETED,
        JobStatus.CONTROLLER_ABORTED,
        JobStatus.HCP_ABORTED,
    ];

    const getCurrentJobStatus = (): JobStatus | undefined => {
        const isFirstUser = values.removedHcp === user1?.userId;
        const isSecondUser = values.removedHcp === user2?.userId;
        let currentJobStatus: JobStatus | undefined = JobStatus.AVAILABLE;

        if (isFirstUser) currentJobStatus = visit.jobStatus;
        if (isSecondUser) currentJobStatus = visit.buddyJobStatus;

        return currentJobStatus;
    };

    const currentJobStatus = getCurrentJobStatus();
    const isValid = !prohibitedStatuses.includes(currentJobStatus);

    return (
        <Form>
            <div className="v2__dialog-block v2__dialog-block--no-margin">
                {focusedUser && (
                    <div className="v2__dialog-radiobuttons-group-container">
                        <p>This is double-up visit. Please select the staff member to remove: </p>
                        <p>{user1Label}</p>
                        <p>{user2Label}</p>
                    </div>
                )}
            </div>
            <div className="v2__dialog-block">
                <Select
                    name="removedHcp"
                    options={[
                        {
                            label: user1Label,
                            value: user1?.userId || '',
                            disabled: prohibitedStatuses.includes(visit.jobStatus),
                        },
                        {
                            label: user2Label,
                            value: user2?.userId || '',
                            disabled: prohibitedStatuses.includes(visit.buddyJobStatus),
                        },
                    ]}
                />
            </div>

            <p>
                Removing the chosen staff member will remove all of their related information. Are
                you sure you want to proceed?
            </p>

            <div className="v2__dialog-change-visit-status-buttons-container">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.RED}
                    disabled={loading}
                    clickEvent={onClose}
                    className="v2__dialog-change-visit-status-button--big"
                />
                <Button
                    name="Confirm staff removal"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    className="v2__dialog-change-visit-status-button--big"
                    disabled={loading || !isValid}
                />
            </div>
        </Form>
    );
};

export default ChangeVisitStatusForm;
