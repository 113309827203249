import { Formik, FormikValues } from 'formik';
import { AlertCategory, AlertStatus, StaffAlert } from '@doc-abode/data-models';
import { FC, useState } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Dialogs } from '../../../../../stores/UCRStore';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { Form, Select, TextArea } from '../../../../v2/form';
import { Dialog } from '../../../../v2/overlays';

import { StaffAlertDetails } from './common/StaffAlertDetails';
import { StaffAlertMessages } from './common/StaffAlertMessages';
import { StaffAlertMap } from './common/StaffAlertMap';
import RootStore from '../../../../../stores/RootStore';

const ResolveStaffAlert: FC = () => {
    const [loading, setLoading] = useState(false);

    const {
        RootStore: {
            lovsStore: { controllerStaffAlertResolutionReason },
            ucrStore: {
                hcps,
                openedDialog,
                openedStaffAlertToResolve: alert,
                clearOpenedDialog,
                updateAlert,
            },
            configStore: { clientKeys },
        },
    } = useStores<{ RootStore: RootStore }>();

    const onSubmit = async (values: FormikValues) => {
        const { controllerResolutionReason, controllerResolutionNotes } = values;
        if (!controllerResolutionReason) {
            AppToaster.show({
                message: 'A resolution type must be set',
                intent: 'warning',
            });
            return;
        }

        const input: Partial<StaffAlert> = {
            status: AlertStatus.RESOLVED,
            category: AlertCategory.STAFF,
            resolution: controllerResolutionReason,
            resolutionNotes: controllerResolutionNotes,
        };

        try {
            setLoading(true);
            await updateAlert(alert!.alertId, input);
            setLoading(false);
            clearOpenedDialog();
            AppToaster.show({
                message: 'Patient alert successfully resolved',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error resolving patient alert:', err);
            setLoading(false);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to cancel the visit',
                intent: 'danger',
            });
        }
    };

    const close = () => {
        if (loading) {
            return;
        }

        clearOpenedDialog();
    };

    const showDialog = openedDialog === Dialogs.RESOLVE_STAFF_ALERT;

    if (showDialog && !alert) {
        console.log(showDialog, alert);
        AppToaster.show({
            message:
                'Sorry, an error occurred and we were unable to show the resoluition interface',
            intent: 'danger',
        });
        clearOpenedDialog();
    }

    return (
        <Dialog isShow={showDialog} closeEvent={close} title="Resolve Staff Alert">
            <Formik
                initialValues={{
                    controllerResolutionReason: controllerStaffAlertResolutionReason[0].value,
                    controllerResolutionNotes: '',
                }}
                onSubmit={onSubmit}
            >
                <Form>
                    <div className="v2__form-section">
                        <p>
                            Resolving a staff alert will remove the alert from the "Urgent Warnings"
                            and remove all visual cues of the alert on the dashboard.
                        </p>

                        <p>
                            Only resolve a staff alert if your staff is safe or the emergency has
                            otherwise been dealt with as per your standard operating procedures.
                        </p>
                    </div>

                    <StaffAlertDetails alert={alert!} hcps={hcps} />
                    <StaffAlertMessages messages={alert?.messages} />
                    {clientKeys.googleMaps.length > 0 && (
                        <StaffAlertMap alert={alert} apiKey={clientKeys.googleMaps} />
                    )}

                    <div className="v2__form-block">
                        <p className="v2__form-group--pos-1-1">
                            How do you want to resolve this alert?
                        </p>
                        <Select
                            label="Resolution to alert"
                            name="controllerResolutionReason"
                            className="v2__form-group--pos-1-1"
                            options={controllerStaffAlertResolutionReason}
                        />
                        <TextArea
                            label="Notes"
                            name="controllerResolutionNotes"
                            className="v2__form-group--pos-1-1"
                        />
                        <div className="v2__form-group v2__form-group--pos-1-1">
                            <Button
                                name="Cancel"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                color={ButtonColors.GREY}
                                disabled={loading}
                                clickEvent={close}
                            />
                            <Button
                                name="Resolve alert"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                type="submit"
                                color={ButtonColors.RED}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default observer(ResolveStaffAlert);
