import React from 'react';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

const CLIENT_SITE_KEY = process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY;

const Recaptcha = ({ disabled = false, name = 'recaptcha', ...props }) => {
    const [, meta, helpers] = useField({
        ...props,
        name,
    });

    const { setError, setValue } = helpers;
    const showError = meta.touched && meta.error;

    const onRecaptchaChange = (token) => {
        setValue(token, true);
        setError(null);
    };

    const onRecaptchaError = () => {
        setError('Unable to process Recaptcha, please try again');
    };

    return (
        <div className="recaptcha">
            <div className="recaptcha__input">
                <ReCAPTCHA
                    sitekey={CLIENT_SITE_KEY}
                    onChange={onRecaptchaChange}
                    onErrored={onRecaptchaError}
                />
            </div>
            {showError && <div className="form-error-message">{meta.error}</div>}
        </div>
    );
};

export default Recaptcha;
