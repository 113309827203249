import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../../../../hook/useStores';
import { TagInputComponent } from '../../../../v2/form/TagInput/TagInput';
import { Checkbox } from '@blueprintjs/core';
import { CommonControls } from '../CommonControls';
import RootStore from '../../../../../stores/RootStore';

export const PatientListViewControls: FC<{ refreshJobs(): void }> = observer(() => {
    const {
        RootStore: {
            patientListStore: { filters, setFilters },
        },
    } = useStores<{ RootStore: RootStore }>();

    const setNameAndNhsFilter = (values: ReactNode[]) => {
        const filtered = values.filter(
            (value): value is string => typeof value === 'string' && value.length !== 0,
        );
        setFilters('nameOrNhsNumber', filtered.length !== 0 ? filtered : undefined);
    };

    return (
        <div className="ucr__main-filters">
            <CommonControls
                disableDatePicker
                disableAddVisit
                disableAddAdmin
                additionalLeft={
                    <>
                        <TagInputComponent
                            name="patientName"
                            className="ucr__filters-filter-input ucr__filters-filter-first"
                            placeholder="Patient"
                            values={filters.nameOrNhsNumber || []}
                            onChange={(values) => setNameAndNhsFilter(values)}
                        />
                        {false && (
                            <div className="ucr__controls-button">
                                <Checkbox
                                    label="Show discharged patients"
                                    checked={Boolean(filters.showDischargedPatients)}
                                    onChange={(e) => {
                                        setFilters(
                                            'showDischargedPatients',
                                            filters.showDischargedPatients ? undefined : true,
                                        );
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                />
                            </div>
                        )}
                    </>
                }
            />
        </div>
    );
});
