import { LegendOptionV2, LiveLocationThreshold } from '@doc-abode/data-models';
import { FC } from 'react';

import {
    AddressWarningIcon,
    EarliestArrival,
    IconCallback,
    IconCarRequired,
    IconComplexCare,
    IconNonEnglishSpeaker,
    IconNote,
    IconS1Referral,
    IconWarning,
    LatestArrival,
    People,
} from '../../../../helpers/ucr/icons';
import useStores from '../../../../hook/useStores';

import { ViewToShow } from '../../../../constants/mainConst';
import RootStore from '../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../CondtionalDisplay';
import { Markers } from './mapView/Markers';
import { Panel } from './side';

interface IMapMarkerLegendProps {
    markerType: 'hcp' | 'job' | 'cluster' | 'base';
    fill: string;
    label: string;
    description: string;
}

const MapMarkerLegend: FC<IMapMarkerLegendProps> = ({ markerType, fill, label, description }) => {
    let marker;

    switch (markerType) {
        case 'base':
            marker = Markers.base();
            break;
        case 'hcp':
            marker = Markers.singleHcp({
                fill,
                label,
            });
            break;
        case 'job':
            marker = Markers.single({
                fill,
                label,
                foundHcp: false,
            });
            break;
        case 'cluster':
            marker = Markers.cluster({
                fill,
                label,
                foundHcp: false,
            });
            break;
    }

    return (
        <>
            <div
                dangerouslySetInnerHTML={{ __html: marker }}
                className="ucr__legend-panel-icon-map"
            />
            <p className="ucr__legend-panel-label-map">{description}</p>
        </>
    );
};

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const DynamicLegendOption = ({ option }: { option: LegendOptionV2 }) => (
    <div className="ucr__legend-panel-sub-section" key={option.color}>
        <div
            className="ucr__legend-panel-square-box-size"
            style={{ backgroundColor: option.color }}
        ></div>
        <p className="ucr__legend-panel-label">{option.label}</p>
    </div>
);

const Legend: FC<IProps> = ({ isShow, closePanel }) => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes, legend, liveLocationThreshold, isFeatureEnabled },
            ucrStore: { viewToShow },
        },
    } = useStores<{ RootStore: RootStore }>();

    const allServices = pathways.map((pathway: any) => pathway.services).flat();

    const locationDataConfig = liveLocationThreshold as LiveLocationThreshold;

    const visitLegend =
        legend.visit?.filter((option: any) =>
            allServices.some((service: any) => service.color === option.color && service.enabled),
        ) || [];

    const adminLegend =
        legend.admin?.filter((option: any) =>
            adminTypes.some((admin: any) => admin.color === option.color && admin.enabled),
        ) || [];

    return (
        <Panel
            title="Legend"
            side="right"
            isShow={isShow}
            closePanel={closePanel}
            testId="legendPanel"
        >
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-legend-wrapper">
                    <ConditionalDisplay show={viewToShow === ViewToShow.MAP}>
                        <h4 className="ucr__legend-panel-titles">Map icons</h4>
                        <div className="ucr__legend-panel-sub-section">
                            <MapMarkerLegend
                                markerType="base"
                                fill="notNeeded"
                                label="notNeeded"
                                description="Location of base"
                            />
                        </div>
                        <div className="ucr__legend-panel-sub-section">
                            <MapMarkerLegend
                                markerType="hcp"
                                fill={locationDataConfig?.color?.below ?? '834277'}
                                label="AB"
                                description={`HCP location <= ${locationDataConfig?.value} mins ago`}
                            />
                        </div>
                        <div className="ucr__legend-panel-sub-section">
                            <MapMarkerLegend
                                markerType="hcp"
                                fill={locationDataConfig?.color?.above ?? 'dda0dd'}
                                label="AB"
                                description={`HCP location > ${locationDataConfig?.value} mins ago`}
                            />
                        </div>
                        <div className="ucr__legend-panel-sub-section">
                            <MapMarkerLegend
                                markerType="job"
                                fill="4caf50"
                                label="AB"
                                description="Visit location: colour = status, initials = HCP"
                            />
                        </div>
                        <div className="ucr__legend-panel-sub-section">
                            <MapMarkerLegend
                                markerType="cluster"
                                fill="546e7a"
                                label="4"
                                description="Multiple pins in close proximity"
                            />
                        </div>
                    </ConditionalDisplay>

                    <h4 className="ucr__legend-panel-titles">Warning indicators</h4>
                    <div className="ucr__legend-panel-sub-section">
                        <IconWarning className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Job has warnings</p>
                    </div>
                    {isFeatureEnabled('s1Enabled') && (
                        <div className="ucr__legend-panel-sub-section">
                            <IconS1Referral className="ucr__calendar-icon ucr__calendar-icon--alert" />
                            <p className="ucr__legend-panel-label">Job is missing a S1 referral</p>
                        </div>
                    )}
                    <div className="ucr__legend-panel-sub-section">
                        <EarliestArrival className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Earliest time of arrival breached</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <LatestArrival className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Latest time of arrival breached</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconNonEnglishSpeaker className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">
                            Assignees do not speak the patient's language
                        </p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <People className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Job is missing assignees</p>
                    </div>

                    <h4 className="ucr__legend-panel-titles">Visit colours</h4>
                    {visitLegend.map((option: any) => (
                        <DynamicLegendOption option={option} key={option.color} />
                    ))}
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-any ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Unresolved patient alert</p>
                    </div>

                    <h4 className="ucr__legend-panel-titles">Admin colours</h4>
                    {adminLegend.map((option: any) => (
                        <DynamicLegendOption option={option} key={option.color} />
                    ))}

                    <h4 className="ucr__legend-panel-titles">Visit states</h4>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-notStarted ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Visit not yet started</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-current ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">En-route to patient</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-arrived ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Care started</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-completed ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Visit completed</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-aborted ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Visit cancelled</p>
                    </div>

                    <h4 className="ucr__legend-panel-titles">Additional information</h4>
                    <div className="ucr__legend-panel-sub-section">
                        <IconWarning className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Job has warnings</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <AddressWarningIcon className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">
                            Address details could not be verified
                        </p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <EarliestArrival className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Earliest time of arrival set</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <LatestArrival className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Latest time of arrival set</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <People className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Double-up job</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconCarRequired className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Job requires a car</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconNote className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Pre-job notes set</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconNonEnglishSpeaker className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Patient is a non-English speaker</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconComplexCare className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Complex case</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconCallback className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Callback requested</p>
                    </div>
                </div>
            </div>
        </Panel>
    );
};

export default Legend;
