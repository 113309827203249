import { Checkbox } from '@blueprintjs/core';
import { FormEventHandler } from 'react';
import { HeaderColumnType } from './TableHeader';

interface Props {
    columns: HeaderColumnType[];
    item: any;
    onClick: Function;
    onToggle?: FormEventHandler<HTMLInputElement>;
    selectable?: boolean;
    selected?: boolean;
    selectionDisabled?: boolean;
    alignTop?: boolean;
}

const TableRow = ({
    columns,
    onClick,
    item,
    onToggle = () => {},
    selected = false,
    selectable = false,
    selectionDisabled = false,
    alignTop = false,
}: Props) => {
    if (!item) return null;

    return (
        <tr
            key={item.id}
            onClick={(event) => onClick(item['id'], event)}
            className={`common-table__row ${
                selected ? 'common-table__row--selected' : ''
            } patient-data-table__row`}
        >
            {selectable && (
                <td
                    className={`patient-data-table__cell ${
                        alignTop ? 'patient-data-table__cell--alignTop' : ''
                    }`}
                >
                    <Checkbox
                        id={item['id']}
                        checked={selected}
                        onChange={(evt: any) => {
                            onToggle(evt.target.id);
                        }}
                        disabled={selectionDisabled || item.isArchived}
                        onClick={(event) => event.stopPropagation()}
                    />
                </td>
            )}
            {columns.map((col: HeaderColumnType, index) =>
                col.render ? (
                    <td
                        key={`${item['id']}_${col.key}_${index}`}
                        className={`patient-data-table__cell ${
                            alignTop ? 'patient-data-table__cell--alignTop' : ''
                        }`}
                    >
                        {col.render(item)}
                    </td>
                ) : (
                    <td
                        key={`${item['id']}_${col.key}_${index}`}
                        className={`patient-data-table__cell ${
                            alignTop ? 'patient-data-table__cell--alignTop' : ''
                        }`}
                    >
                        {item[col.key]}
                    </td>
                ),
            )}
        </tr>
    );
};

export default TableRow;
