import moment, { Moment } from 'moment';

export const getMomentDuration = (duration?: string): Moment => {
    if (!duration) {
        return moment().startOf('day');
    }

    return moment(duration, 'HH:mm');
};

export const getDateDuration = (duration?: string): Date => {
    return getMomentDuration(duration).toDate();
};

export const getDurationInMinutes = (duration?: string): number => {
    const [hours, minutes] = (duration || '').split(':');
    return Number.parseInt(hours) * 60 + Number.parseInt(minutes);
};
