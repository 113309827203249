import React, { Component } from 'react';
import { Callout } from '@blueprintjs/core';

export default class Warning extends Component {
    render() {
        return this.props.warnings?.length ? (
            <Callout intent="warning" icon={'warning-sign'}>
                {this.props.warnings.map((warning, index) => (
                    <div key={`${index}-error`}>{warning}</div>
                ))}
            </Callout>
        ) : null;
    }
}
