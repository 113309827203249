import { useEffect, useState } from 'react';
import { ViewToShow } from '../../../../../constants/mainConst';
import useStores from '../../../../../hook/useStores';
import { IFilterOption, IHcpFilter, IJobFilter } from '../../../../../interfaces/ucr';
import RootStore from '../../../../../stores/RootStore';
import { ALL_OPTION } from '../../../../v2/form/MultiSelect/MultiSelect';
import { FilterName } from './useUcrFilterPanelViewModel';

export function useUcrFilterLogic() {
    const {
        RootStore: {
            ucrStore: {
                viewToShow,
                jobFilters: calendarJobFilters,
                setShowAbortedJobs: calendarSetShowAbortedJobs,
                showAbortedJobs: calendarShowAbortedJobs,
                setHcpFilters: calendarSetHcpFilters,
                setJobFilters: calendarSetJobFilters,
                clearAllFilters: calendarClearAllFilters,
                hcpFilters: calendarHcpFilters,
            },
            mapStore: {
                jobFilters: mapJobFilters,
                setShowAbortedJobs: mapSetShowAbortedJobs,
                showAbortedJobs: mapShowAbortedJobs,
                showUnassignedJobs: mapShowUnassignedJobs,
                setShowUnassignedJobs: mapSethowUnassignedJobs,
                setJobFilters: mapSetJobFilters,
                clearAllFilters: mapClearAllFilters,
                setHcpFilters: mapSetHcpFilters,
                hcpFilters: mapHcpFilters,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    // ------------------------------------
    // Conditionally get the filter logic from the relevant stores
    // ------------------------------------
    let jobFilters: IJobFilter;
    let setJobFilters: (
        name: string,
        value?: boolean | string | string[] | IFilterOption | IFilterOption[],
    ) => void;

    let hcpFilters: IHcpFilter;
    let setHcpFilters: (
        name: string,
        value?: string | string[] | IFilterOption | IFilterOption[],
    ) => void;

    let setShowAbortedJobs;
    let showAbortedJobs: boolean | undefined;

    let setShowUnassignedJobs;
    let showUnassignedJobs: boolean | undefined;

    let clearAllFilters: () => void;

    if (viewToShow === ViewToShow.MAP) {
        jobFilters = mapJobFilters;
        setJobFilters = mapSetJobFilters;
        hcpFilters = mapHcpFilters;
        showAbortedJobs = mapShowAbortedJobs;
        setHcpFilters = mapSetHcpFilters;
        setShowAbortedJobs = mapSetShowAbortedJobs;
        showUnassignedJobs = mapShowUnassignedJobs;
        setShowUnassignedJobs = mapSethowUnassignedJobs;
        clearAllFilters = mapClearAllFilters;
    } else {
        jobFilters = calendarJobFilters;
        setHcpFilters = calendarSetHcpFilters;
        hcpFilters = calendarHcpFilters;
        setJobFilters = calendarSetJobFilters;
        showAbortedJobs = calendarShowAbortedJobs;
        setShowAbortedJobs = calendarSetShowAbortedJobs;
        clearAllFilters = calendarClearAllFilters;
    }

    // ------------------------------------
    // COMMON LOGIC ACROSS FILTERABLE VIEWS
    // ------------------------------------
    const addJobFilterOption = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (option.value !== '') {
            if (!forceSingleOption) {
                const filters = jobFilters[name].find(
                    (opt: IFilterOption) => opt.value === option.value,
                )
                    ? jobFilters[name]
                    : [...jobFilters[name], option];
                setJobFilters(name, filters);
            } else {
                setJobFilters(name, [option]);
            }
        } else {
            setJobFilters(name, []);
        }
    };

    const removeJobFilterOption = (name: string, option: IFilterOption): void => {
        setJobFilters(
            name,
            jobFilters[name].filter((opt: IFilterOption) => opt.value !== option.value),
        );
    };

    const onJobFilterChange = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (jobFilters[name].some((opt: IFilterOption) => opt.value === option.value)) {
            removeJobFilterOption(name, option);
        } else {
            addJobFilterOption(name, option, forceSingleOption);
        }
    };

    const removeHcpFilterOption = (name: string, option: IFilterOption): void => {
        setHcpFilters(
            name,
            hcpFilters[name].filter((opt: IFilterOption) => opt.value !== option.value),
        );
    };

    const addHcpFilterOption = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (option.value !== '') {
            if (!forceSingleOption) {
                const filters = hcpFilters[name].find(
                    (opt: IFilterOption) => opt.value === option.value,
                )
                    ? hcpFilters[name]
                    : [...hcpFilters[name], option];

                setHcpFilters(name, filters);
            } else {
                setHcpFilters(name, [option]);
            }
        } else {
            setHcpFilters(name, []);
        }
    };

    const onHcpFilterChange = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (hcpFilters[name].some((opt: IFilterOption) => opt.value === option.value)) {
            removeHcpFilterOption(name, option);
        } else {
            addHcpFilterOption(name, option, forceSingleOption);
        }
    };

    const onClearAllFilters = (): void => {
        clearAllFilters();
    };

    // ------------------------------------
    // HANDLING OF FILTERS WITH SPECIAL LOGIC
    // ------------------------------------
    const [jobTransportOption, setJobTransportOption] = useState<IFilterOption[]>([]);

    const onJobTransportChange = (option: IFilterOption, remove?: boolean): void => {
        if (
            remove ||
            option.label === ALL_OPTION.label ||
            jobTransportOption[0]?.label === option.label
        ) {
            setJobFilters(FilterName.CAR_REQUIRED, undefined);
            setJobTransportOption([]);
        } else {
            setJobFilters(FilterName.CAR_REQUIRED, option.value === 'carRequired');
            setJobTransportOption([option]);
        }
    };

    useEffect(() => {
        if (typeof jobFilters.carRequired === 'undefined') {
            setJobTransportOption([]);
        }
    }, [jobFilters.carRequired]);

    return {
        jobFilters,
        hcpFilters,
        showAbortedJobs,
        setShowAbortedJobs,
        showUnassignedJobs,
        setShowUnassignedJobs,
        jobTransportOption,
        onJobTransportChange,
        onJobFilterChange,
        onHcpFilterChange,
        onClearAllFilters,
    };
}
