import React, { FC } from 'react';
import { Callout } from '@blueprintjs/core';

import { VaccinationImportPatientWarning } from '../../../../interfaces';

interface Props {
    validationWarnings: VaccinationImportPatientWarning[];
}

export const ImportWarningsTable: FC<Props> = ({ validationWarnings }) => {
    return (
        <>
            <Callout intent="warning" className="file-upload__error">
                The uploaded file contains validation warnings, please see below for details.
            </Callout>
            <table className="bp5-html-table bp5-html-table-striped">
                <thead>
                    <tr data-testid="validation-warnings-table-header">
                        <th>Line number</th>
                        <th>NHS number</th>
                        <th>Vaccination type</th>
                        <th>Warnings</th>
                    </tr>
                </thead>
                <tbody data-testid="validation-warnings-table-body">
                    {validationWarnings.map(
                        ({ line, nhsNumber, vaccinationCategory, warnings, lineInFile }, index) => (
                            <tr key={line} data-testid={`${nhsNumber}-${index}`}>
                                <td>{lineInFile}</td>
                                <td>{nhsNumber}</td>
                                <td>{vaccinationCategory}</td>
                                <td data-testid={`validation-warnings-table-warning-cell-${index}`}>
                                    {warnings.map((warning) => (
                                        <li key={warning}>{warning}</li>
                                    ))}
                                </td>
                            </tr>
                        ),
                    )}
                </tbody>
            </table>
        </>
    );
};
