import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import useStores from '../../../../../hook/useStores';
import { SubDialogs } from '../../../../../stores/UCRStore';
import SystmOneCommunityParser from '../../../../modules/helpers/SystmOneCommunityParser';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Button, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { TextArea } from '../../../../v2/form';
import { Dialog, DialogSizes, Overlay } from '../../../../v2/overlays';
import { formatNhsNumber } from '../../../../../helpers/ucr';
import { useGetPDSSpineData } from '../../../../../api/pdsQueries';
import { Patient } from '@doc-abode/data-models';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    isAdminTime?: boolean;
}

const PullFromReferralForm: FC<IProps> = ({ isAdminTime = false }) => {
    const {
        RootStore: {
            ucrStore: { openedSubDialog, setOpenedSubDialog },
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    const pdsLookupEnabled: boolean = isFeatureEnabled('pdsLookup');
    const [pulledNhsNumber, setPulledNhsNumber] = useState('');

    const { isSuccess, data: pdsSpineData } = useGetPDSSpineData(pulledNhsNumber, pdsLookupEnabled);

    const { values, setTouched, setValues }: FormikContextType<FormikValues> = useFormikContext();

    const [patientSummary, setPatientSummary] = useState('');
    const [referralDetails, setReferralDetails] = useState('');
    const onCloseSubDialog = () => {
        setOpenedSubDialog(SubDialogs.NONE);
        setReferralDetails('');
        setPatientSummary('');
    };
    const onPullFromReferral = async ({
        patientSummary,
        referralDetails,
    }: {
        patientSummary: string;
        referralDetails: string;
    }) => {
        try {
            const parsedValues = SystmOneCommunityParser(patientSummary, referralDetails);
            const parsedFields = Object.keys(parsedValues);
            const pulledFields = [
                'nhsNumber',
                'firstName',
                'middleName',
                'lastName',
                'contactNumber',
                'additionalContactNumbers',
                'dateOfBirth',
                'gender',
                'addressLine1',
                'addressLine2',
                'addressLine3',
                'town',
                'postCode',
                'languagesSpoken',
                'staffPreferredGender',
                'systmOneRef',
                'referrer',
                'referralDateTime',
                'referralPathway',
                'disposition',
                'notes',
            ];
            const timeFields = ['dateOfBirth'];
            const arrayFields = ['languagesSpoken', 'staffPreferredGender'];
            const referralFields = ['disposition', 'referralDateTime', 'referralPathway'];
            const pulledValues: Partial<Patient> = pulledFields.reduce((acc, field) => {
                if (parsedFields.includes(field)) {
                    return { ...acc, [field]: parsedValues[field] };
                }
                if (timeFields.includes(field)) {
                    return { ...acc, [field]: field === 'dateOfBirth' ? new Date() : null };
                }
                if (arrayFields.includes(field)) {
                    return { ...acc, [field]: [] };
                }
                if (referralFields.includes(field)) {
                    return { ...acc, [field]: values[field] };
                }
                return { ...acc, [field]: '' };
            }, {});

            let spineVersion;
            if (pulledValues.nhsNumber && isFeatureEnabled('pdsLookup')) {
                const nhsNumber = formatNhsNumber(pulledValues.nhsNumber);
                setPulledNhsNumber(nhsNumber);

                if (isSuccess && pdsSpineData) {
                    spineVersion = pdsSpineData.versionId;
                    pulledValues.pds = {
                        versionId: spineVersion,
                    };
                }
            }

            if (parsedFields.length > 0) {
                setValues({ ...values, ...pulledValues, fromReferral: true });
                setTouched(
                    parsedFields.reduce((fields, field) => ({ ...fields, [field]: true }), {}),
                );

                onCloseSubDialog();
                AppToaster.show({
                    message: `Successfully parsed ${parsedFields.length} fields!`,
                    intent: 'success',
                });
            } else {
                AppToaster.show({
                    message: 'No fields were parsed from the supplied data',
                    intent: 'warning',
                });
            }
        } catch (err: any) {
            console.error('Error parsing referral data', err.message);
            AppToaster.show({
                message: 'Unable to parse patient referral',
                intent: 'danger',
            });
        }
    };
    return (
        <div className="pull-from-referral-wrap">
            <Overlay isShow={openedSubDialog !== SubDialogs.NONE} />
            <Dialog
                title="Pull from referral"
                size={DialogSizes.SMALL}
                isShow={openedSubDialog === SubDialogs.PULL_FROM_REFERRAL}
                closeEvent={onCloseSubDialog}
                renderChildrenOnlyWhenVisible
            >
                <div className="v2__form-block">
                    <TextArea
                        label="Patient summary"
                        name="patientSummary"
                        className="v2__form-group--pos-1-1"
                        value={patientSummary}
                        onChange={(e) => setPatientSummary(e.currentTarget.value)}
                    />
                    {!isAdminTime && (
                        <TextArea
                            label="Referral details"
                            name="referralDetails"
                            className="v2__form-group--pos-1-1"
                            value={referralDetails}
                            onChange={(e) => setReferralDetails(e.currentTarget.value)}
                        />
                    )}
                    <label className="v2__form-group">
                        <Button
                            name="Import"
                            elem={ButtonElems.BUTTON}
                            size={ButtonSizes.MEDIUM}
                            className="v2__form-submit-button"
                            type="button"
                            clickEvent={() =>
                                onPullFromReferral({ patientSummary, referralDetails })
                            }
                        />
                    </label>
                </div>
            </Dialog>
        </div>
    );
};

export default observer(PullFromReferralForm);
