import { S1Referral } from '@doc-abode/data-models';
import { FC } from 'react';
import { Select } from '../../../../v2/form';
import { formatS1ReferralName } from '../../../../../helpers/ucr/formatS1ReferralName';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';

type S1ReferralSelectProps = {
    loading: boolean;
    referrals: S1Referral[];
    onSyncS1Referrals: () => void;
    referralSelectHelperText: string;
};

export const S1ReferralSelect: FC<S1ReferralSelectProps> = ({
    loading,
    referrals,
    onSyncS1Referrals,
    referralSelectHelperText,
}) => {
    return (
        <>
            <Select
                name="systmOneRef"
                className="v2__form-group--pos-1-2"
                label="SystmOne referral"
                disabled={loading}
                helperText={referralSelectHelperText}
            >
                <option value="">Not set</option>
                {referrals.map((referral) => (
                    <option value={referral.ReferralUID} key={referral.ReferralUID}>
                        {formatS1ReferralName(referral)}
                    </option>
                ))}
            </Select>
            <div className="v2__form-group--pos-2-2" style={{ display: 'flex', marginTop: '13px' }}>
                <label className="v2__form-group">
                    <Button
                        className="v2__form-submit-button"
                        name="Sync referrals with SystmOne"
                        size={ButtonSizes.MEDIUM}
                        color={ButtonColors.GREY}
                        clickEvent={onSyncS1Referrals}
                    />
                </label>
            </div>
        </>
    );
};
