import { Pathway, Service } from '@doc-abode/data-models';
import { IFilterOption } from '../../../../../interfaces/ucr';

export const jobStatusOptions: IFilterOption[] = [
    {
        label: 'Not started',
        value: 'notStarted',
    },
    {
        label: 'Current',
        value: 'current',
    },
    {
        label: 'Arrived',
        value: 'arrived',
    },
    {
        label: 'Completed',
        value: 'completed',
    },
];

export const careComplexityOptions: IFilterOption[] = [
    {
        label: 'Complex',
        value: 'complex',
    },
    {
        label: 'Non-complex',
        value: 'nonComplex',
    },
    {
        label: 'Not set',
        value: 'unset',
    },
];

export const serviceOptions = (pathways: Pathway[]) => {
    return pathways
        .map(({ services }) => services)
        .flat()
        .filter(({ enabled }) => enabled)
        .reduce((acc, service) => {
            if (!acc.find(({ value }) => value === service.value)) {
                acc.push(service);
            }
            return acc;
        }, [] as Service[]);
};

export const carRequiredOptions: IFilterOption[] = [
    {
        label: 'Car required',
        value: 'carRequired',
    },
    {
        label: 'No car required',
        value: 'noCarRequired',
    },
];

export const warningOptions: IFilterOption[] = [
    {
        label: 'With any warning',
        value: 'withAnyWarnings',
    },
    {
        label: 'With patient alerts',
        value: 'withPatientAlerts',
    },
    {
        label: 'With scheduling conflicts',
        value: 'withConflicts',
    },
    {
        label: 'Which are outside of HCPs available hours',
        value: 'outsideAvailable',
    },
    {
        label: 'With breaches of the latest arrival time',
        value: 'latestArrival',
    },
    {
        label: 'With breaches of the earliest arrival time',
        value: 'earliestArrival',
    },
    {
        label: 'Affected by delays',
        value: 'affectedByDelays',
    },
    {
        label: 'With double-up',
        value: 'withDoubleUp',
    },
];

export const availabilityOptions: IFilterOption[] = [
    {
        label: 'Available staff',
        value: 'availableStaff',
    },
    {
        label: 'Staff with visits',
        value: 'staffWithVisits',
    },
    {
        label: 'Staff without visits',
        value: 'staffWithoutVisits',
    },
];

export const referrerOptions = (referrers?: string[]) => {
    return referrers?.map((label: string) => ({
        label,
        value: label,
    }));
};

export const genderOptions = (genders?: IFilterOption[]) => {
    return genders?.filter((gender: IFilterOption) => gender.label !== 'Not Set');
};

export const pathwayOptions = (pathways: Pathway[]) => {
    return pathways.filter((pathway: Pathway) => pathway.enabled);
};
