import * as Yup from 'yup';
import moment from 'moment';

export default function markAsCompletedValidationSchema() {
    const schema: { [key: string]: any } = {
        assignedHcp: Yup.string().required('assignedHcp This field is required.'),
        markedCurrentDateTime: Yup.date().typeError('madeCurrentDateTime This field is required.'),
        markedArrivedDateTime: Yup.date()
            .typeError('arrivedDateTime This field is required.')
            .when('markedCurrentDateTime', (markedCurrentDateTime, schema) => {
                return markedCurrentDateTime
                    ? schema.test(
                          'markedCurrentDateTime',
                          'arrivedDateTime The arrival time for the visit cannot be earlier than the time the visit was marked as en-route',
                          (value: Date) => {
                              if (!value) {
                                  return true;
                              }
                              return moment(markedCurrentDateTime)
                                  .startOf('minute')
                                  .isSameOrBefore(moment(value).startOf('minute'));
                          },
                      )
                    : schema;
            }),
        markedCompletedFinishedDateTime: Yup.date()
            .typeError('finishedDateTime This field is required.')
            .when('markedArrivedDateTime', (markedArrivedDateTime, schema) => {
                return markedArrivedDateTime
                    ? schema.test(
                          'markedArrivedDateTime',
                          'finishedDateTime The time of completion for the visit cannot be earlier than the time of arrival',
                          (value: Date) => {
                              if (!value) {
                                  return true;
                              }
                              return moment(markedArrivedDateTime)
                                  .startOf('minute')
                                  .isSameOrBefore(moment(value).startOf('minute'));
                          },
                      )
                    : schema;
            }),
        markedCompletedNotes: Yup.string().required('markedCompletedNotes This field is required.'),
        vaccineBatchNumberGiven: Yup.string().required(
            'vaccineBatchNumber This field is required.',
        ),
        vaccineExpiryDateTime: Yup.date().typeError('vaccineExpiryDate This field is required.'),
        siteOfAdministration: Yup.string().required(
            'vaccineSiteOfAdministration This field is required.',
        ),
    };

    return Yup.object(schema);
}
