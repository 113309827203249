import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, RangeSlider } from '@blueprintjs/core';
import { Vaccination } from '@doc-abode/data-models';
import { isEqual } from 'lodash';
import { MultiSelectComponent } from '../../../v2/form/MultiSelect';

const Filter = (props) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(
        props.value
            ? Array.isArray(props.value)
                ? props.value
                : [props.value]
            : props.range
              ? props.range
              : [],
    );
    const [practiceOptions, setPracticeOptions] = useState(props.multiSelectOptions);

    const inputRef = useRef();

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const {
        name,
        label,
        options,
        range,
        multiSelectOptions,
        labelTransform,
        labelRenderer,
        valueTransform,
        onChange,
    } = props;

    useEffect(() => {
        // If there are any vaccination categories selected in the filters, then we need to filter
        // out any manufacturers that do not relate to the selected categories.
        if (name === 'vaccineManufacturer' && props.filters.vaccinationCategory?.length > 0) {
            let nextSelection = [...value];
            const manufacturers = props.filters.vaccinationCategory.reduce(
                (acc, category) => [...acc, ...Object.keys(Vaccination.manufacturerList(category))],
                [],
            );
            nextSelection = nextSelection.filter((manufacturer) =>
                manufacturers.includes(manufacturer),
            );
            if (!isEqual(value, nextSelection)) {
                setValue(nextSelection);
            }
        }
    }, [props.filters.vaccinationCategory, name, value]);

    useEffect(() => {
        setPracticeOptions(multiSelectOptions);
    }, [multiSelectOptions]);

    const getLabeledValue = (value) => {
        if (labelTransform) {
            return value.map((label) => labelTransform(label));
        }

        return multiSelectOptions ? value.map((option) => option.label) : value;
    };

    const formatValue = (value) => {
        if (range && range[0] === value[0] && range[1] === value[1]) {
            return '(any)';
        }
        const values = getLabeledValue(value);
        return values.join(range ? '-' : ', ');
    };

    const onUpdateSelection = (toggledOption, checked) => {
        let nextSelection = [...value];
        checked
            ? nextSelection.push(toggledOption)
            : (nextSelection = value.filter((option) => option !== toggledOption));
        setValue(nextSelection);
    };

    const getNextValue = () => {
        if (multiSelectOptions) {
            return value.map((item) => item.value);
        }

        return options || range ? value : value[0];
    };

    const onSubmitSelection = () => {
        setEditing(false);
        const nextValue = getNextValue();
        onChange({
            name,
            value: valueTransform ? valueTransform(nextValue) : nextValue,
        });
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmitSelection();
        }
    };

    const onAddPracticeValue = (option) => {
        if (option.value !== '') {
            setValue([...value, option]);
        } else {
            setValue([]);
        }
    };
    const onRemovePracticeValue = (option) => {
        setValue(value.filter((opt) => opt.value !== option.value));
    };

    const onSelectPracticeOption = (option) => {
        if (value.some((opt) => opt.value === option.value)) {
            onRemovePracticeValue(option);
        } else {
            onAddPracticeValue(option);
        }
    };

    const onFilterOptions = (query, items) => {
        return items.filter((option) => option.value.toLowerCase().includes(query.toLowerCase()));
    };

    const getFormattedValue = () => {
        if (value[0] !== undefined) {
            return formatValue(value);
        }

        return options || multiSelectOptions ? '(any)' : '(none)';
    };

    const muteText = !editing && !value[0];

    return (
        <Fragment key={name}>
            <span className={`filter__label ${muteText ? 'filter__label--any' : ''}`}>{label}</span>
            {editing ? (
                <>
                    <Button
                        className={`filter__button ${
                            multiSelectOptions ? 'filter__button--multi' : ''
                        }`}
                        icon="tick"
                        intent="success"
                        minimal
                        onClick={onSubmitSelection}
                    />
                    {options ? (
                        <span className="filter__value">
                            {options.map((option) => (
                                <Checkbox
                                    label={labelTransform ? labelTransform(option) : option}
                                    checked={value?.includes(option) || false}
                                    onChange={(event) =>
                                        onUpdateSelection(option, event.target.checked)
                                    }
                                    key={option}
                                />
                            ))}
                        </span>
                    ) : range ? (
                        <span className="filter__value">
                            <RangeSlider
                                min={range[0]}
                                max={range[1]}
                                stepSize={1}
                                value={value}
                                onChange={setValue}
                                labelStepSize={range[1]}
                                labelRenderer={labelRenderer}
                            />
                        </span>
                    ) : multiSelectOptions ? (
                        <span className="filter__value">
                            <MultiSelectComponent
                                className="v2__filter-form-group"
                                name="practice"
                                optionsSelected={value}
                                placeholder="Enter value..."
                                options={practiceOptions}
                                optionClassName="v2__form-multi-select-option"
                                onItemSelect={(option) => onSelectPracticeOption(option)}
                                onRemove={(option) => onSelectPracticeOption(option)}
                                itemListPredicate={onFilterOptions}
                                openOnKeyDown={true}
                                resetOnSelect={true}
                                scrollToActiveItem={false}
                                popoverClassName="v2__form-multi-select-popover"
                            />
                        </span>
                    ) : (
                        <input
                            className="bp5-input filter__input"
                            type="text"
                            value={value[0] || ''}
                            onChange={(event) => setValue([event.target.value])}
                            placeholder="Enter value..."
                            onKeyDown={onKeyDown}
                            ref={inputRef}
                        />
                    )}
                </>
            ) : (
                <>
                    <Button
                        className="filter__button"
                        icon="edit"
                        intent="primary"
                        minimal
                        onClick={() => setEditing(true)}
                    />
                    <span className={`filter__value ${muteText ? 'filter__value--any' : ''}`}>
                        {getFormattedValue()}
                    </span>
                </>
            )}
        </Fragment>
    );
};

const Filters = ({ filters, filterOptions, setFilters }) => {
    const onChange = ({ name, value }) => {
        const nextFilters = { ...filters };

        if (!value || value.length === 0) {
            delete nextFilters[name];
        } else {
            nextFilters[name] = value;
        }

        setFilters(nextFilters);
    };

    return (
        <div className="filters">
            {filterOptions.map((filter) => (
                <Filter
                    {...filter}
                    value={filters[filter.name]}
                    filters={filters}
                    onChange={onChange}
                    key={filter.name}
                />
            ))}
        </div>
    );
};

export default Filters;
