import { memo } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { StaffAlert } from '@doc-abode/data-models';
import { Callout } from '../../../../../v2/components';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import { IconWarning } from '../../../../../../helpers/ucr/icons';
import { GOOGLE_API_LIBRARIES } from '../../../../../../constants/googleAPIs';

interface StaffAlertProps {
    alert?: StaffAlert;
    apiKey: string;
}

export const StaffAlertMap = memo<StaffAlertProps>(({ alert, apiKey }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: GOOGLE_API_LIBRARIES,
    });

    if (
        !(
            alert?.lastKnownLocation &&
            alert.lastKnownLocation.latitude &&
            alert.lastKnownLocation.longitude &&
            alert.lastKnownLocation.dateTime
        )
    ) {
        return <div className="v2__form-section">Last known location is unavailable</div>;
    }

    if (!isLoaded) {
        return null;
    }

    const position = {
        lat: alert.lastKnownLocation.latitude,
        lng: alert.lastKnownLocation.longitude,
    };

    return (
        <>
            <Callout intent="warning" Icon={IconWarning} spacerBottom spacerTop>
                Last known location recorded by the Doc Abode app displayed below as of{' '}
                <strong>{formatDisplayDateTime(alert.lastKnownLocation.dateTime)}</strong>.{' '}
                <strong>Note:</strong> this location could be out of date.
            </Callout>
            <GoogleMap
                mapContainerClassName="ucr-staff-alert-dialog__map"
                center={position}
                zoom={17}
            >
                <Marker title="Last known postion" position={position} />
            </GoogleMap>
        </>
    );
});
