import { FC } from 'react';
import cn from 'classnames';
import { FormGroup, FormGroupProps } from '@blueprintjs/core';

interface IProps extends FormGroupProps {
    required?: boolean;
    touched?: boolean;
    error?: string;
    displayErrors?: string | undefined;
}

const FormGroupComponent: FC<IProps> = ({
    className,
    required,
    touched,
    error,
    children,
    displayErrors,
    ...props
}) => {
    const isError = ((touched || touched === undefined) && error) || displayErrors;

    return (
        <FormGroup
            className={cn('v2__form-group', className, {
                'v2__form-group--error': isError,
            })}
            labelInfo={required ? '*' : null}
            helperText={isError ? error || displayErrors : null}
            {...props}
        >
            {children}
        </FormGroup>
    );
};

export default FormGroupComponent;
