import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Button, ButtonSizes, ButtonElems } from '../Button';

export type calloutIntent = 'success' | 'warning' | 'danger' | 'primary';

type CalloutAction = {
    onClick: () => void;
    text: string;
};

interface IProps {
    children: ReactNode;
    intent?: calloutIntent;
    Icon?: FC;
    spacerTop?: boolean;
    spacerBottom?: boolean;
    compact?: boolean;
    rounded?: boolean;
    action?: CalloutAction;
    testId?: string;
}

const Callout: FC<IProps> = ({
    children,
    intent,
    Icon,
    action,
    spacerTop = false,
    spacerBottom = false,
    compact = false,
    rounded = true,
    testId,
}) => {
    return (
        <aside
            className={cn('v2-callout', {
                [`v2-callout--${intent}`]: intent,
                'v2-callout--spacer-top': spacerTop,
                'v2-callout--spacer-bottom': spacerBottom,
                'v2-callout--compact': compact,
                'v2-callout--rounded': rounded,
            })}
            data-testid={testId}
        >
            {Icon && (
                <span
                    className={cn('v2-callout__icon', {
                        'v2-callout__icon--danger': intent === 'danger',
                    })}
                >
                    <Icon />
                </span>
            )}
            <span
                className={cn('v2-callout__text', {
                    'v2-callout__text--danger': intent === 'danger',
                })}
            >
                {children}
            </span>
            {action && (
                <span className="v2-callout__action">
                    <Button
                        clickEvent={action.onClick}
                        name={action.text}
                        size={ButtonSizes.SMALL}
                        elem={ButtonElems.BUTTON}
                    />
                </span>
            )}
        </aside>
    );
};

export default Callout;
