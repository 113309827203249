import { FC } from 'react';
import { observer } from 'mobx-react';
import { AlertStatus } from '@doc-abode/data-models';

import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Dialogs } from '../../../../../stores/UCRStore';
import { Dialog } from '../../../../v2/overlays';
import { SummaryJobList } from '../../components';

import { getHcpName } from '../../../../../helpers/ucr';
import { Button, ButtonColors, Info, InfoItem } from '../../../../v2/components';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';
import { getServiceDisplayName } from '../common';
import RootStore from '../../../../../stores/RootStore';

const acknowledgeMessage = [
    'Acknowledging the alert will not provide feedback to the HCP.',
    'The alert will remain unresolved and you will be able to access it via the "Urgent Warnings" or the patient visit to resolve at a later time.',
];

const PatientAlertInfo: FC = () => {
    let {
        RootStore: {
            ucrStore: {
                jobs,
                hcps,
                openedDialog,
                openedPatientAlertToResolve: alert,
                setAlertAcknowledged,
                setOpenedPatientAlertResolver,
                clearOpenedDialog,
                openAlert,
                closeAlert,
            },
            configStore: { pathways },
        },
    } = useStores<{ RootStore: RootStore }>();

    const alertedJob = jobs.find((job: any) => alert?.jobId === job.id);

    const filteredJobs = jobs
        .filter((job: any) => alertedJob?.hcpId === job.hcpId)
        .sort((a: any, b: any) => {
            if (a.startDateTime && b.startDateTime) {
                return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
            }

            return a.startDateTime ? 1 : -1;
        });

    const openResolutionForm = () => {
        setOpenedPatientAlertResolver(alert!);
    };

    const close = () => {
        clearOpenedDialog();
    };

    const acknowledgeConfirm = () => {
        setAlertAcknowledged(alert?.alertId!);
        closeAlert();
        clearOpenedDialog();
    };

    const acknowledge = () => {
        openAlert({
            heading: 'Acknowledge Patient Alert',
            message: acknowledgeMessage,
            onConfirm: acknowledgeConfirm,
        });
    };

    const showDialog = openedDialog === Dialogs.PATIENT_ALERT_INFO;

    // If we can't find a job from the alert we're in a weird spot
    if (showDialog && !(alert || alertedJob)) {
        AppToaster.show({
            message:
                'Sorry, an error occurred and we were unable to show the patient alert information',
            intent: 'danger',
        });
        clearOpenedDialog();
    }

    if (!alert || !alertedJob) {
        return null;
    }
    const alertInfoTitle =
        (alert.acknowledged && !alert.resolvedBy && 'Acknowledged') ||
        (alert.resolvedBy && alert.acknowledged && 'Resolved') ||
        (alert.resolvedBy && !alert.acknowledged && 'Resolved') ||
        (!alert.resolvedBy && 'Unresolved');

    const pathway = pathways.find((pathway: any) => pathway.value === alertedJob?.referralPathway);

    return (
        <Dialog isShow={showDialog} closeEvent={close} title={`${alertInfoTitle} patient alert`}>
            <div className="v2__form-section">
                <Info title="Job Details">
                    <InfoItem label="Raised:">
                        {alert?.createdAt ? formatDisplayDateTime(alert.createdAt) : ''}
                    </InfoItem>
                    <InfoItem label="Sender:">{getHcpName(hcps, alert?.createdBy)}</InfoItem>
                    <InfoItem label="Patient:">
                        {alertedJob?.firstName} {alertedJob?.middleName} {alertedJob?.lastName}
                    </InfoItem>
                    <InfoItem label="Planned Start Time:">
                        {alertedJob?.startDateTime &&
                            formatDisplayDateTime(alertedJob?.startDateTime)}
                        <br />
                    </InfoItem>
                    <InfoItem label="Pathway:">
                        {pathway?.label || alertedJob?.referralPathway || 'Unknown Pathway'}
                    </InfoItem>
                    <InfoItem label="Service:">
                        {getServiceDisplayName(pathway?.services, alertedJob?.disposition) ||
                            'Unkown Service'}
                    </InfoItem>
                </Info>
            </div>

            <div className="v2__form-section">
                <Info title="Alert Details">
                    <InfoItem label="Reason:">{alert?.type}</InfoItem>

                    {alert?.resolution && (
                        <>
                            <InfoItem label="Resolution:">{alert?.resolution}</InfoItem>
                            <InfoItem label="Resolution Notes:">{alert?.resolutionNotes}</InfoItem>
                        </>
                    )}
                </Info>

                <div>
                    {alert?.status === AlertStatus.OPEN && (
                        <Button
                            className="ucr__controls-button"
                            name="Resolve Alert"
                            clickEvent={openResolutionForm}
                        />
                    )}
                    {alert?.status === AlertStatus.OPEN && !alert.acknowledged && (
                        <Button
                            className="ucr__controls-button"
                            name="Acknowledge Alert"
                            clickEvent={acknowledge}
                        />
                    )}
                    <Button
                        className="ucr__controls-button"
                        name="Close"
                        color={ButtonColors.GREY}
                        clickEvent={close}
                    />
                </div>
            </div>

            <SummaryJobList filteredJobs={filteredJobs} pathways={pathways} />
        </Dialog>
    );
};

export default observer(PatientAlertInfo);
