import { FC } from 'react';
import { Form } from 'formik';
import cn from 'classnames';

interface IProps {
    className?: string;
    children: any;
}

const FormComponent: FC<IProps> = ({ className, children, ...props }) => {
    return (
        <Form className={cn('v2__form-group', className)} {...props}>
            {children}
        </Form>
    );
};

export default FormComponent;
