import { FC } from 'react';
import cn from 'classnames';

import { InfoItem } from '.';
import { InfoIconItem } from '.';

export interface IDetailsRow<Data extends {}, K extends keyof Data = any> {
    key?: K;
    heading?: string;
    Icon?: any;
    multipleKey?: K[];
    multipleIcon?: any[];
    urgent?: boolean;
    parser?: (value: Data[K]) => any;
}

interface IDetailsProps<Data extends {} = Record<string | number | symbol, any>> {
    rows: IDetailsRow<Data>[];
    data: Data;
    classes?: string;
    isAborted?: boolean;
}

/**
 * Renders a data driven details block
 */
export const DataDetails: FC<IDetailsProps> = ({ rows, data, classes, isAborted = false }) => {
    const details = rows
        .map(({ key, heading, Icon, urgent = false, parser, multipleIcon, multipleKey }, i) => {
            const value = isAborted && key !== 'activity' ? null : data[key];

            if (multipleKey?.length && multipleIcon?.length) {
                return (
                    <div className="v2-info-icon-block" key={`${key}`}>
                        {multipleKey.map((key, i) => (
                            <InfoIconItem
                                key={`${key}--${i}`}
                                label={heading}
                                Icon={multipleIcon[i]}
                                urgent={urgent}
                                isMultiple={true}
                            >
                                {' '}
                            </InfoIconItem>
                        ))}
                    </div>
                );
            }

            if (!value) {
                return null;
            }

            return Icon ? (
                <InfoIconItem key={`${key}--${i}`} label={heading} Icon={Icon} urgent={urgent}>
                    {parser ? parser(value) : value}
                </InfoIconItem>
            ) : (
                <InfoItem key={`${key}--${i}`} label={heading}>
                    {parser ? parser(value) : value}
                </InfoItem>
            );
        })
        .filter((row) => Boolean(row));

    if (!details.length) {
        return null;
    }

    return rows.some((v) => v.Icon) ? (
        <div className={cn('details', 'v2-info-icon--flex', classes)}>{details} </div>
    ) : (
        <dl className={cn('details', 'v2-info', classes)}>{details} </dl>
    );
};
