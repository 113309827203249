import { Alert, TextArea, FormGroup } from '@blueprintjs/core';

interface RouteDetailsAbortAlertProps {
    showAbortPrompt?: boolean;
    abortNotes?: string;
    onAbort?: () => Promise<void>;
    setAbortNotes?: React.Dispatch<React.SetStateAction<string>>;
    setShowAbortPrompt?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const RouteDetailsAbortAlert: React.FC<RouteDetailsAbortAlertProps> = ({
    showAbortPrompt,
    abortNotes,
    onAbort,
    setAbortNotes,
    setShowAbortPrompt,
}) => {
    return (
        <Alert
            isOpen={showAbortPrompt}
            onConfirm={onAbort}
            onCancel={() => {
                if (setShowAbortPrompt) {
                    setShowAbortPrompt(false);
                }
            }}
            cancelButtonText="Cancel"
            confirmButtonText="Confirm"
            icon="warning-sign"
            intent="danger"
        >
            <p>
                <strong>Are you sure you want to abort this route?</strong>
            </p>
            <FormGroup
                label="You can enter a reason for aborting or any other information here:"
                labelFor="abortNotes"
            >
                <TextArea
                    id="abortNotes"
                    onChange={(event) => {
                        if (setAbortNotes) {
                            setAbortNotes(event.target.value);
                        }
                    }}
                    fill
                    value={abortNotes}
                />
            </FormGroup>
        </Alert>
    );
};
