import { FC, useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useField } from 'formik';
import FormGroup from '../FormGroup';
import { InputGroup, InputGroupProps2 } from '@blueprintjs/core';

export interface Place {
    addressLine1?: string;
    city?: string;
    postcode?: string;
}

interface AutocompletePlacesInputProps extends InputGroupProps2 {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    displayErrors?: string;
    isContinueBtnClicked?: boolean;
    onPlaceSelected: (place: Place) => void;
    apiKey: string;
}

const AutocompletePlacesInput: FC<AutocompletePlacesInputProps> = ({
    className,
    label,
    required,
    isContinueBtnClicked,
    displayErrors,
    onPlaceSelected,
    apiKey,
    ...props
}) => {
    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey,
        onPlaceSelected: (place) => {
            let addressLine1: string | undefined;
            let city: string | undefined;
            let postcode: string | undefined;

            for (const addressComponent of place.address_components || []) {
                if (addressComponent.types.includes('street_number')) {
                    addressLine1 = addressComponent.long_name;
                } else if (addressComponent.types.includes('route')) {
                    addressLine1 = addressLine1
                        ? `${addressLine1} ${addressComponent.long_name}`
                        : addressComponent.long_name;
                } else if (addressComponent.types.includes('postal_town')) {
                    city = addressComponent.long_name;
                } else if (addressComponent.types.includes('postal_code')) {
                    postcode = addressComponent.long_name;
                }
            }

            onPlaceSelected({ addressLine1, city, postcode });
        },
        options: {
            types: [],
            componentRestrictions: { country: 'uk' },
        },
    });

    const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement> | null>(ref);

    useEffect(() => {
        if (ref && ref.current instanceof HTMLInputElement) {
            setInputRef(ref);
        } else {
            setInputRef(null);
        }
    }, [ref]);

    const [field, meta] = useField({ ...props });

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <InputGroup
                inputRef={inputRef}
                {...field}
                {...props}
                id={props.name}
                className="v2__form-text-input"
            />
        </FormGroup>
    );
};

export default AutocompletePlacesInput;
