import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { isLocalhost } from '../helpers/isLocalHost';
import pkg from '../../package.json';
import { getBearerToken } from './amplifyApi';

const TWO_SECONDS = 2000;
const { REACT_APP_ENVIRONMENT: env, NODE_ENV } = process.env;

const generateHost = () => {
    if (NODE_ENV === 'development' || isLocalhost()) {
        const subdomainPrefix = env === 'prod' ? '' : `${env}-`;
        return `https://${subdomainPrefix}controller.docabode.com`;
    }
    return window.location.origin;
};

const host = generateHost();
let baseUrl: string;
let rootUrl = `${host}/api`;
let graphqlUrl = `${host}/graphql/`;
let orgUrl = `${rootUrl}/org`;
let graphqlV2Url = process.env.REACT_APP_GRAPHQL_V2 || `${rootUrl}/v2/graphql/`;

const headers: { [key: string]: string } = {
    'x-docabode-application-name': 'controller-app',
    'x-docabode-application-version': pkg.version,
};

const setOrganisation = (org: string) => {
    baseUrl = `${rootUrl}/controller/org/${org}`;
    orgUrl = `${rootUrl}/org/${org}`;
    headers['x-docabode-organisation'] = org;
};

const api = axios.create({
    headers,
});

const request = async (config: AxiosRequestConfig, retry: boolean = false) => {
    axiosRetry(api);
    const bearerToken = await getBearerToken({ tokenType: 'id' });

    const response = await api({
        ...config,
        headers: {
            ...headers,
            ...config.headers,
            Authorization: bearerToken,
        },
        'axios-retry': {
            retries: retry ? 3 : 0,
            retryDelay: axiosRetry.exponentialDelay,
        },
    });

    return response.data;
};

const retryable = <T>(callback: () => Promise<Response>, retries = 0): Promise<T> => {
    let tries = 0;

    return new Promise((resolve, reject) => {
        const execute = async () => {
            try {
                tries += 1;
                const response = await callback();
                const data = await response.json();

                if (!response.ok) {
                    let errorMessage = 'No error message provided';
                    if (data.message || typeof data.error === 'string') {
                        errorMessage = data.message || data.error;
                    }

                    const error = new Error(errorMessage);
                    // @ts-ignore todo
                    error.response = response;
                    // @ts-ignore todo
                    error.data = data;
                    throw error;
                }

                resolve(data);
            } catch (err) {
                console.warn('Request failed:', err);

                if (tries <= retries) {
                    setTimeout(execute, TWO_SECONDS);
                } else {
                    console.log('Request reached retry limit');
                    reject(err);
                }
            }
        };

        execute();
    });
};

export {
    setOrganisation,
    baseUrl,
    rootUrl,
    retryable,
    graphqlUrl,
    headers,
    orgUrl,
    host,
    graphqlV2Url,
    request,
};
