import { Checkbox, Dialog, Intent, Tag } from '@blueprintjs/core';
import { JobStatus } from '@doc-abode/data-models/dist/job/Job';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Hub, Instruction, RoutePatientInput, Vaccination } from '@doc-abode/data-models';
import { formatNameFirstMiddleLast } from '@doc-abode/helpers';

import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatDisplayDate } from '../../../../../modules/helpers/formatData';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../../v2/components';
import { VaccinationRoute } from '../../../types';
import { formatPatientForRoute, routeStatusMappings, routeStatusTags } from '../../../utils';
import { Itinerary } from '../Itinerary/Itinerary';

interface IPickupDetailsDialogShowItinerary {
    vaccinationRoute: VaccinationRoute | null | undefined;
}
export function PickupDetailsDialogShowItinerary({
    vaccinationRoute,
}: IPickupDetailsDialogShowItinerary): JSX.Element {
    const instructions: Instruction[] = vaccinationRoute?.itinerary?.instructions || [];

    return (
        <Itinerary instructions={instructions} routeItineraryId={vaccinationRoute?.itineraryId} />
    );
}

interface Props {
    patientsToAdd: Vaccination[];
    vaccinationToRemove?: Vaccination;
    route: VaccinationRoute;
    isOpen: boolean;
    handleClose: (isOpen: boolean) => void;
    setIsOpen: (isOpen: boolean) => void;
    onRequestUpdatedRoute: (
        route: VaccinationRoute,
        body: any,
        vaccination: Vaccination | undefined | null,
    ) => void;
    recalculateRoute: boolean;
    selectedStartTime?: string;
    hub?: Hub;
}

interface ISelectAllUnselectAllProps {
    onSelectAll: () => void;
    onUnselectAll: () => void;
}

export function SelectAllUnselectAll({
    onSelectAll,
    onUnselectAll,
}: ISelectAllUnselectAllProps): JSX.Element {
    return (
        <div className="pickup-details-dialog__checkbox-mass-select">
            <div
                className="pickup-details-dialog__checkbox-mass-select-link-like"
                onClick={onSelectAll}
            >
                Select all
            </div>
            <div>{'\u00A0'}</div>
            <div className="pickup-details-dialog__checkbox-mass-select-link-like">|</div>
            <div>{'\u00A0'}</div>
            <div
                className="pickup-details-dialog__checkbox-mass-select-link-like"
                onClick={onUnselectAll}
            >
                Unselect all
            </div>
        </div>
    );
}

interface IPatientIdOdsCode {
    patientId: string;
    odsCode: string;
}
export const PickupRecalculationOptionsDialog: FC<Props> = ({
    patientsToAdd,
    vaccinationToRemove,
    route,
    isOpen = false,
    handleClose,
    setIsOpen,
    onRequestUpdatedRoute,
    recalculateRoute,
    selectedStartTime,
    hub,
}) => {
    const {
        RootStore: {
            configStore: { vaccinationDuration, vaccinationDetails },
        },
    } = useStores<{ RootStore: RootStore }>();

    const {
        state: { routeType },
    } = useLocation() as {
        state: {
            routeType: string;
        };
    };

    const [visitedPickups, setVisitedPickups] = useState<string[]>([]);
    const [newPatientsForVisitedPickups, setNewPatientsForVisitedPickups] = useState(
        {} as {
            [key: string]: Vaccination[];
        },
    );
    const [patientWithPickupFrom, setPatientWithPickupFrom] = useState<IPatientIdOdsCode[]>([]);

    useEffect(() => {
        const patientsForVisitedPickups = patientsToAdd.filter(
            (patient) => patient.odsCode && visitedPickups.includes(patient.odsCode),
        );

        const patientsMap = patientsForVisitedPickups.reduce(
            (acc, patient) => {
                if (patient.odsCode && acc[patient.odsCode]) {
                    acc[patient.odsCode].push(patient);
                } else if (patient.odsCode) {
                    acc[patient.odsCode] = [patient];
                }

                return acc;
            },
            {} as {
                [key: string]: Vaccination[];
            },
        );

        setNewPatientsForVisitedPickups(patientsMap);
    }, [patientsToAdd, visitedPickups]);

    const gpPickups = route?.itinerary.instructions
        .reduce(
            (acc, instruction) => {
                if (
                    instruction.instructionType === 'Pickup' &&
                    instruction.itineraryItem?.name &&
                    instruction.itineraryItem.odsCode &&
                    !acc.find(({ odsCode }) => odsCode === instruction.itineraryItem?.odsCode)
                ) {
                    acc.push({
                        name: instruction.itineraryItem.name,
                        odsCode: instruction.itineraryItem.odsCode,
                    });
                }

                return acc;
            },
            [] as Record<string, string>[],
        )
        .sort((a, b) => a.name.localeCompare(b.name));

    const onClose = () => {
        cleanUp();
        handleClose(false);
    };
    const cleanUp = () => {
        setNewPatientsForVisitedPickups({});
        setVisitedPickups([]);
        setPatientWithPickupFrom([]);
    };
    const onConfirm = async () => {
        // patient has pickup that was not visited => add dropoffFrom
        // patient has brand-new pickup => add dropoffFrom
        // patient has pickup that was visited and is marked as needing pickup => add dropoffFrom
        // patient has pickup that was visited and is not marked as needing pickup => don't add dropoffFrom
        const addPatients = patientsToAdd.reduce((patients, job) => {
            const skipPickup =
                visitedPickups.includes(job.odsCode!) &&
                !patientWithPickupFrom.find(
                    ({ patientId, odsCode }) => patientId === job.id && odsCode === job.odsCode,
                );
            const formattedPatient = formatPatientForRoute({
                patient: job,
                selectedDate: route?.itinerary.route.startTime,
                vaccinationDuration,
                vaccinationDetails,
                routeType,
                jobs: [],
                skipPickup,
            });

            if (formattedPatient) {
                patients.push(formattedPatient);
            }

            return patients;
        }, [] as RoutePatientInput[]);

        let requestBody = recalculateRoute
            ? {
                  selectedStartTime,
                  visitedPickups,
                  removePatients: [vaccinationToRemove?.id],
                  ...(hub && { hub: { address: hub.address } }),
              }
            : {
                  addPatients,
                  visitedPickups,
                  removePatients: [vaccinationToRemove?.id],
              };
        cleanUp();
        setIsOpen(false);
        // route and vaccinationToRemove are from props, we are only calculating requestBody
        // Which has patients being removed/added
        onRequestUpdatedRoute(route, requestBody, vaccinationToRemove);
    };

    const jobStatus = route?.jobStatus || ('' as JobStatus);

    const showGpPickupsSelectAll = (gpPickups?.length || 0) > 0;

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Recalculation options"
            className="modal modal__dialog--with-existing-route"
        >
            <div
                className="modal__container modal__container--with-existing-route"
                data-testid="Pickup-details-dialog"
            >
                <p>
                    The current route requires a pickup from one or more GP practices. Please tick
                    those GP practices which have already been visited by the HCP.
                </p>
                <ConditionalDisplay show={showGpPickupsSelectAll}>
                    <div className="pickup-details-dialog__select-all-container">
                        <SelectAllUnselectAll
                            onSelectAll={() => {
                                const visitAll =
                                    gpPickups?.map((gpPickup) => gpPickup.odsCode) || [];
                                setVisitedPickups(visitAll);
                            }}
                            onUnselectAll={() => setVisitedPickups([])}
                        />
                    </div>
                </ConditionalDisplay>
                {gpPickups?.map(({ name, odsCode }, index) => (
                    <Checkbox
                        key={`gp-pickups-${name}`}
                        className="v2__checkbox"
                        label={`${name} (${odsCode})`}
                        checked={visitedPickups.includes(odsCode)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.target.checked) {
                                setVisitedPickups([...visitedPickups, odsCode]);
                            } else {
                                setVisitedPickups(visitedPickups.filter((p) => p !== odsCode));
                            }
                        }}
                        data-testid={`Pickup-details-dialog__GP-pickup-${index}`}
                    />
                ))}
                <ConditionalDisplay show={Object.keys(newPatientsForVisitedPickups).length > 0}>
                    <div>
                        <p className="modal__paragraph">
                            Some of the to-be-added patients require a pickup from a GP practice
                            that has already been visited by the HCP. Please tick those patients for
                            which the HCP must return to the GP practice for an additional pickup.
                        </p>
                        <div className="pickup-details-dialog__select-all-container">
                            <SelectAllUnselectAll
                                onSelectAll={() => {
                                    const visitAll: IPatientIdOdsCode[] = [];
                                    Object.keys(newPatientsForVisitedPickups).forEach((odsCode) => {
                                        newPatientsForVisitedPickups[odsCode].forEach((patient) => {
                                            visitAll.push({
                                                patientId: patient.id,
                                                odsCode: patient.odsCode!,
                                            });
                                        });
                                    });
                                    setPatientWithPickupFrom(visitAll);
                                }}
                                onUnselectAll={() => setPatientWithPickupFrom([])}
                            />
                        </div>
                    </div>
                </ConditionalDisplay>
                {Object.keys(newPatientsForVisitedPickups).map((odsCode) => (
                    <div style={{ marginBottom: '25px' }} key={`odsCode-${odsCode}`}>
                        {newPatientsForVisitedPickups[odsCode].map((patient) => (
                            <Checkbox
                                key={`checkbox-${patient.id}`}
                                className="v2__checkbox"
                                checked={patientWithPickupFrom
                                    .map(({ patientId }) => patientId)
                                    .includes(patient.id)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.target.checked) {
                                        setPatientWithPickupFrom([
                                            ...patientWithPickupFrom,
                                            { patientId: patient.id, odsCode: patient.odsCode! },
                                        ]);
                                    } else {
                                        setPatientWithPickupFrom(
                                            patientWithPickupFrom.filter(
                                                ({ patientId }) => patientId !== patient.id,
                                            ),
                                        );
                                    }
                                }}
                            >
                                {`${formatNameFirstMiddleLast(patient)} (${
                                    patient.nhsNumber
                                }, ${formatDisplayDate(patient.dateOfBirth)}) from ${
                                    patient.practice
                                }`}
                            </Checkbox>
                        ))}
                    </div>
                ))}
                <div className="modal__footer-buttons">
                    <Button
                        name="Close"
                        elem={ButtonElems.BUTTON}
                        color={ButtonColors.GREY}
                        className="v2__form-submit-button"
                        type="button"
                        clickEvent={onClose}
                    />
                    <Button
                        name="Recalculate"
                        elem={ButtonElems.BUTTON}
                        size={ButtonSizes.MEDIUM}
                        className="v2__form-submit-button"
                        type="button"
                        clickEvent={onConfirm}
                    />
                </div>
            </div>
            <div className="pickup-details-dialog__route-table-header-section">
                <div>
                    <label className="modal__label--itinerary">
                        Current itinerary{'\u00A0'}
                        {'\u00A0'}
                    </label>
                </div>
                <div>
                    <Tag
                        className="vaccinations__instruction-tag-new"
                        intent={routeStatusTags[jobStatus] as Intent}
                        minimal={jobStatus !== 'COMPLETED'}
                    >
                        {routeStatusMappings[jobStatus]}
                    </Tag>
                </div>
            </div>
            <PickupDetailsDialogShowItinerary vaccinationRoute={route} />
        </Dialog>
    );
};
