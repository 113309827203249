import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../../hook/useStores';
import Hour from './Hour';
import RootStore from '../../../../../stores/RootStore';

interface IProps {}

const Timeline: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: { cellWidth, hourStart, hourEnd },
        },
    } = useStores<{ RootStore: RootStore }>();

    const hoursList = useMemo(
        () =>
            Array.from({ length: hourEnd - hourStart + 1 }, (_, i) => i + hourStart).map((hour) => (
                <Hour key={`hour-${hour}`} hour={hour} cellWidth={cellWidth} />
            )),
        [cellWidth, hourStart, hourEnd],
    );

    return <div className="ucr__calendar-timeline">{hoursList}</div>;
};

export default observer(Timeline);
