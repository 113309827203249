import { baseUrl, rootUrl, request } from './baseApi';

/**
 * Gets the configuration for the current organisation
 * @param {boolean} retry - Whether to retry the request if it fails
 */
export const getConfig = (retry: boolean = false): Promise<any> =>
    request(
        {
            url: `${baseUrl}/config`,
            method: 'GET',
        },
        retry,
    );

/**
 * Gets the client keys
 * @param {boolean} retry - Whether to retry the request if it fails
 */
export const getClientKeys = (retry: boolean = false): Promise<any> =>
    request(
        {
            url: `${rootUrl}/config/client-keys`,
            method: 'GET',
        },
        retry,
    );
