import { FC, ReactNode } from 'react';
import { useField, FieldHookConfig } from 'formik';
import { Checkbox, CheckboxProps } from '@blueprintjs/core';

import { FormGroup } from '../FormGroup';

interface IProps extends CheckboxProps {
    name: string;
    className?: string;
    label?: string;
    title?: string;
    required?: boolean;
    inline?: boolean;
    children?: ReactNode;
    withFormGroup?: boolean;
}

const CheckboxComponent: FC<IProps> = ({
    className,
    label,
    title,
    required,
    inline,
    children,
    withFormGroup = true,
    ...props
}) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' } as FieldHookConfig<IProps>);

    const CheckBoxWithChildren = () => (
        <Checkbox
            {...(field as unknown as CheckboxProps)}
            {...(props as IProps)}
            className="v2__form-checkbox-input"
        >
            {children}
        </Checkbox>
    );

    if (withFormGroup) {
        return (
            <FormGroup
                className={className}
                label={label}
                labelFor={props.name}
                required={required}
                touched={meta.touched}
                error={meta.error}
                inline={inline}
            >
                {children ? (
                    <CheckBoxWithChildren />
                ) : (
                    // label is set to title here
                    <Checkbox
                        {...(field as unknown as CheckboxProps)}
                        {...(props as IProps)}
                        label={title}
                        className="v2__form-checkbox-input"
                    />
                )}
            </FormGroup>
        );
    }

    return (
        <>
            {children ? (
                <CheckBoxWithChildren />
            ) : (
                // label is set to label here
                <Checkbox
                    {...(field as unknown as CheckboxProps)}
                    {...(props as IProps)}
                    label={label}
                    className="v2__form-checkbox-input"
                />
            )}
        </>
    );
};

export default CheckboxComponent;
