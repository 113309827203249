import { useField } from 'formik';
import { InputGroup, FormGroup, InputGroupProps2 } from '@blueprintjs/core';
import { formatNhsNumber } from '../../../helpers';

interface ITextInput extends InputGroupProps2 {
    name: string;
    label?: string;
    helperText?: string;
    labelInfo?: string;
}

const TextInput = ({ label, helperText, labelInfo, ...props }: ITextInput) => {
    const [field, meta, helpers] = useField(props);

    const { setValue } = helpers;
    const setNewValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (
            field.name === 'nhsNumber' ||
            field.name === 'contactNumber' ||
            field.name === 'additionalContactNumbers' ||
            field.name === 'mobilePhone' ||
            field.name === 'homePhone'
        ) {
            e.target.value = formatNhsNumber(e.target.value);
        }
        setValue(e.target.value);
    };

    return (
        <>
            <FormGroup
                label={label}
                labelFor={props.name}
                helperText={helperText}
                labelInfo={labelInfo}
            >
                <InputGroup
                    {...field}
                    {...props}
                    id={props.name}
                    onChange={(e) => setNewValue(e)}
                    value={field.value}
                />
                {meta.touched && meta.error ? (
                    <div className="form-error-message">{meta.error}</div>
                ) : null}
            </FormGroup>
        </>
    );
};

export default TextInput;
