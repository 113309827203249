import { FC, useContext, useEffect, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Patient, UpdateJobAction } from '@doc-abode/data-models';

import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Dialogs } from '../../../../../stores/UCRStore';
import ChangeVisitStatusForm from './ChangeToSingleVisitForm';
import { JobsContext } from '../../../../../providers';
import { useView } from '../../views/useView';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    onClose: () => void;
}

type StatusUpdateType = {
    id: string;
    buddyJobStatus?: string;
    hcpId?: string;
    lastUpdatedBy: string;
    version: number;
};

const ChangeVisitStatus: FC<IProps> = ({ onClose }) => {
    const {
        RootStore: {
            ucrStore: { focusedJobId, setOpenedDialog, jobs, focusedUser },
            userStore: {
                user: { username },
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [updateJob, { loading }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                jobsContext.setRefreshAssignedJobs(true);
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [currentJob, setCurrentJob] = useState(
        jobs.find((job: Patient) => job.id === focusedJobId),
    );

    const { loading: jobLoading, data: jobData } = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
    });

    useEffect(() => {
        if (!currentJob && jobData?.getJob) {
            setCurrentJob(jobData.getJob);
        }
    }, [currentJob, jobData]);

    const defaultRemovedHcp = focusedUser === 'user1' ? currentJob?.hcpId : currentJob?.buddyId;

    const onSubmit = async (values: FormikValues) => {
        const remainingHcp =
            currentJob?.hcpId === values.removedHcp || (!currentJob?.hcpId && !values.removedHcp)
                ? currentJob?.buddyId
                : currentJob?.hcpId;

        let input: StatusUpdateType = {
            id: focusedJobId,
            lastUpdatedBy: username,
            version: (currentJob && currentJob.version + 1) || 0,
            hcpId: remainingHcp as string,
        };

        try {
            await updateJob({ variables: { input, action: UpdateJobAction.DOUBLEUP_TO_SINGLE } });
            setOpenedDialog(Dialogs.NONE);
            AppToaster.show({
                message: 'Changed to single visit successfully',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error during the changing to single visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to change the visit',
                intent: 'danger',
            });
        }
    };

    return (
        <Formik initialValues={{ removedHcp: defaultRemovedHcp }} onSubmit={onSubmit}>
            <ChangeVisitStatusForm
                visit={currentJob}
                loading={loading || jobLoading}
                onClose={onClose}
            />
        </Formik>
    );
};

export default ChangeVisitStatus;
