import { FC } from 'react';
import { useField } from 'formik';
import { TagInput, TagInputProps } from '@blueprintjs/core';

import { FormGroup } from '../FormGroup';
import { IFilterOption } from '../../../../interfaces/ucr';

interface IProps {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    options?: IFilterOption[];
}
interface IFieldProps extends IProps, TagInputProps {
    field?: { [key: string]: any };
    meta?: { [key: string]: any };
}

export const TagInputComponent: FC<IFieldProps> = ({
    className,
    label,
    required,
    values = [],
    field = {},
    meta = {},
    ...props
}) => {
    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props?.name}
            required={required}
            touched={meta?.touched}
            error={meta?.error}
        >
            <TagInput className="v2__form-tag-input" values={values} {...props} />
        </FormGroup>
    );
};

// TODO: Finish this component when adding it to any form
const TagInputFormComponent: FC<IProps> = ({ className, label, required, ...props }) => {
    const [{ name, onBlur, onChange, value }, meta] = useField(props);
    const field = { name, onBlur, onChange, value };

    return (
        <TagInputComponent
            className={className}
            label={label}
            required={required}
            field={field}
            meta={meta}
            values={[]}
            {...props}
        />
    );
};

export default TagInputFormComponent;
