import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';
import Validation from './Validation';

const ResetPassword = ({ onResetPassword, onResendCode }) => {
    const initialValues = {
        code: '',
        newPassword: '',
    };

    const { newPasswordValidationSchema, mfaSchema } = Validation();
    const validationSchema = newPasswordValidationSchema.concat(mfaSchema);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onResetPassword}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Reset password</h2>

                        <p>
                            We've sent a verification code to your registered email address or phone
                            number, please enter it below along with your new password.
                        </p>

                        <Input
                            name="code"
                            type="text"
                            inputMode="numeric"
                            label="Verification code"
                            placeholder="e.g. 123456"
                            disabled={isSubmitting}
                        />

                        <p>
                            <button type="button" className="minimal-button" onClick={onResendCode}>
                                Resend verification code
                            </button>
                        </p>

                        <Input
                            name="newPassword"
                            type="password"
                            label="New password"
                            autoComplete="new-password"
                            disabled={isSubmitting}
                        />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Confirm new password"
                            outlined
                            icon="tick"
                            type="submit"
                            fill
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResetPassword;
