import { FC } from 'react';
import { StaffAlert } from '@doc-abode/data-models';

import { getHcpName } from '../../../../../../helpers/ucr';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import { Button, ButtonColors, Info, InfoItem } from '../../../../../v2/components';
import { IHcp } from '../../../../../../interfaces/ucr';

interface Props {
    alert: StaffAlert;
    hcps: IHcp[];
    actions?: { openResolutionForm: () => void; close: () => void };
}

export const StaffAlertDetails: FC<Props> = ({ alert, hcps, actions }) => {
    return (
        <div className="v2__form-section">
            <Info>
                <InfoItem label="Raised:">
                    {alert?.createdAt ? formatDisplayDateTime(alert.createdAt) : ''}
                </InfoItem>
                <InfoItem label="Sender:">{getHcpName(hcps, alert?.userId)}</InfoItem>
                <InfoItem label="Phone:">{alert?.originPhoneNumber}</InfoItem>
            </Info>

            {actions && (
                <div>
                    <Button
                        className="ucr__controls-button"
                        name="Resolve Alert"
                        clickEvent={actions.openResolutionForm}
                    />
                    <Button
                        className="ucr__controls-button"
                        name="Close"
                        color={ButtonColors.GREY}
                        clickEvent={actions.close}
                    />
                </div>
            )}
        </div>
    );
};
