import { Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import useStores from '../../../../../hook/useStores';
import EmptyListView from './EmptyListView';

import { uniqBy } from 'lodash';
import { abortedJobStatuses } from '../visits/ContainerConst';
import { emptyStateMessage, headerProperties } from './ListViewConst';

import { IconArrowDropDown, IconArrowDropUp } from '../../../../../helpers/ucr/icons';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import RootStore from '../../../../../stores/RootStore';
import { ListViewRow } from './ListViewRow';

interface IProps {}

export interface IPatientExtended extends Patient {
    hasStaffAlert?: boolean | null;
    hasPatientAlert?: boolean | null;
}
interface IpationSortedBy {
    key: string;
    direction: string;
}
interface IListGenerate {
    jobList: IPatientExtended[];
    doubleUps: IPatientExtended[];
}

const ListView: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: {
                unassignedJobs,
                assignedJobs,
                showAbortedJobs,
                hcpFilters,
                selectedDate,
                patientAlerts,
                staffAlerts,
                hcps,
                nameFilters,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [listOfJobs, setListOfJobs] = useState<IPatientExtended[]>([]);
    const [listOfDoubleUps, setListOfDoubleUps] = useState<IPatientExtended[]>([]);
    const [sortConfig, setSortConfig] = useState<IpationSortedBy>({
        key: 'startDateTime',
        direction: 'ascending',
    });
    const requestSort = (key: string) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = '';
        } else {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (name: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    useMemo(() => {
        const assignedJobsFiltered = (assignedJobs as Patient[]).filter((job) =>
            moment(job.startDateTime || job.dateOfVisit).isSame(selectedDate, 'day'),
        );

        const filteredJobsAssigned = showAbortedJobs
            ? assignedJobsFiltered
            : assignedJobsFiltered.filter(
                  (j) =>
                      !abortedJobStatuses.includes(j.jobStatus) ||
                      (j.buddyJobStatus && !abortedJobStatuses.includes(j.buddyJobStatus)),
              );

        const filteredUnassignedJobs = unassignedJobs.filter((job: any) =>
            moment(job.dateOfVisit).isSame(selectedDate, 'day'),
        );

        let jobs = [...filteredUnassignedJobs, ...filteredJobsAssigned];

        if (
            hcpFilters?.hcpType?.length ||
            hcpFilters?.availability?.length ||
            nameFilters.staffName?.length ||
            hcpFilters?.band?.length ||
            hcpFilters?.gender?.length
        ) {
            let filteredList = filteredJobsAssigned.filter((value: Patient) => {
                let staffNameStatus: boolean = true;
                if (nameFilters.staffName.length > 0) {
                    staffNameStatus = nameFilters.staffName.some((name: any) => {
                        return (
                            value.hcpName
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.buddyName
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.buddyId
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.hcpId?.toLowerCase().includes(String(name).toLowerCase().trim())
                        );
                    });
                }

                return staffNameStatus;
            });

            if (
                hcpFilters.hcpType.length ||
                hcpFilters.availability.length ||
                hcpFilters.band.length ||
                hcpFilters.gender.length
            ) {
                filteredList = filteredList.filter((value: Patient) => {
                    let staffNameStatus: boolean = false;

                    staffNameStatus = hcps.some(({ userId }: { userId: any }) => {
                        return value.buddyId === userId || value.hcpId === userId;
                    });

                    return staffNameStatus;
                });
            }
            jobs = [...filteredList];
        }

        // remove any duplicates from assigned and unassigned. (mainly double up causing this.)
        jobs = uniqBy(jobs, 'id');

        // find all the double ups and duplicate their id so they appear twice in the list
        // as part of the duplication, we map the buddy fields to the job fields to simplify the display logic in the detail rows
        // to ensure warnings are still correctly resolved, we need to inverse the common fields (status, ids) that are used for checks
        let doubleUps: Patient[] = [];
        jobs.forEach((patient: Patient) => {
            if (isMultiAssigneeJob(patient)) {
                doubleUps.push({
                    ...patient,
                    jobStatus: patient.buddyJobStatus,
                    hcpName: patient.buddyName,
                    hcpId: patient.buddyId,
                    hcpAbortedDateTime: patient.buddyHcpAbortedDateTime,
                    finishedDateTime: patient.buddyFinishedDateTime,
                    arrivedDateTime: patient.buddyArrivedDateTime,
                    buddyJobStatus: patient.jobStatus,
                    buddyId: patient.hcpId,
                    buddyName: patient.hcpName,
                } as Patient);
            }
        });

        setListOfDoubleUps(doubleUps);
        jobs = [...jobs, ...doubleUps];

        const unresolvedStaffAlert = new Set(staffAlerts.map((alert: any) => alert.userId));
        const jobList = jobs
            .map((patient: IPatientExtended): IPatientExtended => {
                const hasPatientAlert: boolean = patientAlerts.some((alert: any) => {
                    const isSame = moment(alert.createdAt).isSame(selectedDate, 'day');
                    return alert.jobId === patient.id && isSame;
                });

                patient.hasPatientAlert = hasPatientAlert;
                patient.hasStaffAlert =
                    unresolvedStaffAlert.has(patient.hcpId || '') ||
                    unresolvedStaffAlert.has(patient.buddyId || '')
                        ? true
                        : false;

                return patient;
            })
            .sort((patientA: IPatientExtended, patientB: IPatientExtended): number => {
                let aLastName = patientA.lastName || '';
                let bLastName = patientB.lastName || '';
                let aStartTime =
                    moment(patientA.startDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;
                let bStartTime =
                    moment(patientB.startDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;

                let aArriveTime =
                    moment(patientA.arrivedDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;
                let bArriveTime =
                    moment(patientB.arrivedDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;

                if (patientA.hasStaffAlert) {
                    return -1;
                } else if (patientB.hasStaffAlert) {
                    return 1;
                } else if (patientA.hasStaffAlert && patientB.hasStaffAlert) {
                    if (aStartTime && bStartTime) {
                        return aStartTime < bStartTime ? -1 : 1;
                    } else if (aStartTime !== bStartTime) {
                        return bStartTime ? -1 : 1;
                    }
                }

                if (patientA.hasPatientAlert) {
                    return -1;
                } else if (patientB.hasPatientAlert) {
                    return 1;
                } else if (patientA.hasPatientAlert && patientB.hasPatientAlert) {
                    if (aStartTime && bStartTime) {
                        return aStartTime < bStartTime ? -1 : 1;
                    } else if (aStartTime !== bStartTime) {
                        return bStartTime ? -1 : 1;
                    }
                }

                if (aStartTime && bStartTime && aStartTime !== bStartTime) {
                    return aStartTime < bStartTime ? -1 : 1;
                } else if (aStartTime !== bStartTime) {
                    return bStartTime ? -1 : 1;
                }

                if (aArriveTime && bArriveTime) {
                    return aArriveTime < bArriveTime ? 1 : -1;
                }

                return aLastName.localeCompare(bLastName);
            })
            .sort((a: any, b: any) => {
                if (a[sortConfig.key] === null) {
                    return 1;
                }

                if (b[sortConfig.key] === null) {
                    return -1;
                }
                if (a[sortConfig.key] === b[sortConfig.key]) {
                    return 0;
                }
                if (
                    a[sortConfig.key] !== null &&
                    b[sortConfig.key] !== null &&
                    a[sortConfig.key] !== undefined &&
                    b[sortConfig.key] !== undefined
                ) {
                    if (sortConfig.direction === '') {
                        return NaN;
                    }
                    if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                } else {
                    if (sortConfig.direction === '') {
                        return NaN;
                    }
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
            });

        setListOfJobs(jobList);
    }, [
        assignedJobs,
        hcpFilters,
        hcps,
        nameFilters.staffName,
        patientAlerts,
        selectedDate,
        showAbortedJobs,
        staffAlerts,
        unassignedJobs,
        sortConfig,
    ]);

    // generates the header if adding or removing a remove please add/remove grid-template-columns on listview.scss
    const GenerateHeader: FC<IProps> = (): JSX.Element => {
        const {
            RootStore: {
                configStore: { isFeatureEnabled },
            },
        } = useStores<{ RootStore: RootStore }>();

        const jsxArr = headerProperties.map((value, index) => {
            if (!value.featureFlag || isFeatureEnabled(value.featureFlag)) {
                return (
                    <th
                        className="ucr-listview__header"
                        key={'listview-header-' + index}
                        onClick={() => requestSort(value.columnName)}
                    >
                        <div className="patient-list__header-flex">
                            <div
                                className={`patient-list__header-text ${getClassNamesFor(
                                    value.columnName,
                                )}`}
                            >
                                {value.headerName}
                            </div>
                            {value.sortable && (
                                <span className={getClassNamesFor(value.columnName)}>
                                    <IconArrowDropUp
                                        className={'patient-list__header-sort-icon icon-ascending'}
                                    />
                                    <IconArrowDropDown
                                        className={`patient-list__header-sort-icon icon-descending`}
                                    />
                                </span>
                            )}
                        </div>
                    </th>
                );
            } else {
                return <></>;
            }
        });
        return <>{jsxArr}</>;
    };

    // generates the list view per row.
    const GenerateList: FC<IListGenerate> = ({ jobList, doubleUps }): JSX.Element => {
        let jsxArr = jobList.map((job: IPatientExtended, index: number) => {
            return <ListViewRow job={job} index={index} isDoubleUp={doubleUps.includes(job)} />;
        });
        return <>{jsxArr}</>;
    };

    return (
        <div className="ucr-listview__main">
            <table className="ucr-listview__containerWidth">
                <thead>
                    <tr className="ucr-listview__list-row ucr-listview__list-row--sticky">
                        <GenerateHeader />
                    </tr>
                </thead>
                {listOfJobs.length > 0 && (
                    <tbody>
                        <GenerateList jobList={listOfJobs} doubleUps={listOfDoubleUps} />
                    </tbody>
                )}
            </table>
            {listOfJobs.length === 0 && <EmptyListView message={emptyStateMessage} />}
        </div>
    );
};

export default observer(ListView);
