import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';
import Recaptcha from '../common/formik/Recaptcha';

const ForgotUsername = ({ didError, onBackToSignIn, onForgotUsername }) => {
    const initialValues = {
        email: '',
        recaptcha: null,
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address provided.')
            .required('This field is required.'),
        recaptcha: Yup.string().nullable().required('Please complete the Recaptcha.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onForgotUsername}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Forgotten username</h2>

                        <p>
                            Please enter your email address below and if it matches an account in
                            our records, we will send an email to that address containing your user
                            name.
                        </p>

                        <Input
                            name="email"
                            label="Email address"
                            placeholder="e.g. jane.smith@example.com"
                            disabled={isSubmitting}
                        />

                        <Recaptcha />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Send me an email reminder"
                            icon="send-message"
                            type="submit"
                            outlined
                            fill
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ForgotUsername;
