import cn from 'classnames';
import { FC, ReactNode } from 'react';

import { Patient } from '@doc-abode/data-models';
import { getHcp } from '../../../helpers/ucr/getHcp';
import { IconClose } from '../../../helpers/ucr/icons';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import { formatDisplayDate, formatDisplayTime } from '../../modules/helpers/formatData';

export enum DialogSizes {
    SMALL = 'small',
    LARGE = 'large',
}

interface IProps {
    title: string;
    titleClassName?: string;
    size?: DialogSizes;
    isShow: boolean;
    children: ReactNode;
    closeEvent: () => void;
    renderChildrenOnlyWhenVisible?: boolean;
}

const Dialog: FC<IProps> = ({
    title,
    titleClassName,
    size = DialogSizes.LARGE,
    children,
    isShow,
    closeEvent,
    renderChildrenOnlyWhenVisible = false,
}) => {
    const {
        RootStore: {
            ucrStore: { focusedJobId, jobs },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();
    const currentJob = jobs.find((job: Patient) => job.id === focusedJobId);
    const createdByHcp = getHcp(users, currentJob?.createdBy);
    const updatedByHcp = getHcp(users, currentJob?.lastUpdatedBy);
    const createDateTime = currentJob?.createDateTime;
    const lastUpdatedDateTime = currentJob?.lastUpdatedDateTime;

    return (
        <div
            className={cn('v2__dialog', {
                'v2__dialog--show': isShow,
                'v2__dialog--hide': !isShow,
            })}
        >
            <div
                className={cn('v2__dialog-wrapper', {
                    'v2__dialog-wrapper--show': isShow,
                    'v2__dialog-wrapper--hide': !isShow,
                })}
            >
                <div
                    className="v2__dialog-overlay"
                    onClick={closeEvent}
                    data-testid="dialog-close"
                />
                <div className={`v2__dialog-content v2__dialog-content--${size}`}>
                    <div className="v2__dialog-header">
                        <span className={`v2__dialog-title ${titleClassName || ''}`}>{title}</span>
                        <div className="v2__dialog-header-right-block">
                            {title === 'Edit visit' || title === 'Edit administrative time' ? (
                                <>
                                    <span className="v2__dialog-header-update-by">
                                        created by {createdByHcp?.userName || currentJob?.createdBy}{' '}
                                        on&nbsp;
                                        {formatDisplayDate(createDateTime)}&nbsp;
                                        {formatDisplayTime(createDateTime)}
                                    </span>
                                    {lastUpdatedDateTime && (
                                        <span className="v2__dialog-header-update-by">
                                            last updated by{' '}
                                            {updatedByHcp?.userName || currentJob.lastUpdatedBy}{' '}
                                            on&nbsp;
                                            {formatDisplayDate(lastUpdatedDateTime)}&nbsp;
                                            {formatDisplayTime(lastUpdatedDateTime)}
                                        </span>
                                    )}
                                </>
                            ) : null}
                            <span className="v2__dialog-close" onClick={closeEvent}>
                                <IconClose />
                            </span>
                        </div>
                    </div>
                    <div className="v2__dialog-main">
                        {renderChildrenOnlyWhenVisible && !isShow ? null : children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dialog;
