import { FC, KeyboardEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { TimePicker, TimePickerProps } from '@blueprintjs/datetime';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';

import { FormGroup } from '../FormGroup';
import { getDateDuration } from '../../../../helpers/ucr';

const availableKeys = ['Alt', 'Backspace', 'Ctrl', 'Enter', 'Shift', 'Tab', 'ArrowUp', 'ArrowDown'];

interface IProps extends TimePickerProps {
    name: string;
    label?: string;
    fullWidth?: boolean;
    required?: boolean;
    checkboxPosition?: string;
    onChangeCheckbox?: (isChecked: boolean) => void;
    handleOnChange?: (value: Date) => void;
    showCheckbox?: boolean;
    displayErrors?: string;
}

const DurationInputComponent: FC<IProps> = ({
    name,
    className,
    label,
    fullWidth,
    required,
    defaultValue,
    selectAllOnFocus = true,
    onChangeCheckbox = () => {},
    disabled,
    displayErrors,
    handleOnChange = () => {},
    ...props
}) => {
    const [field, meta] = useField(name);
    const { setFieldValue, setFieldTouched } = useFormikContext();
    // When the field is disabled its value is set to null, so we save its previous so it can be
    // restored when the field is re-enabled
    const [savedValue, setSavedValue] = useState<Date | undefined>(
        getDateDuration(field.value) || defaultValue || null,
    );

    const formatDate = (date: Date) =>
        `${date.getHours().toString().padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}`;

    useEffect(() => {
        const newValue =
            savedValue ||
            moment()
                .minute(Number(moment().add(5, 'minutes').format('mm')))
                .toDate();
        setFieldValue(name, formatDate(newValue));
    }, [name, setFieldValue, savedValue]);

    const changeTime = (time: Date) => {
        setFieldValue(name, formatDate(time));
        setFieldTouched(name, true, true);
        setSavedValue(time);

        if (handleOnChange) {
            handleOnChange(time);
        }
    };

    const keyDownEvent = (e: KeyboardEvent) => {
        const matchesDigit = /\d/.test(e.key);

        if (!matchesDigit && !availableKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <FormGroup
            className={cn(className, 'v2__form-time-input-group')}
            label={label}
            labelFor={field.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <TimePicker
                {...props}
                className={cn('v2__form-time-input', {
                    'v2__form-time-input--am-pm': props.useAmPm,
                    'v2__form-time-input--full': fullWidth,
                })}
                value={getDateDuration(field.value)}
                defaultValue={savedValue}
                onKeyDown={keyDownEvent}
                onChange={changeTime}
                disabled={disabled}
                selectAllOnFocus={selectAllOnFocus}
            />
        </FormGroup>
    );
};

export default DurationInputComponent;
