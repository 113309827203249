import { JobStatus } from '@doc-abode/data-models';
import { isArrived, isCompleted } from './statusCheckHelper';

export interface IShouldShowPostJobNotes {
    jobStatus: JobStatus | undefined;
}

export function shouldShowPostJobNotes({ jobStatus }: IShouldShowPostJobNotes): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return isArrived({ jobStatus }) || isCompleted({ jobStatus });
}
