import { FC } from 'react';
import { observer } from 'mobx-react';

import { Views, UrgentWarningsButton } from './side';
import { SideSizes } from '../../../../stores/UCRStore';

interface IProps {}

const Left: FC<IProps> = () => {
    return (
        <aside className={`ucr__side ucr__side--left ucr__side--${SideSizes.SMALL}`}>
            <div className="ucr__side-wrapper ucr__side-wrapper--left">
                <div className="ucr__side-content">
                    <UrgentWarningsButton size={SideSizes.SMALL} />
                    <Views size={SideSizes.SMALL} />
                </div>
            </div>
        </aside>
    );
};

export default observer(Left);
