import { FC } from 'react';
import { Text } from '../../../../v2/form';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

export const PdsIssues: FC<{
    showError?: boolean;
}> = ({ showError = true }) => {
    const {
        RootStore: {
            ucrStore: { pdsError, pdsWarnings },
        },
    } = useStores<{ RootStore: RootStore }>();

    return (
        <>
            {showError && !!pdsError && (
                <div className="v2__form-no-data">
                    <Text
                        name="pdsError"
                        title="Unable to retrieve data from SPINE"
                        description={pdsError}
                        titleClassName="v2__form-no-data--title"
                    />
                </div>
            )}
            {pdsWarnings.length > 0 &&
                pdsWarnings.map((warning: any) => (
                    <div key={warning.title} className="v2__form-no-data">
                        <Text
                            name={`pdsWarining-${warning.title}`}
                            title={warning.title}
                            description={warning.message}
                            titleClassName="v2__form-no-data--title"
                        />
                    </div>
                ))}
        </>
    );
};
