import { useState, useEffect } from 'react';
import { FormikValues } from 'formik';
import moment from 'moment';
import { S1Referral } from '@doc-abode/data-models';

import { FormMode, FormSteps, getButtonName } from '../common';
import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';
import RootStore from '../../../../../stores/RootStore';
import { formatS1ReferralName } from '../../../../../helpers/ucr/formatS1ReferralName';

export type ReferralDetailsVMInterface = {
    values: FormikValues;
    formMode: FormMode;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};

const FIVE_SECONDS = 5 * 1000;

export const useReferralDetailsViewModel = ({
    formMode,
    values,
    onNextStep,
    onSaveForm,
}: ReferralDetailsVMInterface) => {
    const {
        RootStore: {
            lovsStore: { referrer },
            s1Store: { s1Referrals, s1ReferralsLoading, fetchS1Referrals, syncS1Referrals },
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [referralsInterval, setReferralsInterval] = useState<NodeJS.Timeout | null>(null);
    const [referrals, setReferrals] = useState<S1Referral[]>([]);
    const [activeReferrals, setActiveReferrals] = useState<S1Referral[]>([]);
    const [referralsUpdatedAt, setReferralsUpdatedAt] = useState<string>('');

    const s1Enabled = isFeatureEnabled('s1Enabled');

    useEffect(() => {
        const getReferrals = async () => {
            try {
                await fetchS1Referrals(values.nhsNumber);
            } catch (error) {
                console.error('Error fetching inital referral list from SystmOne', error);
                AppToaster.show({
                    message: 'Failed to fetch referral list.',
                    intent: 'danger',
                });
            }
        };

        if (values.nhsNumber && s1Enabled) {
            getReferrals();
        }

        return () => {
            if (referralsInterval) {
                clearInterval(referralsInterval);
            }
        };
    }, [fetchS1Referrals, values.nhsNumber, referralsInterval, s1Enabled]);

    useEffect(() => {
        const { referrals: s1Referral = [], updatedAt } = s1Referrals[values.nhsNumber] || {};
        setReferrals(s1Referral);
        setReferralsUpdatedAt(updatedAt);
    }, [s1Referrals, values.nhsNumber]);

    useEffect(() => {
        setActiveReferrals(
            referrals.filter(
                ({ EndDate, ReferralUID }) =>
                    typeof EndDate !== 'string' || ReferralUID === values.systmOneRef,
            ),
        );
    }, [referrals, values.systmOneRef]);

    const buttonName = getButtonName(formMode);

    const onSubmit = () => {
        switch (formMode) {
            case FormMode.ADD_VISIT:
                onNextStep?.(FormSteps.REVIEW, FormSteps.REFERRAL);
                break;
            case FormMode.EDIT_VISIT:
                onSaveForm?.(FormSteps.REFERRAL);
                break;
            case FormMode.DEFAULT:
            case FormMode.FOLLOW_UP:
                onNextStep?.(FormSteps.CARE, FormSteps.REFERRAL);
                break;
        }
    };

    const onSyncS1Referrals = async () => {
        const requestedAt = new Date().toISOString();
        let requestId = '';

        try {
            const response = await syncS1Referrals(values.nhsNumber);
            if (response?.requestId) {
                requestId = response.requestId;
            }
        } catch (error) {
            console.error('Error syncing referral list from SystmOne', error);
            AppToaster.show({
                message: 'Failed to sync referral list from SystmOne.',
                intent: 'danger',
            });
        }

        if (requestId) {
            const interval = setInterval(async () => {
                try {
                    const response = await fetchS1Referrals(values.nhsNumber);
                    // Check if the referral list has been updated since the request was made
                    if (response?.updatedAt && response.updatedAt > requestedAt) {
                        clearInterval(interval);
                        setReferralsInterval(null);
                    }
                } catch (error) {
                    console.error('Error fetching referral list', error);
                    clearInterval(interval);
                    setReferralsInterval(null);
                    AppToaster.show({
                        message: 'Failed to fetch referral list.',
                        intent: 'danger',
                    });
                }
            }, FIVE_SECONDS);

            setReferralsInterval(interval);

            AppToaster.show({
                message: 'The latest referrals from SystmOne should be available shortly.',
                intent: 'success',
            });
        } else {
            AppToaster.show({
                message:
                    'Referrals are already being synchronised. Please wait and try again shortly.',
                intent: 'warning',
            });
        }
    };

    let referralSelectHelperText = '';

    if (referralsUpdatedAt) {
        const referralsUpdatedTimestamp = formatDisplayDateTime(referralsUpdatedAt);
        const referralsLoadingText = s1ReferralsLoading ? 'Checking...' : '';
        referralSelectHelperText = `Last synced from SystmOne at ${referralsUpdatedTimestamp}. ${referralsLoadingText}`;
    }

    let systmOneRef = values.systmOneRef;

    if (s1Enabled && systmOneRef) {
        if (s1ReferralsLoading) {
            systmOneRef = 'Loading referral details...';
        } else {
            const referral = referrals.find((ref) => ref.ReferralUID === values.systmOneRef);
            systmOneRef = referral
                ? formatS1ReferralName(referral)
                : 'SystmOne referral cannot be resolved';
        }
    }

    const systmOneRefLabel = s1Enabled ? 'SystmOne referral' : 'Referral reference number';

    const referralDate = values.referralDateTime
        ? moment(values.referralDateTime).format('DD/MM/YYYY')
        : values.referralDateTime;

    const referralTime = values.referralDateTime
        ? moment(values.referralDateTime).format('hh:mm a')
        : values.referralDateTime;

    return {
        activeReferrals,
        referrals,
        onSyncS1Referrals,
        referrer,
        buttonName,
        onSubmit,
        referralSelectHelperText,
        s1Enabled,
        systmOneRef,
        systmOneRefLabel,
        referralDate,
        referralTime,
    };
};
