import { useMutation } from '@apollo/client';
import { useState } from 'react';

import { JobStatus, Patient } from '@doc-abode/data-models';
import useStores from '../../../../../hook/useStores';

import { isAbortNoteRequired } from '../../../../../helpers/isAbortNoteRequired';
import { isValidStringValue } from '../../../../../helpers/isValidStringValue';

import { getSortedAbortReasons } from '../../../../../helpers/getSortedAbortReasons';
import { UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import AppToaster from '../../../../modules/helpers/Toaster';
import RootStore from '../../../../../stores/RootStore';

export interface IUseAbortJobDialogViewModel {
    job: Patient;
    showAbortPrompt: boolean;
    setShowAbortPrompt: (value: boolean) => void;
    onConfirmRedirectAction?: () => void;
}

// This message will be returned in a toast as well as the abort dialog when the update method
const ERROR_ON_UPDATE_MESSAGE = (
    <>
        There was a problem aborting the job. Please try again or contact{' '}
        <a href="mailto:support@docabode.com">support@docabode.com</a> for help.
    </>
);

export const useAbortJobDialogViewModel = (props: IUseAbortJobDialogViewModel) => {
    const {
        RootStore: {
            userStore: {
                user: { username },
            },
            configStore: { abortReasons },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Get a list of sorted abort reasons which are still active; 3rd param is set to false as Vaccinations does not support part-cancellations
    const sortedAbortReasons = getSortedAbortReasons(abortReasons, true, false);

    // The mask opens with the first aborted reason found. This reason my require mandatory notes
    const [areAbortNotesMandatory, setAreAbortNotesMandatory] = useState(
        abortReasons?.length > 0 ? abortReasons[0].notesRequired : false,
    );
    const [abortNotes, setAbortNotes] = useState('');
    const [abortReason, setAbortReason] = useState(
        abortReasons?.length > 0 ? abortReasons[0].label : '',
    );
    const [abortErrors, setAbortErrors] = useState<any[]>([]);
    const [updateJob] = useMutation(UPDATE_JOB);

    const handleChangeOfAbortReason = (selectedAbortReason: string) => {
        let initialSelection = selectedAbortReason;
        if (selectedAbortReason === '') {
            initialSelection = abortReasons?.length > 0 ? abortReasons[0].label : '';
        }
        setAbortReason(initialSelection);
        setAreAbortNotesMandatory(isAbortNoteRequired(abortReasons, initialSelection));
    };

    const handleChangeOfAbortNotes = (newAbortNotes: string) => {
        setAbortNotes(newAbortNotes);
    };

    const onCancel = () => {
        props.setShowAbortPrompt(false);

        // Reset values upon cancellation
        setAbortErrors([]);
        // Calling handle method in order to determine whether the initial state requires or does not require mandatory notes
        handleChangeOfAbortReason('');
        setAbortNotes('');
    };

    const onConfirm = async () => {
        if (!isValidStringValue(areAbortNotesMandatory, abortNotes)) {
            setAbortErrors(['Abort notes are mandatory']);
            return;
        }

        try {
            await updateJob({
                variables: {
                    input: {
                        id: props.job.id,
                        lastUpdatedBy: username,
                        jobStatus: JobStatus.CONTROLLER_ABORTED,
                        controllerAbortedReason: abortReason,
                        controllerAbortedNotes: abortNotes?.trim(),
                        version: props.job.version + 1,
                    },
                },
            });
        } catch (err) {
            AppToaster.show({
                message: ERROR_ON_UPDATE_MESSAGE,

                intent: 'danger',
                timeout: 0,
            });
            console.error(err);

            setAbortErrors([ERROR_ON_UPDATE_MESSAGE]);

            return props.setShowAbortPrompt(true);
        }

        props.setShowAbortPrompt(false);

        if (props.onConfirmRedirectAction) {
            props.onConfirmRedirectAction();
        }

        AppToaster.show({
            message: 'The job has been aborted successfully',
            intent: 'success',
        });
    };

    return {
        onConfirm,
        onCancel,
        handleChangeOfAbortNotes,
        handleChangeOfAbortReason,
        sortedAbortReasons,
        abortReason,
        abortNotes,
        areAbortNotesMandatory,
        abortErrors,
    };
};
