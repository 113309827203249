import moment from 'moment';
import { FormikValues } from 'formik';
import { FormattedPDSRecord, Patient } from '@doc-abode/data-models';
import isEqual from 'lodash/isEqual';

type PrePopulateLocalData = {
    localData?: Patient;
    values: FormikValues;
    setSelectedPatientData: (data: Patient) => void;
    setToFormPatientData: (data: Patient) => void;
    bypassSetToForm?: boolean;
};

type PrePopulatePdsData = {
    customData?: { staffPreferredGender?: string[]; languagesSpoken?: string[] };
    pdsData?: FormattedPDSRecord;
    values: FormikValues;
    setSelectedPatientData: (data: Patient) => void;
    setToFormPatientData: (data: Patient) => void;
};

export const prePopulateLocalData = ({
    localData,
    values,
    setSelectedPatientData,
    setToFormPatientData,
    bypassSetToForm = false,
}: PrePopulateLocalData): boolean => {
    if (!localData) return false;

    const newFormValues = { ...values };
    const patientFields: (keyof Patient)[] = [
        'firstName',
        'lastName',
        'middleName',
        'dateOfBirth',
        'gender',
        'contactNumber',
        'additionalContactNumbers',
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'town',
        'postCode',
        'staffPreferredGender',
        'languagesSpoken',
        'pds',
    ];

    patientFields.forEach((f) => {
        newFormValues[f] = localData[f] || undefined;
    });

    if (!isEqual(values, newFormValues) && !bypassSetToForm) {
        setToFormPatientData(newFormValues as Patient);
        setSelectedPatientData(newFormValues as Patient);
    }

    return true;
};

export const prePopulatePdsData = ({
    customData,
    pdsData,
    values,
    setSelectedPatientData,
    setToFormPatientData,
}: PrePopulatePdsData): boolean => {
    if (!pdsData || !Object.keys(pdsData).length) return false;

    const newFormValues = { ...values };
    const fieldsMissing =
        (pdsData.name?.length && !pdsData.singleDataSet.name) ||
        (pdsData.contactNumber?.length && !pdsData.singleDataSet.contactNumber) ||
        (pdsData.address?.length && !pdsData.singleDataSet.address);

    if (pdsData.singleDataSet.name && pdsData.name?.length) {
        if (pdsData.name[0].firstName !== values.firstName) {
            newFormValues.firstName = pdsData.name[0].firstName;
        }

        if (pdsData.name[0].middleName !== values.middleName) {
            newFormValues.middleName = pdsData.name[0].middleName;
        }

        if (pdsData.name[0].lastName !== values.lastName) {
            newFormValues.lastName = pdsData.name[0].lastName;
        }
    }

    if (pdsData.singleDataSet.contactNumber && pdsData.contactNumber?.length) {
        const [contactNumber, ...additionalContactNumbers] = pdsData.contactNumber;
        if (values.contactNumber !== contactNumber.value) {
            newFormValues.contactNumber = contactNumber.value;
        }

        if (additionalContactNumbers.length && !newFormValues.additionalContactNumbers) {
            newFormValues.additionalContactNumbers = [];
        }

        additionalContactNumbers.forEach((c) => {
            if (!values.additionalContactNumbers?.includes(c.value)) {
                newFormValues.additionalContactNumbers.push(c.value);
            }
        });
    }

    if (pdsData.singleDataSet.address && pdsData.address?.length) {
        if (values.addressLine1 !== pdsData.address[0].addressLine1) {
            newFormValues.addressLine1 = pdsData.address[0].addressLine1;
        }
        if (values.addressLine2 !== pdsData.address[0].addressLine2) {
            newFormValues.addressLine2 = pdsData.address[0].addressLine2;
        }
        if (values.addressLine3 !== pdsData.address[0].addressLine3) {
            newFormValues.addressLine3 = pdsData.address[0].addressLine3;
        }

        if (values.town !== pdsData.address[0].town) {
            newFormValues.town = pdsData.address[0].town;
        }

        if (values.postCode !== pdsData.address[0].postCode) {
            newFormValues.postCode = pdsData.address[0].postCode;
        }
    }

    if (
        moment(values.dateOfBirth).format('DD/MM/YYYY') !==
        moment(pdsData.dateOfBirth).format('DD/MM/YYYY')
    ) {
        newFormValues.dateOfBirth = moment(pdsData.dateOfBirth).toDate();
    }

    if (values.gender !== pdsData.gender) {
        newFormValues.gender = pdsData.gender;
    }

    if (customData?.languagesSpoken) {
        newFormValues.languagesSpoken = customData.languagesSpoken;
    }

    if (customData?.staffPreferredGender) {
        newFormValues.staffPreferredGender = customData.staffPreferredGender;
    }

    newFormValues.pds = { versionId: pdsData.versionId };
    newFormValues.nhsNumber = pdsData.nhsNumber;

    if (!isEqual(values, newFormValues)) {
        setSelectedPatientData(newFormValues as Patient);
        if (!fieldsMissing) {
            setToFormPatientData(newFormValues as Patient);
        }
    }

    return !fieldsMissing;
};

type FilterPatientData = PrePopulatePdsData & {
    localData?: Patient;
    setSelectedPatientData: (data: Patient) => void;
    setToFormPatientData: (data: Patient) => void;
    bypassSetToForm?: boolean;
};

export const filterPatientData = ({
    localData,
    pdsData,
    values,
    setSelectedPatientData,
    setToFormPatientData,
    bypassSetToForm = false,
}: FilterPatientData): boolean => {
    const onlyLocalData = localData && (!pdsData || !Object.keys(pdsData).length);
    const onlyPDSData = pdsData && !localData;
    const sameVersionId =
        localData?.pds?.versionId && localData?.pds?.versionId === pdsData?.versionId;

    if (onlyLocalData || sameVersionId) {
        const result = prePopulateLocalData({
            localData,
            values,
            setSelectedPatientData,
            setToFormPatientData,
            bypassSetToForm,
        });

        return result;
    }

    if (onlyPDSData) {
        const { staffPreferredGender = [], languagesSpoken = [] } = localData || ({} as Patient);

        const result = prePopulatePdsData({
            customData: { staffPreferredGender, languagesSpoken },
            pdsData,
            values,
            setSelectedPatientData,
            setToFormPatientData,
        });

        return result;
    }

    return false;
};
