import { observer } from 'mobx-react';
import { FC } from 'react';
import { ConditionalDisplay } from '../../CondtionalDisplay';
import UserRow from './UserRow';
import { useUsersTableColumnViewModel } from './useUsersTableColumnViewModel';
import { useUsersTableViewModel } from './useUsersTableViewModel';

const UsersTable: FC = () => {
    const { showS1Info, showUserStatusInfo } = useUsersTableColumnViewModel();
    const { users, onClickOfUser } = useUsersTableViewModel();

    return (
        <table className="bp5-html-table bp5-html-table-striped bp5-interactive users-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>User ID</th>
                    <ConditionalDisplay show={showS1Info}>
                        <th>SystmOne Username</th>
                    </ConditionalDisplay>
                    <th>HCP Type(s)</th>
                    <th>Maximum drive time</th>
                    <ConditionalDisplay show={showUserStatusInfo}>
                        <th>Status</th>
                    </ConditionalDisplay>
                    <th>Notifications</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user) => (
                    <UserRow
                        user={user}
                        onClick={() => onClickOfUser(user.userId)}
                        key={user.userId}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default observer(UsersTable);
