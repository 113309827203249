import React from 'react';
import { Alert } from '@blueprintjs/core';

const CustomPrompt = ({ message, cleanUp }: any) => {
    const icon = 'trash';
    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);

    return (
        <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Confirm"
            className={`confirmation-alert`}
            onConfirm={ok}
            onCancel={cancel}
            isOpen={true}
            icon={icon}
            intent={'danger'}
            canEscapeKeyCancel={true}
        >
            <h4>{message}</h4>
        </Alert>
    );
};

export default CustomPrompt;
