import React, { useCallback, useEffect, useState } from 'react';
import { Callout, Card } from '@blueprintjs/core';
import Amplify from '@aws-amplify/core';
import { observer } from 'mobx-react';

import {
    AUTH_STATUS,
    completeNewPassword,
    confirmMFACode,
    forgotPassword,
    resetPassword,
    signIn,
    verifyCurrentUserAttribute,
    verifyCurrentUserAttributeSubmit,
} from '../../api/amplifyApi';
import { forgottenUsername } from '../../api/usersApi';
import CompleteNewPassword from './CompleteNewPassword';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';
import MFA from './MFA';
import ResetPassword from './ResetPassword';
import VerifyContact from './VerifyContact';
import SignIn from './SignIn';
import Loader from '../modules/helpers/Loader';
import AppToaster from '../modules/helpers/Toaster';
import config from '../../config';

export const SCREENS = {
    CHECKING: 'CHECKING',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_USERNAME: 'FORGOT_USERNAME',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SIGN_IN: 'SIGN_IN',
    MFA_ENTRY: 'MFA_ENTRY',
    COMPLETE_NEW_PASSWORD: 'COMPLETE_NEW_PASSWORD',
    VERIFY_CONTACT: 'VERIFY_CONTACT',
    LOGGED_IN: 'LOGGED_IN',
};

Amplify.configure({
    ...config().aws,
    aws_project_region: 'eu-west-2',
    aws_cognito_region: 'eu-west-2',
});

const Authenticator = observer(({ children, RootStore }) => {
    const [currentScreen, setCurrentScreen] = useState(SCREENS.CHECKING);
    const [forgottenPasswordUsername, setForgottenPasswordUsername] = useState(null);
    const [error, setError] = useState('');
    const [signInResult, setSignInResult] = useState(null);

    const { getUserSession } = RootStore.userStore;

    const changeScreen = useCallback((nextScreen) => {
        setCurrentScreen(nextScreen);
        setError('');
    }, []);

    const handleError = (message) =>
        setError(
            message ||
                'Sorry, we were unable to sign you in. Please check your details and try again.',
        );

    const onToggleForgotPassword = () => {
        const nextScreen =
            currentScreen === SCREENS.FORGOT_PASSWORD ? SCREENS.SIGN_IN : SCREENS.FORGOT_PASSWORD;

        changeScreen(nextScreen);
    };

    const onToggleForgotUsername = () => {
        const nextScreen =
            currentScreen === SCREENS.FORGOT_USERNAME ? SCREENS.SIGN_IN : SCREENS.FORGOT_USERNAME;

        changeScreen(nextScreen);
    };

    useEffect(() => {
        const checkSession = async () => {
            let session;

            try {
                session = await getUserSession();
            } catch (err) {
                console.error({ err });
            }
            setSignInResult(session);

            if (!session) {
                return changeScreen(SCREENS.SIGN_IN);
            }

            const verifiedContact = session.emailVerified || session.phoneNumberVerified;

            if (verifiedContact) {
                setCurrentScreen(SCREENS.LOGGED_IN);
            } else {
                setCurrentScreen(SCREENS.VERIFY_CONTACT);
            }
        };
        const timer = setTimeout(() => {
            checkSession();
        }, 500);
        return () => clearTimeout(timer);
    }, [changeScreen, getUserSession]);

    const onSignIn = async (values, { setSubmitting }) => {
        setError('');

        try {
            const result = await signIn(values.userName, values.password);

            setSubmitting(false);

            if (result.status === 'SMS_MFA' || result.status === 'SOFTWARE_TOKEN_MFA') {
                setSignInResult(result);
                changeScreen(SCREENS.MFA_ENTRY);
            } else if (result.status === AUTH_STATUS.VERIFICATION_REQUIRED) {
                setSignInResult(result);
                changeScreen(SCREENS.VERIFY_CONTACT);
            } else if (result.status === AUTH_STATUS.NEW_PASSWORD_REQUIRED) {
                setSignInResult(result);
                changeScreen(SCREENS.COMPLETE_NEW_PASSWORD);
            } else {
                const session = await getUserSession();
                setSignInResult(session);
                changeScreen(SCREENS.LOGGED_IN);
            }
        } catch (error) {
            handleError(
                /password has expired/.test(error.message) ? (
                    <>
                        Sorry, your temporary password has expired and must be reset by an
                        administrator.
                        <br />
                        <br />
                        Please email us at{' '}
                        <a href="mailto:support@docabode.com">support@docabode.com</a> and include
                        your username so that we can create you a new password.
                    </>
                ) : (
                    error.message
                ),
            );
            console.error(error);
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onCompleteNewPassword = async (values, { setSubmitting }) => {
        setError('');

        try {
            const result = await completeNewPassword(signInResult.user, values.newPassword);

            setSubmitting(false);

            if (result.status === AUTH_STATUS.VERIFICATION_REQUIRED) {
                setSignInResult(result);
                changeScreen(SCREENS.VERIFY_CONTACT);
            } else {
                const session = await getUserSession();
                setSignInResult(session);
                changeScreen(SCREENS.LOGGED_IN);
            }
        } catch (error) {
            handleError(error.message);
            console.error(error);
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onConfirmCode = async ({ code }, { setSubmitting }) => {
        setError('');

        try {
            await confirmMFACode(signInResult.user, code, signInResult.status);
            const session = await getUserSession();
            setSubmitting(false);
            setSignInResult(session);
            changeScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            handleError(
                error.code === 'CodeMismatchException'
                    ? 'The code you have entered is invalid, please try again.'
                    : error.message,
            );
            console.error(error);
            setSubmitting(false);
        }
    };

    const onForgotPassword = async (values, { setSubmitting }) => {
        setError('');

        try {
            await forgotPassword(values.userName);
            setSubmitting(false);
            setForgottenPasswordUsername(values.userName);
            changeScreen(SCREENS.RESET_PASSWORD);
            AppToaster.show({
                message: 'Verification code sent!',
                intent: 'success',
            });
        } catch (error) {
            handleError(
                error.code === 'InvalidParameterException' ? (
                    <>
                        Sorry, you cannot reset your password as you do not have a verified email
                        address or phone number for us to send your new password. Please email us at{' '}
                        <a className="text-pink-700 underline" href="mailto:support@docabode.com">
                            support@docabode.com
                        </a>{' '}
                        and include your username so that we can create you a new password.
                    </>
                ) : (
                    error.message
                ),
            );
            console.error(error);
            setSubmitting(false);
        }
    };

    const onForgotUsername = async (values, { setSubmitting }) => {
        setError('');

        try {
            await forgottenUsername({ ...values });
            setSubmitting(false);
            changeScreen(SCREENS.SIGN_IN);
            AppToaster.show({
                message: 'Email sent!',
                intent: 'success',
            });
        } catch (error) {
            handleError(
                error.response?.status === 404
                    ? 'Sorry, no user was found with that email address. Please check it and try again.'
                    : error.message,
            );
            console.error(error);
            setSubmitting(false);
        }
    };

    const onResetPassword = async ({ code, newPassword }, { setSubmitting }) => {
        setError('');

        try {
            await resetPassword(forgottenPasswordUsername, code, newPassword);
            const session = await getUserSession();
            setSubmitting(false);
            setSignInResult(session);
            changeScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            handleError(error.message);
            console.error(error);
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onVerifyContact = async ({ contactType, code }, { setSubmitting }) => {
        setError('');

        try {
            await verifyCurrentUserAttributeSubmit(contactType, String(code));
            const session = await getUserSession();
            setSignInResult(session);
            changeScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            handleError(error.message);
            console.error(error);
            setSignInResult(null);
        }

        setSubmitting(false);
    };

    const onRequestCode = async (contactType) => {
        setError('');

        try {
            await verifyCurrentUserAttribute(contactType);
            AppToaster.show({
                message: 'Verification code sent!',
                intent: 'success',
            });
        } catch (error) {
            handleError(error.message);
            console.error(error);
        }
    };

    const onResendCode = async () => {
        setError('');

        try {
            await forgotPassword(forgottenPasswordUsername);
            AppToaster.show({
                message: 'Verification code sent!',
                intent: 'success',
            });
        } catch (error) {
            handleError(error.message);
            console.error(error);
        }
    };

    const getComponent = () => {
        if (currentScreen === SCREENS.FORGOT_PASSWORD) {
            return (
                <ForgotPassword
                    onBackToSignIn={() => {
                        changeScreen(SCREENS.SIGN_IN);
                    }}
                    onForgotPassword={onForgotPassword}
                />
            );
        }

        if (currentScreen === SCREENS.FORGOT_USERNAME) {
            return (
                <ForgotUsername
                    onBackToSignIn={() => {
                        setError(null);
                        changeScreen(SCREENS.SIGN_IN);
                    }}
                    onForgotUsername={onForgotUsername}
                />
            );
        }

        if (currentScreen === SCREENS.RESET_PASSWORD) {
            return <ResetPassword onResendCode={onResendCode} onResetPassword={onResetPassword} />;
        }

        if (currentScreen === SCREENS.COMPLETE_NEW_PASSWORD) {
            return <CompleteNewPassword onCompleteNewPassword={onCompleteNewPassword} />;
        }

        if (currentScreen === SCREENS.MFA_ENTRY) {
            return <MFA onConfirmCode={onConfirmCode} />;
        }

        if (currentScreen === SCREENS.VERIFY_CONTACT) {
            return (
                <VerifyContact onRequestCode={onRequestCode} onVerifyContact={onVerifyContact} />
            );
        }

        if (currentScreen === SCREENS.LOGGED_IN && signInResult) {
            return children;
        }

        // Default to show sign in
        return (
            <SignIn
                onSignIn={onSignIn}
                onToggleForgotPassword={onToggleForgotPassword}
                onToggleForgotUsername={onToggleForgotUsername}
            />
        );
    };

    return (
        <>
            {currentScreen === SCREENS.LOGGED_IN && signInResult ? (
                children
            ) : currentScreen === SCREENS.CHECKING ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <div className="auth">
                    <div className="auth__content">
                        <Card>
                            <img
                                src="/images/logos/docabode_logo.png"
                                alt="Doc Abode Logo"
                                className="auth__logo"
                            />
                            {error && (
                                <Callout className="auth-callout" intent="danger">
                                    {error}
                                </Callout>
                            )}
                            {getComponent()}
                            {currentScreen !== SCREENS.SIGN_IN &&
                                currentScreen !== SCREENS.LOGGED_IN && (
                                    <div className="auth__return-link">
                                        <button
                                            type="button"
                                            className="minimal-button"
                                            onClick={() => changeScreen(SCREENS.SIGN_IN)}
                                        >
                                            Return to sign in
                                        </button>
                                    </div>
                                )}
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
});

export default Authenticator;
