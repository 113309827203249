import { FC } from 'react';
import { JobStatus, Pathway, Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';

import { formatDisplayDateTime } from '../../../modules/helpers/formatData';
import { getServiceDisplayName } from '../forms/common';

interface JobListProps {
    filteredJobs: Patient[];
    pathways?: Pathway[];
}

const futureStatuses = new Set([
    JobStatus.AVAILABLE,
    JobStatus.ACCEPTED,
    JobStatus.CURRENT,
    JobStatus.ARRIVED,
]);

const jobFilter = (job: Patient) => futureStatuses.has(job.jobStatus);

const SummaryJobList: FC<JobListProps> = ({ filteredJobs, pathways = [] }) => {
    filteredJobs = filteredJobs.filter(jobFilter);

    if (filteredJobs.length === 0) {
        return (
            <div className="v2__form-section">
                This staff member does not have any assigned jobs for today
            </div>
        );
    }

    const pathway = pathways.find((pathway) => pathway.value === filteredJobs[0].referralPathway);

    return (
        <div className="v2__form-section">
            <table className="ucr-staff-alert-dialog__job-table">
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>Pathway</th>
                        <th>Service</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredJobs.map((job, i) => (
                        <tr key={`staff-alert-info__job-list-row++${i}`}>
                            <td>
                                {job.startDateTime
                                    ? formatDisplayDateTime(job.startDateTime)
                                    : 'No start time'}
                            </td>
                            <td>{pathway?.label || job.referralPathway}</td>
                            <td>{getServiceDisplayName(pathway?.services, job.disposition)}</td>
                            <td>{Patient.getFriendlyPatientStatus(job.jobStatus)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default observer(SummaryJobList);
