import { Button, Checkbox, Icon } from '@blueprintjs/core';
import { Vaccination } from '@doc-abode/data-models';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import isMultiDoseVaccine from '../../../../helpers/isMultiDoseVaccine';
import useStores from '../../../../hook/useStores';
import {
    formatDisplayDate,
    formatTimeDifferenceInWeeks,
} from '../../../modules/helpers/formatData';
import StatusTag from './StatusTag';
import RootStore from '../../../../stores/RootStore';

interface IPatient {
    patient: Vaccination;
    selectedPatientIds: string[];
    onToggleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
    prevNhsNumber?: string;
    nextNhsNumber?: string;
    groupPatients: boolean;
}

const Patient = ({
    patient,
    selectedPatientIds,
    onToggleSelect,
    prevNhsNumber,
    nextNhsNumber,
    groupPatients,
}: IPatient) => {
    const { RootStore } = useStores<{ RootStore: RootStore }>();
    const history = useHistory();

    const {
        id,
        firstName,
        lastName,
        hubId,
        doseNumber,
        createDateTime,
        jobStatus,
        age,
        selectable,
        dateOfPreviousDose,
        nhsNumber,
        requiresAttention,
        coAdministeredWith,
        friendlyVaccinationCategory,
    } = patient;

    const onClickPatient = (id: string) => {
        history.push(`/vaccinations/patients/${id}`);
    };

    let modifier;

    if (nhsNumber === prevNhsNumber && nhsNumber === nextNhsNumber) {
        modifier = 'middle';
    } else if (nhsNumber === prevNhsNumber) {
        modifier = 'end';
    } else if (nhsNumber === nextNhsNumber) {
        modifier = 'start';
    }

    const isAdditionalRow = nhsNumber === prevNhsNumber && groupPatients;
    const showDoseInfo = isMultiDoseVaccine(patient);

    let doseNumberText = doseNumber ? String(doseNumber) : 'Not provided';

    return (
        <tr
            className={classNames('common-table__row', {
                'common-table__row--selected': selectedPatientIds.includes(id),
                'common-table__row--grouped': groupPatients,
                'common-table__row--requires-attention': requiresAttention.length > 0,
                [`common-table__row--${modifier}`]: modifier,
            })}
            onClick={() => onClickPatient(id)}
        >
            {groupPatients && (
                <td className="common-table__additional">
                    {isAdditionalRow && (
                        <Icon className="common-table__additional-icon" icon="key-enter" />
                    )}
                </td>
            )}
            <td onClick={(event) => event.stopPropagation()}>
                <Checkbox
                    id={id}
                    checked={selectedPatientIds.includes(id)}
                    disabled={!selectable}
                    onChange={onToggleSelect}
                />
            </td>
            <td>
                {!isAdditionalRow && (
                    <>
                        {lastName}, {firstName}
                    </>
                )}
            </td>
            <td>{!isAdditionalRow && age}</td>
            <td>{RootStore.configStore.getHubName(hubId)}</td>
            <td className="common-table__icon-column">
                {coAdministeredWith && coAdministeredWith.length > 0 && (
                    <Icon icon="link" iconSize={16} />
                )}
            </td>
            <td>{friendlyVaccinationCategory}</td>
            <td>{showDoseInfo ? doseNumberText : 'N/A'}</td>
            <td
                className={classNames({
                    'common-table__requires-attention':
                        requiresAttention.includes('dateOfPreviousDose'),
                })}
            >
                {showDoseInfo && dateOfPreviousDose
                    ? formatTimeDifferenceInWeeks(dateOfPreviousDose)
                    : 'N/A'}
            </td>
            <td>{formatDisplayDate(createDateTime)}</td>
            <td>
                <StatusTag jobStatus={jobStatus} isPatient />
            </td>
        </tr>
    );
};

interface ISortedHeader {
    label: string;
    sortBy: string;
    sortKey: string;
    direction: string;
    changeSortKey: (key: string) => void;
}

const SortedHeader = ({ label, sortKey, sortBy, direction, changeSortKey }: ISortedHeader) => {
    let icon = null;

    if (sortKey === sortBy) {
        icon = (
            <Icon
                icon={direction === 'asc' ? 'arrow-up' : 'arrow-down'}
                color="grey"
                iconSize={16}
            />
        );
    }

    return (
        <th className="sorted-header">
            <Button icon={icon} onClick={() => changeSortKey(sortBy)} minimal fill alignText="left">
                {label}
            </Button>
        </th>
    );
};

interface IPatientsTable {
    patients: Vaccination[];
    allSelected: boolean;
    selectedPatientIds: string[];
    onToggleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onToggleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeSortKey: (key: string) => void;
    sortDirection: string;
    sortKey: string;
    groupPatients: boolean;
}

export default function PatientsTable({
    patients,
    allSelected,
    onToggleSelectAll,
    selectedPatientIds,
    onToggleSelect,
    changeSortKey,
    sortDirection,
    sortKey,
    groupPatients,
}: IPatientsTable) {
    const sortedHeader = (sortBy: string, label: string) => (
        <SortedHeader
            changeSortKey={changeSortKey}
            sortBy={sortBy}
            label={label}
            direction={sortDirection}
            sortKey={sortKey}
        />
    );

    return (
        <table className="bp5-html-table bp5-interactive common-table common-table--fill">
            <thead>
                <tr>
                    {groupPatients && <th></th>}
                    <th>
                        <Checkbox checked={allSelected} onChange={onToggleSelectAll} />
                    </th>
                    {sortedHeader('lastName', 'Name')}
                    {sortedHeader('age', 'Age')}
                    {sortedHeader('hubId', 'Hub')}
                    <th className="common-table__icon-column" />
                    {sortedHeader('vaccinationCategory', 'Vaccine')}
                    {sortedHeader('doseNumber', 'Dose')}
                    {sortedHeader('dateOfPreviousDose', 'Previous dose')}
                    {sortedHeader('createDateTime', 'Date added')}
                    {sortedHeader('jobStatus', 'Status')}
                </tr>
            </thead>
            <tbody>
                {patients.map((patient, i) => (
                    <Patient
                        patient={patient}
                        selectedPatientIds={selectedPatientIds}
                        onToggleSelect={onToggleSelect}
                        prevNhsNumber={patients[i - 1]?.nhsNumber}
                        nextNhsNumber={patients[i + 1]?.nhsNumber}
                        groupPatients={groupPatients}
                        key={patient.id}
                    />
                ))}
            </tbody>
        </table>
    );
}
