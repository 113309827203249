import { FC, useState } from 'react';
import cn from 'classnames';
import { Tag, Tooltip } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import useStores from '../../../../../hook/useStores';
import { ButtonColors } from '../../../../v2/components';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    featureFlag?: string;
    name: string;
    size: string;
    pos: string;
    Icon: FC;
    isActive: boolean;
    clickEvent: () => void;
    dataTest: string;
    tooltipText?: string;
    text?: string;
    badgeContent?: string;
    textAction?: () => void;
    fullSizeIcon?: boolean;
    color?: ButtonColors;
    iconClass?: string;
}

const SideButton: FC<IProps> = ({ tooltipText, featureFlag, ...props }) => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    if (featureFlag && !isFeatureEnabled(featureFlag)) {
        return null;
    }

    if (!tooltipText) {
        return <SideButtonInner {...props} />;
    }

    return (
        <Tooltip
            popoverClassName="ucr__side-button-tooltip-bp-override"
            content={tooltipText}
            openOnTargetFocus={false}
            renderTarget={({ isOpen: isTooltipOpen, ref, ...tooltipProps }) => {
                return (
                    <div {...tooltipProps} ref={ref}>
                        <SideButtonInner {...props} />
                    </div>
                );
            }}
        />
    );
};

const SideButtonInner: FC<Omit<IProps, 'tooltipText' | 'featureFlag'>> = ({
    name,
    size,
    pos,
    Icon,
    isActive,
    clickEvent,
    dataTest,
    text,
    badgeContent,
    textAction,
    fullSizeIcon,
    color,
    iconClass,
}) => {
    const [isHover, setHover] = useState<boolean>(false);

    const onMouseEnter = () => setHover(true);
    const onMouseLeave = () => setHover(false);

    return (
        <>
            <div
                className={cn(
                    'ucr__side-button',
                    `ucr__side-button--${size}`,
                    `ucr__side-button--${pos}`,
                    color && `v2__button--${color}`,
                    {
                        'ucr__side-button--default': !isActive && !isHover,
                        'ucr__side-button--hovered': !isActive && isHover,
                        'ucr__side-button--active': isActive,
                    },
                )}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={clickEvent}
                data-test={dataTest}
            >
                <span
                    className={cn(
                        `ucr__side-button-icon${fullSizeIcon ? '-full-size' : ''}`,
                        iconClass,
                    )}
                >
                    <Icon />
                </span>
                {size === 'large' && <span className="ucr__side-button-name">{name}</span>}
                {badgeContent && (
                    <Tag
                        className={cn(
                            'ucr__side-button-badge',
                            color && `ucr__side-button-badge--${color}`,
                        )}
                        round
                    >
                        {badgeContent}
                    </Tag>
                )}
            </div>
            {text && (
                <div
                    className={cn('ucr__side-button-action', {
                        'ucr__side-button--hovered': !isActive && isHover,
                        'ucr__side-button-action--active': isActive,
                    })}
                    onClick={textAction}
                >
                    <span className="ucr__side-button-text">{text}</span>
                </div>
            )}
        </>
    );
};

export default observer(SideButton);
