import { useState, useCallback, useMemo } from 'react';
import { DirectionsService, DirectionsRenderer, Marker } from '@react-google-maps/api';

import { useQuery } from '@apollo/client';
import { GET_ROUTE_BY_ID } from '../../../graphql/queries/jobs';
import { Instruction } from '@doc-abode/data-models';
import { icon, markerType } from './MapView';

export const colors = ['#1c7cd5', '#29a634', '#d9534f', '#d9822b', '#800080'];

interface DirectionsInterface {
    isLoaded: boolean;
    getLatLng: (
        lat: string,
        lng: string,
        name: string,
    ) => {
        location: google.maps.LatLng;
        id: string;
    };
    index: number;
    hidden: boolean;
    jobId?: string;
    newItems?: string[];
    instructions?: Instruction[];
}

export const Directions = ({
    isLoaded,
    getLatLng,
    index,
    newItems = [],
    hidden,
    jobId,
    instructions: providedInstructions,
}: DirectionsInterface) => {
    const [response, setResponse] = useState(null);
    const [waypoints, setWaypoints] = useState<
        {
            location: google.maps.LatLng;
            id: string;
        }[]
    >([]);

    type originState = {
        location: google.maps.LatLng;
        id: string;
    } | null;

    const [origin, setOrigin] = useState<originState>(null);
    const [instructions, setInstructions] = useState(providedInstructions);

    // we skip when jobid is undefined because sometimes we pass the instructions in manually so we do not need to do the api-call.
    useQuery(GET_ROUTE_BY_ID, {
        variables: {
            id: jobId,
        },
        skip: jobId === undefined,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setInstructions(data.getJob.itinerary.instructions);
        },
    });

    const directionsCallback = useCallback((res) => {
        if (res !== null) {
            if (res.status === 'OK') {
                setResponse(res);
            } else {
                console.warn('response: ', res);
            }
        }
    }, []);

    const directionsServiceOptions = useMemo(() => {
        if (!isLoaded || !instructions) return;

        // sadly instructions doesn't appear to map correctly for latitude and longitude so we need to use an any.
        const [origin] = instructions
            .slice(0, 1)
            .map((instruction: any) =>
                getLatLng(
                    instruction.itineraryItem?.location?.latitude,
                    instruction.itineraryItem?.location?.longitude,
                    instruction.itineraryItem?.name,
                ),
            );
        const [destination] = instructions
            .slice(-1)
            .map((instruction: any) =>
                getLatLng(
                    instruction.itineraryItem?.location?.latitude,
                    instruction.itineraryItem?.location?.longitude,
                    instruction.itineraryItem?.name,
                ),
            );

        const waypoints = instructions
            .slice(1, -1)
            .filter((instruction) =>
                ['VisitLocation', 'Pickup'].includes(instruction.instructionType),
            )
            .map((instruction: any) =>
                getLatLng(
                    instruction.itineraryItem?.location?.latitude,
                    instruction.itineraryItem?.location?.longitude,
                    instruction.itineraryItem?.name,
                ),
            );

        setOrigin(origin);
        setWaypoints(waypoints);
        const TravelMode = google.maps.TravelMode;
        return {
            destination: destination?.location,
            origin: origin?.location,
            waypoints: waypoints.map(({ location }) => ({ location })),
            optimizeWaypoints: false,
            travelMode: TravelMode.DRIVING,
        };
    }, [instructions, isLoaded, getLatLng, setWaypoints]);

    const color = colors[index];

    const directionsRendererOptions = useMemo(() => {
        return {
            directions: response,
            markerOptions: {
                visible: false,
            },
            polylineOptions: {
                strokeColor: color,
                strokeWeight: 6,
                strokeOpacity: 0.55,
            },
        };
    }, [response, color]);

    if (!instructions) return null;

    if ((hidden || response === null) && directionsServiceOptions) {
        return (
            <DirectionsService options={directionsServiceOptions} callback={directionsCallback} />
        );
    }

    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (!origin) return null;

    return (
        <>
            <DirectionsRenderer options={directionsRendererOptions} />
            <Marker position={origin.location} icon={icon(color, markerType.origin)} />
            {waypoints.map((locationDetails, index: number) => {
                const { location, id } = locationDetails;
                const isNew = newItems.includes(id);
                return (
                    <Marker
                        position={location}
                        icon={icon(isNew ? colors[1] : color)}
                        label={{
                            text: letters[index],
                            color: 'white',
                            fontSize: '16px',
                        }}
                        key={`${location.lat},${location.lng}-${index}`}
                    />
                );
            })}
        </>
    );
};
